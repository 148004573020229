import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import logo from "../../static/images/graco-logo-white.png";
import { useAppSelector } from "../../store/store";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: "35px 0 40px 0",
      width: "100%",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      ["@media (max-width: 1499px)"]: {
        paddingTop: 100,
        paddingBottom: 12,
      },
    },
    background: {
      top: 0,
      position: "absolute",
      width: "100%",
      height: "70%",
      backgroundColor: theme.colors.blue,
    },
    content: {
      display: "grid",
      boxSizing: "border-box",
      padding: "0 35px",
      gridTemplateColumns: "repeat(2, 280px) 200px repeat(2, 280px)",
      justifyContent: "center",
      gridColumnGap: 35,
      zIndex: 1,
      width: "100%",
      height: "100%",
      ["@media (max-width: 1499px)"]: {
        display: "flex",
        width: "80%",
      },
      ["@media (max-width: 1200px)"]: {
        width: "90%",
      },
      ["@media (max-width: 600px)"]: {
        width: "100%",
        padding: 0
      },
    },
    item: {
      cursor: "pointer",
      boxSizing: "border-box",
      padding: 10,
      overflow: "hidden",
      width: "100%",
      height: 490,
      margin: "0 auto",
      borderRadius: 20,
      border: `1px solid ${theme.colors.border}`,
      backgroundColor: theme.colors.text,
      ["@media (max-width: 1499px)"]: {
        width: "90%",
        height: 525,
      },
      ["@media (max-width: 1024px)"]: {
        width: 290,
        height: 515,
      },
    },
    instagramHeading: {
      color: theme.colors.blue,
      fontSize: 40
    },
    post: {
      border: "none",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: 10,
    },
    logo: {
      margin: "0 auto",
      marginTop: 60,
      userSelect: "none",
      pointerEvents: "none",
      height: 60,
      filter: "drop-shadow(2px 4px 3px black)",
      [theme.device.mobile()]: {
        width: 45,
        height: 60,
      },
    },
    mobileLogo: {
      pointerEvents: "none",
      height: 50,
      position: "absolute",
      top: 20,
      margin: "0 auto",
    },
    activeDesktop: {
      display: "flex",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      display: "flex",
      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "social-media-bar" }
);

const SocialMediaBar = (): JSX.Element => {
  const RENDER_DELAY = 2000
  setTimeout(() => setShowBar(true), RENDER_DELAY)
  const [showBar, setShowBar] = useState(false)

  const classes = useStyles();

  const { posts } = useAppSelector(state => state.siteConfig);

  const isMobile = window.innerWidth < 1500;

  const items = posts.map(post => (
    <div key={post.id} className={classes.item}>
      <iframe
        loading="lazy"
        scrolling="no"
        className={classes.post}
        src={post.url}
        title="instagram post"
      />
    </div>
  ));

  if (!isMobile) {
    items.splice(
      2,
      0,
      <img key="logo" className={clsx(classes.logo, classes.activeDesktop)} src={logo} alt="logo" />
    );
  }

  if(!showBar) return <></>

  return (
    <div className={classes.root}>
      <div className={classes.background} />
      {isMobile ? <img src={logo} alt="social media post" className={classes.mobileLogo} /> : null}
      <div className={classes.content}>
        {isMobile ? (
          <AliceCarousel
            mouseTracking
            autoPlay
            animationDuration={4000}
            disableButtonsControls
            infinite
            items={items}
            responsive={{
              0: {
                items: 1,
              },
              700: {
                items: 2,
              },
              1024: {
                items: 3,
              },
            }}
          />
        ) : (
          items
        )}
      </div>
    </div>
  );
};

export default SocialMediaBar;
