import { Phrases } from "./utils/language";

const phrases: Phrases = {
  "": "שגיאה כללית",
  search: "חפש",
  accessibility: "נגישות",
  auth: "התחבר / הרשמה",
  shopping_bag: "שקית קניות",
  sign_up: "הירשם",
  login: "התחברות",
  features: "תכונות",
  free_shipping: "משלוח חינם",
  qty: "כמות",
  add_to_cart: "הוסף לעגלה",
  view_more: "לפרטים נוספים",
  load_more: "טען עוד",
  join_subscription: "הצטרף למנויים שלנו",
  join_newsletter: "הצטרף לניוזלטר החודשי",
  email: "אימייל",
  submit: "שלח",
  cancel: "ביטול",
  filters: "מסננים",
  sort_by: "מיין לפי",
  my_account: "החשבון שלי",
  buy_policy: "מדיניות קניה",
  customer_service: "שירות לקוחות",
  career: "קריירה",
  accumulative_cart: "עגלה צוברת",
  product_catalog: "קטלוג מוצרים",
  authorized_resellers: "משווקים מורשים",
  importer_deals: "מבצעי יבואן",
  car_suitability: "שאלון התאמה לרכב",
  search_guides: "מדריכים  חיפוש",
  privacy_policy: "מדיניות פרטיות",
  terms_of_use: "תנאי שימוש",
  age: "גיל",
  size: "גודל",
  weight: "משקל",
  color: "צבע",
  warranty: "אחריות יצרן",
  available_colors: "צבעים זמינים",
  share: "שתף",
  newest: "הכי חדש",
  alphabet: "אלף בית",
  popularity: "פופולריות",
  cheapest: "מחיר מהזול ליקר",
  expensive: "מחיר מהיקר לזול",
  additional_info: "לפרטים נוספים",
  all_categories: "כל הקטגוריות",
  google_login: "התחבר עם גוגל",
  google_sign_up: "הירשם בגוגל",
  empty_bag: "תיק ריק",
  shipping: "משלוח",
  subtotal: "סך הכל",
  password: "סיסמה",
  verify_password: "אמת סיסמה",
  full_address: "כתובת מלאה",
  first_name: "שם פרטי",
  last_name: "שם משפחה",
  phone_number: "מספר טלפון",
  manufacturies: "תעשייה",
  rate: "ציון",
  show_more: "להראות יותר",
  similar_products: "מוצרים דומים",
  related_products: "מוצרים קשורים",
  description: "תיאור",
  specification: "מפרט טכני",
  kit: "מה כלול בערכה",
  qna: "שאלות ותשובות",
  reviews: "ביקורות",
  id: "תְעוּדַת זֶהוּת",
  view_bag: "צפה בתיק",
  proceed_to_checkout: "התקדם לנקודת הביקורת",
  item: "פריט",
  items: "פריטים",
  order_summary: "סיכום הזמנה",
  order_total: "סך כל ההזמנה",
  subtotal_before_delivery: "סכום המשנה לפני דמי משלוח",
  include_vat: 'כולל מע"מ',
  mobile_phone: "טלפון נייד",
  checkout: "לבדוק",
  shipping_address: "כתובת למשלוח",
  billing_address: "כתובת לחיוב",
  different_address: "השתמש בכתובת אחרת",
  full_name: "שם מלא",
  notes: "הערות",
  shipping_method: "שיטת משלוח",
  express_courier: "שליח אקספרס (אוויר)",
  up_to_business: "Up to ? business day(s)",
  payment: "תַשְׁלוּם",
  payment_method: "אנא בחר אמצעי התשלום שלך",
  card_number: "מספר כרטיס",
  expiry_date: "תאריך תפוגה",
  security_code: "קוד אבטחה",
  place_order: "שלמו והזמינו",
  january: "יָנוּאָר",
  february: "פברואר",
  march: "מרץ",
  april: "אַפּרִיל",
  may: "מאי",
  june: "יוני",
  july: "יולי",
  august: "אוגוסט",
  september: "סֶפּטֶמבֶּר",
  october: "אוֹקְטוֹבֶּר",
  november: "נוֹבֶמבֶּר",
  december: "דֵצֶמבֶּר",
  personal_details: "פרטים אישיים",
  order_history: "היסטוריית הזמנות",
  favorites_list: "רשימת המועדפים",
  sign_out: "התנתק",
  catalog_number: "מספר קטלוגי",
  more_details: "פרטים נוספים",
  add_all_to_cart: "הוסף הכל לעגלה",
  no_items_added_yet: "עדיין לא הוסיפו פריטים",
  welcome: "ברוך הבא",
  my_id: "תְעוּדַת זֶהוּת",
  consumer_price: "מחיר לצרכן",
  savings: "חיסכון",
  sale_price: "מחיר בייבי סתיו",
  discount_date: "בתוקף עד",
  facebook_login: "התחבר עם פייסבוק",
  facebook_sign_up: "הירשם בפייסבוק",
  "Please provide your username or your e-mail":
    "אנא ספק את שם המשתמש שלך או את הדואר האלקטרוני שלך",
  "Please provide your password": "אנא ספק את הסיסמה שלך",
  "Please provide your new password": "אנא ספק את הסיסמה החדשה שלך",
  "Please provide your new password confirmation": "אנא ספק את אישור הסיסמה החדש שלך",
  "New Passwords do not match": "סיסמאות חדשות אינן תואמות",
  "Current password is invalid": "הסיסמה הנוכחית אינה חוקית",
  name: "שֵׁם",
  second_phone_number: "מספר טלפון שני",
  address: "כתובת",
  change_password: "שנה סיסמא",
  change_your_password: "שנה את סיסמתך",
  current_password: "סיסמה נוכחית",
  new_password: "סיסמה חדשה",
  confirm_new_password: "תאשר סיסמא חדשה",
  min_pwd_chars: "מינימום 6 תווים, ללא רווחים",
  save_changes: "שמור שינויים",
  contact_us: "צור קשר",
  required_field: "שדה נדרש",
  invalid_email: "אימייל שגוי",
  topic: "נוֹשֵׂא",
  message: "הוֹדָעָה",
  phone: "טֵלֵפוֹן",
  send_message: "לשלוח הודעה",
  send_message_success: "!ההודעה שלך נשלחה בהצלחה",
  terms_and_conditions: "תנאים",
  something_went_wrong: "משהו השתבש, אנא רענן את הדפדפן שלך",
  tc_agree: "אני קורא ומסכים לתנאים ולהגבלות ולמדיניות הפרטיות של במבינו קינג.",
  out_of_stock: "נגמר המלאי",
  available_on: "זמין ב",
  approved_sellers: "מוכרים מאושרים",
  all: "את כל",
  north: "צָפוֹן",
  center: "מֶרְכָּז",
  south: "דָרוֹם",
  free: "חינם",
  pick_up: "לאסוף",
  ship_by_post: "נשלח בדואר",
  catalog_n: "מספר קטלוגי",
  order_n: "מספר הזמנה",
  less_details: "פחות פרטים",
  order_date: "תאריך הזמנה",
  status: "סטָטוּס",
  update_message: "עדכנת בהצלחה את פרטי החשבון שלך",
  store_price: "מחיר חנות",
  vat: "מס ערך מוסף",
  warning_message: "אינך יכול לחרוג מהכמות הזמינה של המוצר",
  date: "תַאֲרִיך",
  has_subscription: 'הדוא"ל כבר רשום',
  successful_subscription: "נרשמת כמנוי",
  from: "מ",
  to: "ל",
  height: "גוֹבַה",
  width: "רוֹחַב",
  length: "אורך",
  empty_cart: "העגלה שלך ריקה",
  notFound: "לא הצלחנו למצוא את הדף שאתה מחפש",
  copy_link: "העתק קישור",
  min: "מִינִימוּם",
  max: "מַקסִימוּם",
  price_range: "מחוון טווח",
  years: "y",
  product_link: "אתה יכול לקנות את זה מהאתר הרשמי.",
  write_review: "כתוב ביקורת",
  name_username: "שם / שם משתמש",
  close: "סגור",
  review: "סקירה",
  graco_israel: "graco_israel",
  shops: "shops",
  pushchairs: "pushchairs",
  car_seats: "car_seats",
  cots_and_cribs: "cots_and_cribs",
  highchairs: "highchairs",
  soothers: "soothers",
  explore_joie: "explore_joie",
  vehicle_fit_guide: "vehicle_fit_guide",
  join_joie_desc: "simple text ",
  main_address: "כתובת",
  // "location": "simple text ",
  // "dob": "simple text ",
  // "hello_joie": "simple text ",
  // "explore_joie_desc": "simple text ",
  // "explore_joie_here": "simple text ",
  // "meet_the_gear_desc": "simple text ",
  // "meet_the_gear": "simple text ",
  // "teamed_up": "simple text ",
  // "partner_desc": "simple text ",
  // "join_joie": "simple text ",
};

export { phrases };
