// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { makeStyles, useTheme } from "@material-ui/styles";
import { scrollStyles } from "../../utils/style-utils";
import TagManager from "react-gtm-module";
import Popup from "reactjs-popup";
import { push } from "connected-react-router";
import clsx from "clsx";
import axios from "axios";

import OrderSummary from "../common/order-summary";

import { useAppDispatch, useAppSelector } from "../../store/store";
import CategoryPanel from "../category/category-panel";
import Button from "../common/button";
import Input from "../common/input";

import { TOrder, TShippingMethod } from "../../services/orders/types";
import { countProductDiscountPrice, countProductPrice, countVat } from "../../utils/helper";
import displayCurrencySign from "../../utils/currencies-signs";
import { validateOrder } from "../../utils/validateForm";
import languagesStore, { Language } from "../../store/languages-store";
import { UpdatedProduct } from "../header/cart/cart";
import ordersStore from "../../store/orders-store";
import { NewPhrase } from "../../utils/language";
import { config } from "../../config";
import { isMobile } from "react-device-detect";
import usersStore from "../../store/users-store";
import { current } from "@reduxjs/toolkit";
import PuffLoader from "react-spinners/ClipLoader";
import authFormStore from "../../store/auth-form-store";
import { isCouponValid, checkDiscountPricePerProduct, sumTotalDiscount } from "../../utils/coupons";

import Payment from "./payments/Payment";

import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import { ReactComponent as CheckIcon } from "../../static/icons/check-1.svg";
import BitIcon from "../../static/images/bit.png";

const tagManagerArgs = {
  gtmId: "GTM-T7QB9J6",
};
TagManager.initialize(tagManagerArgs);

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

type TFormField = {
  label: NewPhrase;
  additional: boolean;
  name: string;
};

const getFormFields = (lang: Language): TFormField[] => [
  {
    label: "full_name",
    additional: false,
    name: "fullName",
  },
  {
    label: "email",
    additional: false,
    name: "email",
  },
  {
    label: "phone",
    additional: true,
    name: "phone",
  },
  {
    label: "additional_phone_number",
    additional: true,
    name: "additionalPhone",
  },
  {
    label: "address",
    additional: false,
    name: "fullAddress",
  },
];

type InputErrors = {
  billing: { [props: string]: string };
  shipping: { [props: string]: string };
  termsAndConditions: boolean;
};

type FormState = {
  data: { [stateProps: string]: string };
  additional: boolean;
};

type FormsState = {
  shipping: FormState;
  billing: FormState;
  termsAndConditions: boolean;
};

type Address = "shipping" | "billing";
type Method = "phone" | "card" | 'bit';
type PaymetnMethods = {
  [method in Method]: string;
};
// TODO: Uncomment when serverSignError wil be displayed
// type ServerSignError = string | Error | null;
const paymentMethods: PaymetnMethods = {
  phone: "phone",
  card: "card",
  bit: "bit",
};

const CheckoutPage = (): JSX.Element => {
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [deliveryTime, setDeliveryTime] = useState<number>(0);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [canSubmitOrder, setCanSubmitOrder] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<string>("");
  const [couponStatus, setCouponStatus] = useState<string>("opa");
  const [couponDiscount, setCouponDiscount] = useState<string>(0);
  const [couponId, setCouponId] = useState<string>(0);
  const isAgent = false;

  //NEW
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDisabledPayment, setIsDisabledPayment] = useState<boolean>(true);
  const [idOrder, setIdOrder] = useState<number>(0);
  const [isValidField, setIsValidField] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [togglePaymentCardForm, setTogglePaymentCardForm] = useState<boolean>(false);
  const [togglePaymentBitForm, setTogglePaymentBitForm] = useState<boolean>(false);
  const [iframeString, setIframeString] = useState<string>()
  
  const cart = useAppSelector(state => state.cart.items);
  const { maxOrderWeight, maxOrderPrice, cartProducts, activeOrder, cartItems, lang, user } =
    useAppSelector(state => {
      const cartItems = state.user.data?.cartItems;
      const cartProducts: UpdatedProduct[] = [];

      state.products.productsData.data?.forEach((product: UpdatedProduct) => {
        const updatedProduct = { ...product };

        if (
          cartItems?.find(cartItem => {
            let { price } = countProductDiscountPrice(cartItem);

            if (cartItem.productId === product.id) {
              updatedProduct.count = cartItem.productCount;
              updatedProduct.totalPrice = price * cartItem.productCount;
              updatedProduct.totalWeight = product.weight * cartItem.productCount;
              updatedProduct.price = price;
              // );updatedProduct.price = countProductPrice(
              //   state.user.data?.approvedAgent!,
              //   product,
              //   Number(state.user.data?.discount!)
              // );

              return updatedProduct;
            }

            return;
          })
        ) {
          cartProducts.push(updatedProduct);
        }

        return false;
      });

      return {
        maxOrderWeight: state.siteConfig.maxOrderWeight,
        maxOrderPrice: state.siteConfig.maxOrderPrice,
        activeOrder: state.orders.activeOrder,
        // Do NOT remove cartItems! They are use in the useEffect as a source of truth.
        // Otherwise on hard-refresh it will always go in the first if() condition and
        // redirect to "/catalog"
        cartItems: state.user.data?.cartItems,
        lang: state.languages.language,
        user: state.user.data,
        cartProducts,
      };
    });

  const sumTheTotalPrice = (totalPrice: any) => {
    let discountedPrice = 0;
    if (couponDiscount !== 0) {
      if (couponDiscount?.total) {
        discountedPrice = totalPrice - couponDiscount.total;
      } else if (couponDiscount?.percent) {
        discountedPrice = totalPrice - totalPrice * (couponDiscount.percent / 100);
      } else {
        console.log("Nothing to update !");
      }
    }
    return discountedPrice;
  };
  const checkDiscountStatus = (startDiscountDate, endDiscountDate, importerDeal) => {
    let [currYear, currMonth, currDay] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-")
      .split("-");
    const currDate = currYear + "-" + currMonth + "-" + currDay;
    if (startDiscountDate <= currDate && currDate <= endDiscountDate && importerDeal) {
      return true;
    } else {
      return false;
    }
  };

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const coupons = useAppSelector(state => state.coupons.data);

  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useAppDispatch();

  const classes = useStyles({});

  const theme = useTheme();

  useEffect(() => {
    console.log("erfrgdtg", couponDiscount);
  }, [couponDiscount]);

  useEffect(() => {
    // if(coupon){setCouponStatus("L")}
    const { result, errorMsg } = isCouponValid(coupon, coupons);
    if (errorMsg) {
      setCouponDiscount(0);
      setCouponStatus(<CloseIcon />);
      console.log("err", errorMsg);
    } else if (result) {
      setCouponId(result[0]);
      setCouponStatus(<CheckIcon />);
      // check if there are coupons left
      if (result[0].appliedTo && !result[0]?.appliedToEntireCard) {
        let { productWithDiscount } = checkDiscountPricePerProduct(cartProducts, result[0]);
        let { totalDiscount } = sumTotalDiscount(productWithDiscount, result[0]);
        setCouponDiscount({ total: totalDiscount });
        console.log("=========", totalDiscount);
      } else if (result[0]?.appliedToEntireCard) {
        if (result[0]?.discountType) {
          setCouponDiscount({ percent: result[0]?.discountPercent });
        } else {
          setCouponDiscount({ total: result[0]?.discountAmount });
        }
      } else {
        console.log("else condition");
      }
    }
  }, [coupon]);

  const initialFormState = {
    billing: {
      fullName: !user
        ? ""
        : `${user?.firstName === null ? "" : user?.firstName}${
            user?.lastName === null ? "" : " " + user?.lastName
          }`,
      email: user?.email,
      phone: user?.phoneNumber,
      additionalPhone: user?.additionalPhoneNumber,
      fullAddress: user?.address,
      notes: undefined,
    },

    shipping: {
      fullName: "",
      email: "",
      phone: "",
      additionalPhone: "",
      fullAddress: "",
      notes: "",
    },
  };

  const { shipByPostPrice, courierPrice, vat, discount } = useAppSelector(state => ({
    shipByPostPrice: state.siteConfig.shipByPostPrice!,
    courierPrice: state.siteConfig.courierPrice!,
    //isAgent: state.user.data?.approvedAgent,
    discount: state.user.data?.discount,
    vat: state.siteConfig.vat!,
  }));

  const [inputErrors, setInputErrors] = useState<InputErrors | null>(null);
  // TODO: Uncomment when serverSignError wil be displayed
  // const [serverSignError, setServerSignError] = useState<ServerSignError>(null);
  const [formState, setFormState] = useState<FormsState>({
    shipping: {
      data: { ...initialFormState.shipping },
      additional: false,
    },
    billing: {
      data: { ...initialFormState.billing },
      additional: false,
    },
    termsAndConditions: false,
  });

  useEffect(() => {
    setFormState({
      billing: {
        data: { ...initialFormState.billing },
        additional: false,
      },
      shipping: {
        data: { ...initialFormState.shipping },
        additional: false,
      },
      termsAndConditions: false,
    });
  }, [user]);

  const [availableShipping, setAvailableShipping] = useState<boolean>(false);
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [newsLetter, setNewsLetter] = useState<boolean>(true);
  const [shippingMethod, setShippingMethod] = useState<TShippingMethod>("pick_up");

  // const isFreeShipping = shippingMethod === "pick_up" || shippingMethod === "delivery_by_courier_free";

  const totalShippingPrice =
    shippingMethod === "ship_by_post"
      ? shipByPostPrice
      : shippingMethod === "delivery_by_courier"
      ? courierPrice
      : 0;

  const totalOrderPrice = isAgent
    ? totalPrice + totalShippingPrice + countVat(totalPrice, vat, isAgent)
    : totalPrice + totalShippingPrice;

  const purchasedProducts = user
    ? cartProducts?.map(cP => ({
        id: cP.id,
        count: cP.count!,
        totalPrice: cP.totalPrice!,
        totalWeight: cP.totalWeight!,
        picture: cP.mainPicture,
        catalogNumber: cP.catalogNumber,
        price: cP.price!,
        color: cP.color!,
        name: cP.name,
        title: cP.title,
        brand: cP.brand!,
        shippingPrice: cP.shippingPrice,
        deliveryTime: cP.deliveryTime,
        weight: cP.weight,
        quantity: cP.quantity,
        availabilityDate: cP.availabilityDate,
        barCode: cP.Barcode,
        categories: cP.categories,
        subCategories: cP.subCategories,
        subSubCategories: cP.subSubCategories,
      }))
    : cart.map(cP => ({
        id: cP.id,
        count: cP.count!,
        totalPrice: cP.product.totalPrice!,
        totalWeight: cP.product.totalWeight!,
        picture: cP.product.mainPicture,
        catalogNumber: cP.product.catalogNumber,
        price: cP.price!,
        color: cP.product.color!,
        name: cP.product.name,
        title: cP.product.title,
        brand: cP.product.brand!,
        shippingPrice: cP.product.shippingPrice,
        deliveryTime: cP.product.deliveryTime,
        weight: cP.product.weight,
        quantity: cP.product.quantity,
        availabilityDate: cP.product.availabilityDate,
        barCode: cP.product.Barcode,
        categories: cP.product.categories,
        subCategories: cP.product.subCategories,
        subSubCategories: cP.product.subSubCategories,
      }));

  const productDescriptions = purchasedProducts.map(orderItem => {
    return `${orderItem.name + " " + orderItem.title}`;
  });

  useEffect(() => {
    if (totalOrderPrice === 0) {
      setIsDisabledPayment(true);
      setIsDisabled(true);
    } else {
      setIsDisabledPayment(false);
    }
  }, [totalOrderPrice]);

  const onDifferentAddressClick = () => setAvailableShipping(current => !current);

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setFormState({
      ...formState,
      billing: {
        ...formState.billing,
        data: {
          ...formState.billing.data,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      },
    });

  const handleClickCreditCard = async (e: React.MouseEvent<HTMLButtonElement>, paymentMethod) => {
    
    const billingAdditional = formState.billing.additional;
    const shippingAdditional = formState.shipping.additional;

    const billing = formState.billing.data;
    const shipping = formState.shipping.data;

    const { isValid, errors } = validateOrder(
      checkedTerms,
      availableShipping,
      shippingAdditional,
      billingAdditional,
      activePhrases,
      shipping,
      billing
    );
    if (!isValid) {
      if (!checkedTerms) {
        setInputErrors(errors);
        setCanSubmitOrder(true);
      } else {
        document.body.scrollTop = 350;
        document.documentElement.scrollTop = 350;
        setInputErrors(errors);
        setCanSubmitOrder(true);
      }
    } else {
      setInputErrors(null);
    }

    let res = sumTheTotalPrice(totalOrderPrice);
    if (res != 0) {
      setFinalPrice(res.toFixed(2));
    } else {
      setFinalPrice(totalOrderPrice.toFixed(2));
    }

    stopDefaultSubmit(e);

    let orderReady: TOrder = {};

    if ((!!user && !user.errorMessage) || cart.length > 0) {
      const INITIAL_ORDER_STATUS = "New";
      const INITIAL_PAYMENT_STATUS = "Processing";
      const currentDate = new Date();

      const orderDate = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`;

      const order: TOrder = {
        orderDate,
        user: user ? user : {},
        paymentStatus: INITIAL_PAYMENT_STATUS,
        orderStatus: INITIAL_ORDER_STATUS,
        totalPrice: cart.length
          ? couponDiscount !== 0
            ? sumTheTotalPrice(totalPrice)
            : totalPrice + totalShippingPrice
          : user.approvedAgent
          ? totalPrice + totalShippingPrice
          : //+ countVat(totalPrice, vat, isAgent)
          couponDiscount !== 0
          ? sumTheTotalPrice(totalPrice)
          : totalPrice + totalShippingPrice,
        totalCount,
        totalShippingPrice,
        totalWeight,
        domain: 'graco',
        shippingMethod,
        paymentMethod: paymentMethods[paymentMethod],
        shippingNumber: null,
        invoiceNumber: null,
        deliveryTime,
        billingAddress: {
          email: billing.email,
          fullName: billing.fullName,
          fullAddress: billing.fullAddress,
          phoneNumber: Number(billing.phone),
          additionalPhoneNumber: billingAdditional ? Number(billing.additionalPhone) : null,
          notes: billing.notes,
        },
        orderedProducts: cart.length
          ? cart.map(cP => ({
              id: cP.product.id,
              count: cP.count!,
              totalPrice: checkDiscountStatus(
                cP.product.startDiscountDate,
                cP.product.discountDate,
                cP.product.importerDeal
              )
                ? cP.product.salePrice?.toFixed(2) * cP.count
                : cP.product.retailPrice?.toFixed(2) * cP.count,
              totalWeight: cP.product.weight * cP.count,
              picture: cP.product.mainPicture,
              catalogNumber: cP.product.catalogNumber,
              price: checkDiscountStatus(
                cP.product.startDiscountDate,
                cP.product.discountDate,
                cP.product.importerDeal
              )
                ? cP.product.salePrice?.toFixed(2)
                : cP.product.retailPrice?.toFixed(2),
              color: cP.product.color!,
              name: cP.product.name,
              title: cP.product.title,
              brand: cP.product.brand!,
              shippingPrice: cP.product.shippingPrice,
              deliveryTime: cP.product.deliveryTime,
              weight: cP.product.weight,
              quantity: cP.product.quantity,
              availabilityDate: cP.product.availabilityDate,
              barCode: cP.product.Barcode,
              categories: cP.product.categories!,
              subCategories: cP.product.subCategories!,
              subSubCategories: cP.product.subSubCategories!,
            }))
          : cartProducts?.map(cP => ({
              id: cP.id,
              count: cP.count!,
              totalPrice: cP.totalPrice!,
              totalWeight: cP.totalWeight!,
              picture: cP.mainPicture,
              catalogNumber: cP.catalogNumber,
              price: cP.price!,
              color: cP.color!,
              name: cP.name,
              title: cP.title,
              brand: cP.brand!,
              shippingPrice: cP.shippingPrice,
              deliveryTime: cP.deliveryTime,
              weight: cP.weight,
              quantity: cP.quantity,
              availabilityDate: cP.availabilityDate,
              barCode: cP.Barcode,
              categories: cP.categories!,
              subCategories: cP.subCategories!,
              subSubCategories: cP.subSubCategories!,
            })),
        vat,
        products: cart.length
          ? cart.map(cP => ({
              product_information: `${cP.product.name} - ${cP.product.color} - ${
                cP.product.barCode
              } - ${cP.product.retailPrice?.toFixed(2)}`,
            }))
          : cartProducts.map(cP => ({
              product_information: `${cP.name} - ${cP.color} - ${cP.barCode} - ${cP.price?.toFixed(
                2
              )}`,
            })),
      };

      if (availableShipping) {
        order.shippingAddress = {
          email: shipping.email,
          fullName: shipping.fullName,
          fullAddress: shipping.fullAddress,
          phoneNumber: Number(shipping.phone),
          additionalPhoneNumber: shippingAdditional ? Number(shipping.additionalPhone) : null,
          notes: billing.notes,
        };
      } else {
        order.shippingAddress = {};
      }

      if (isValid) {
        dispatch(ordersStore.actions.createOrder(order)).then(response => {
          const newOrderId = response?.payload?.id;
          orderReady.id = newOrderId
          order.id = newOrderId

          if (response.meta.requestStatus === "fulfilled") {
            if (newsLetter) {
              dispatch(
                usersStore.actions.addSubscriptionEmail({ email: order.billingAddress.email, lang })
              );
            }
            setIdOrder(newOrderId);
            setIsDisabled(true);
            return setIsSubmiting(true);
          }
        });
        orderReady = order;
      }
    }
    console.log('orderReady', orderReady)

    isValid && paymentMethod == "card" && setTogglePaymentCardForm(true);
    isValid && paymentMethod == "bit" && setTogglePaymentBitForm(true);
    if (isValid && paymentMethod == "bit") {
      setShowSpinner(true);
         await  axios.post('https://xml-generator-dot-bambino-3.ew.r.appspot.com/bitpay', orderReady)
                .then(res => res.status == 200 ? setIframeString(res.data.sale_url) : null,
                )
               document.body.scrollTop += 200;
               document.documentElement.scrollTop += 200;
    }
  };

  const createAddressForms = (address: Address): JSX.Element[] => {
    const formFields = getFormFields(lang);
    const addressForms = formFields.map(field => {
      const isShipping = address === "shipping";

      const isAdditionalForm = field.name === "additionalPhone";
      const isAddedField = isShipping
        ? formState.shipping.additional
        : formState.billing.additional;

      const inputValue = isShipping
        ? formState.shipping.data[field.name]
        : formState.billing.data[field.name];

      const errorMsg = !!inputErrors?.[address][field.name]
        ? `* ${inputErrors?.[address][field.name]}`
        : "";

      const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
        setFormState(
          isShipping
            ? {
                ...formState,
                shipping: {
                  ...formState.shipping,
                  data: {
                    ...formState.shipping.data,
                    [e.currentTarget.name]: e.currentTarget.value,
                  },
                },
              }
            : {
                ...formState,
                billing: {
                  ...formState.billing,
                  data: {
                    ...formState.billing.data,
                    [e.currentTarget.name]:
                      e.currentTarget.name === "fullName" || e.currentTarget.name === "fullAddress"
                        ? e.currentTarget.value.trimStart("").replace("  ", " ")
                        : e.currentTarget.value.trim(""),
                  },
                },
              }
        );

      const onCircleClick = (): void =>
        setFormState(
          isShipping
            ? {
                ...formState,
                shipping: {
                  data: { ...formState.shipping.data },
                  additional: !formState.shipping.additional,
                },
              }
            : {
                ...formState,
                billing: {
                  data: { ...formState.billing.data },
                  additional: !formState.billing.additional,
                },
              }
        );
      
      let placeholder = null 
      if(field.name === "fullAddress") { 
        placeholder = "דוגמא: הרצל 22, קומה 5 דירה 3, תל אביב"
      }

      const label = activePhrases && activePhrases[field.label];

      return (
        <Input
          key={field.name}
          label={label}
          name={field.name}
          inputValue={inputValue}
          additional={field.additional}
          isAdditionalForm={isAdditionalForm}
          errorMsg={errorMsg}
          placeholder={placeholder}
          onCircleClick={onCircleClick}
          onInputChange={onInputChange}
          className={{
            root: clsx(
              classes.addressItem,
              isAdditionalForm && classes.additional,
              isAdditionalForm && isAddedField && classes.additionalActive
            ),
            input: clsx(classes.input, !!inputErrors?.[address][field.name] && classes.error),
            label: clsx(classes.inputLabel),
            circle: clsx(
              classes.inputCircle,
              field.label == "additional_phone_number" && classes.minusCircle,
              !isAdditionalForm && isAddedField && classes.inactive
            ),
            circleText:
              field.label == "additional_phone_number"
                ? `${classes.inputCircle}, ${classes.minusCircle}`
                : classes.inputCircleText,
            errorMsg: classes.errorMsg,
          }}
        />
      );
    });

    return addressForms;
  };

  const onPlaceOrderClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    submit: boolean,
    paymentMethod: Method
  ) => {
    // debugger;
    if (paymentMethod === "phone") {
      event.preventDefault();
    }
    if (cartItems?.length === 0 || !canSubmitOrder) {
      return;
    }
    setCanSubmitOrder(false);
    setInputErrors(null);
    if ((!!user && !user.errorMessage) || cart.length > 0) {
      const INITIAL_ORDER_STATUS = "New";
      const INITIAL_PAYMENT_STATUS = "Processing";

      const billingAdditional = formState.billing.additional;
      const shippingAdditional = formState.shipping.additional;

      const billing = formState.billing.data;
      const shipping = formState.shipping.data;
      const currentDate = new Date();

      const orderDate = `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`;

      const order: TOrder = {
        orderDate,
        user: user ? user : {},
        paymentStatus: INITIAL_PAYMENT_STATUS,
        orderStatus: INITIAL_ORDER_STATUS,
        totalPrice: cart.length
          ? couponDiscount !== 0
            ? sumTheTotalPrice(totalPrice)
            : totalPrice + totalShippingPrice
          : user.approvedAgent
          ? totalPrice + totalShippingPrice
          : //+ countVat(totalPrice, vat, isAgent)
          couponDiscount !== 0
          ? sumTheTotalPrice(totalPrice)
          : totalPrice + totalShippingPrice,
        totalCount,
        totalShippingPrice,
        totalWeight,
        shippingMethod,
        paymentMethod: paymentMethods[paymentMethod],
        shippingNumber: null,
        invoiceNumber: null,
        deliveryTime,
        billingAddress: {
          email: billing.email,
          fullName: billing.fullName,
          fullAddress: billing.fullAddress,
          phoneNumber: Number(billing.phone),
          additionalPhoneNumber: billingAdditional ? Number(billing.additionalPhone) : null,
          notes: billing.notes,
        },
        orderedProducts: cart.length
          ? cart.map(cP => ({
              id: cP.product.id,
              count: cP.count!,
              totalPrice: checkDiscountStatus(
                cP.product.startDiscountDate,
                cP.product.discountDate,
                cP.product.importerDeal
              )
                ? cP.product.salePrice?.toFixed(2) * cP.count
                : cP.product.retailPrice?.toFixed(2) * cP.count,
              totalWeight: cP.product.weight * cP.count,
              picture: cP.product.mainPicture,
              catalogNumber: cP.product.catalogNumber,
              price: checkDiscountStatus(
                cP.product.startDiscountDate,
                cP.product.discountDate,
                cP.product.importerDeal
              )
                ? cP.product.salePrice?.toFixed(2)
                : cP.product.retailPrice?.toFixed(2),
              color: cP.product.color!,
              name: cP.product.name,
              title: cP.product.title,
              brand: cP.product.brand!,
              shippingPrice: cP.product.shippingPrice,
              deliveryTime: cP.product.deliveryTime,
              weight: cP.product.weight,
              quantity: cP.product.quantity,
              availabilityDate: cP.product.availabilityDate,
              barCode: cP.product.Barcode,
              categories: cP.product.categories!,
              subCategories: cP.product.subCategories!,
              subSubCategories: cP.product.subSubCategories!,
            }))
          : cartProducts?.map(cP => ({
              id: cP.id,
              count: cP.count!,
              totalPrice: cP.totalPrice!,
              totalWeight: cP.totalWeight!,
              picture: cP.mainPicture,
              catalogNumber: cP.catalogNumber,
              price: cP.price!,
              color: cP.color!,
              name: cP.name,
              title: cP.title,
              brand: cP.brand!,
              shippingPrice: cP.shippingPrice,
              deliveryTime: cP.deliveryTime,
              weight: cP.weight,
              quantity: cP.quantity,
              availabilityDate: cP.availabilityDate,
              barCode: cP.Barcode,
              categories: cP.categories!,
              subCategories: cP.subCategories!,
              subSubCategories: cP.subSubCategories!,
            })),
        vat,
        products: cart.length
          ? cart.map(cP => ({
              product_information: `${cP.product.name} - ${cP.product.color} - ${
                cP.product.barCode
              } - ${cP.product.retailPrice?.toFixed(2)}`,
            }))
          : cartProducts.map(cP => ({
              product_information: `${cP.name} - ${cP.color} - ${cP.barCode} - ${cP.price?.toFixed(
                2
              )}`,
            })),
      };

      if (availableShipping) {
        order.shippingAddress = {
          email: shipping.email,
          fullName: shipping.fullName,
          fullAddress: shipping.fullAddress,
          phoneNumber: Number(shipping.phone),
          additionalPhoneNumber: shippingAdditional ? Number(shipping.additionalPhone) : null,
          notes: billing.notes,
        };
      } else {
        order.shippingAddress = {};
      }

      const { isValid, errors } = validateOrder(
        checkedTerms,
        availableShipping,
        shippingAdditional,
        billingAdditional,
        activePhrases,
        shipping,
        billing
      );
      setIsValidField(isValid);

      if (!isValid) {
        if (!checkedTerms) {
          setInputErrors(errors);
          setCanSubmitOrder(true);
        } else {
          document.body.scrollTop = 350;
          document.documentElement.scrollTop = 350;
          setInputErrors(errors);
          setCanSubmitOrder(true);
        }
      } else {
        setInputErrors(null);
      }

      setIsDisabled(true);

      if (isValid && paymentMethod === "phone") {
        dispatch(ordersStore.actions.createOrder(order)).then(response => {
          if (response.meta.requestStatus === "fulfilled") {
            if (newsLetter) {
              dispatch(
                usersStore.actions.addSubscriptionEmail({ email: order.billingAddress.email, lang })
              );
            }

            if (submit) {
              setIsDisabled(false);
              return setIsSubmiting(true);
            }
          }
        });
        const transactionProducts = [];
        purchasedProducts?.map(pr =>
          transactionProducts.push({
            item_id: pr.catalogNumber,
            item_name: pr.name,
            price: pr.price,
            quantity: pr.count,
            item_category: pr.categories.map(c => c.name),
            item_category_2: pr.subCategories.map(c => c.name),
            item_category_3: pr.subSubCategories.map(c => c.name),
          })
        );
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "ILS",
            transaction_id: activeOrder?.id,
            value: activeOrder?.totalPrice,
            items: transactionProducts,
          },
        });

        ReactPixel.fbq("track", "Purchase", { currency: "ILS", value: activeOrder?.totalPrice });
        dispatch(push(config.routes.thanksPagePhone));
      }
    }
  };

  const updateQuantity = order => {
    const orderedProducts = order.orderedProducts;
    const createUpdate = async (host: any, catalogNumber: any, body: any, requestOptions: any) => {
      await axios.put(`${host}products/${catalogNumber}`, body, requestOptions);
      console.log("update qty", host, catalogNumber, body, requestOptions);
    };

    const createCouponUpdate = async (host: any, id: any, body: any, requestOptions: any) => {
      await axios.put(`${host}coupons/${id}`, body, requestOptions);
    };

    var requestOptions = {
      "Content-type": "application/json",
      // "Authorization": `Bearer ${jwt}`,
    };

    for (let i = 0; i < orderedProducts.length; i++) {
      let currentProduct = orderedProducts[i];

      axios
        .get(`https://bambino-3.ew.r.appspot.com/products/${currentProduct.catalogNumber}`)
        .then(async response => {
          const data = await response.data;
          const updatedQty = data.quantity - currentProduct.count;
          if (updatedQty < 0) {
            updatedQty = 0;
            console.log("Nqma");
            return;
          }
          console.log(updatedQty);
          let updateObject = {
            quantity: updatedQty,
          };

          // // update qty for bambino
          await createUpdate(
            "https://bambino-3.ew.r.appspot.com/",
            currentProduct.catalogNumber,
            updateObject,
            requestOptions
          );
          console.log("between update");
          await createUpdate(
            "https://graco.bambinok.com/",
            currentProduct.catalogNumber,
            updateObject,
            requestOptions
          );
        });
    }
    if (isNaN(order.vat)) {
      order.vat = 0;
    }
    let couponOutputObj = {
      couponsUsed: +couponId?.couponsUsed + 1 + "",
    };
    createCouponUpdate(
      "https://graco.bambinok.com/",
      couponId.id,
      couponOutputObj,
      requestOptions
    );
  };

  const paymentHandler = (isPaymentSuccess: boolean) => {
    if (isPaymentSuccess) {
      let updatedOrder: TUpdateReq = {
        ...activeOrder,
        user: Object.keys(activeOrder.user)?.length > 0 ? activeOrder?.user : null,
        paymentStatus: "Paid",
        orderStatus: "New",
      };

      const transactionProducts = [];
      purchasedProducts?.map(pr =>
        transactionProducts.push({
          item_id: pr.catalogNumber,
          item_name: pr.name,
          price: pr.price,
          quantity: pr.count,
          item_category: pr.categories.map(c => c.name),
          item_category_2: pr.subCategories.map(c => c.name),
          item_category_3: pr.subSubCategories.map(c => c.name),
        })
      );
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "ILS",
          transaction_id: activeOrder?.id,
          value: activeOrder?.totalPrice,
          items: transactionProducts,
        },
      });
      ReactPixel.fbq("track", "Purchase", { currency: "ILS", value: activeOrder?.totalPrice });

      // updateQuantity(activeOrder);

      dispatch(ordersStore.actions.updateOrder(updatedOrder)).then(() => {
        // dispatch(usersStore.actions.updateUserCart({ userId: user?.id!, cartItems: [] }));
        setTimeout(() => {
          dispatch(push(config.routes.thanksPageCard));
        }, 2000);
      });
    } else {
      let updatedOrder: TUpdateReq = {
        ...activeOrder,
        user: Object.keys(activeOrder.user)?.length > 0 ? activeOrder?.user : null,
        paymentStatus: "Declined",
        orderStatus: "New",
      };
      dispatch(ordersStore.actions.updateOrder(updatedOrder)).then(() => {
        dispatch(usersStore.actions.updateUserCart({ userId: user?.id!, cartItems: [] }));
      });
    }
  };

  const stopDefaultSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault();

  const onCheckTermsClick = () => setCheckedTerms(current => !current);
  const onCheckNewsLetter = () => setNewsLetter(current => !current);

  const onShippingMethodClick = (value: TShippingMethod) => () => setShippingMethod(value);

  useEffect(() => {
    if (!availableShipping) {
      setFormState({
        ...formState,
        shipping: {
          data: { ...initialFormState.shipping },
          additional: false,
        },
      });
      setInputErrors({
        billing: { ...inputErrors?.billing },
        shipping: { ...initialFormState.billing },
        termsAndConditions: inputErrors?.termsAndConditions!,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableShipping]);

  useEffect(() => {
    if (
      (shippingMethod === "delivery_by_courier" || shippingMethod === "ship_by_post") &&
      totalPrice >= maxOrderPrice!
    ) {
      setShippingMethod("delivery_by_courier_free");
      return;
    }

    if (shippingMethod === "delivery_by_courier_free" && totalPrice < maxOrderPrice!) {
      setShippingMethod("delivery_by_courier");
      return;
    }

    if (maxOrderWeight! < totalWeight && shippingMethod === "ship_by_post") {
      setShippingMethod("pick_up");
    }
  }, [shippingMethod, totalPrice, maxOrderPrice, totalWeight, maxOrderWeight]);

  useEffect(() => {
    if (isSubmiting) {
      localStorage.setItem("currentOrderId", String(activeOrder.id!));
      //formRef.current!?.submit();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmiting]);
  useEffect(() => {
    if (checkedTerms) {
      setInputErrors({
        billing: { ...inputErrors?.billing },
        shipping: { ...inputErrors?.shipping },
        termsAndConditions: false,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTerms]);

  // useEffect(() => {
  //   cartItems?.length === 0 && dispatch(push(config.routes.catalogPage, true));
  // }, [dispatch, cartItems]);

  useEffect(() => {
    if (cartProducts?.length > 0) {
      const maxDeliveryTime = Math.max.apply(
        Math,
        cartProducts?.map(cP => cP.deliveryTime)
      );

      let totalCartWeight = 0;
      let totalCartPrice = 0;
      let totalCartCount = 0;

      cartProducts.forEach(cP => {
        let { price } = countProductDiscountPrice(cP);

        // let {price} = isAgent ? countProductPrice(isAgent!, cP, cP.discount!) : ;
        totalCartWeight += cP.totalWeight!;
        totalCartPrice += price * cP?.count!;
        totalCartCount += cP.count!;
      });

      setDeliveryTime(maxDeliveryTime);
      setTotalWeight(totalCartWeight);
      setTotalCount(totalCartCount);
      setTotalPrice(totalCartPrice);
    } else if (cart?.length > 0) {
      const maxDeliveryTime = Math.max.apply(
        Math,
        cart?.map(cP => cP.product.deliveryTime)
      );

      let totalCartWeight = 0;
      let totalCartPrice = 0;
      let totalCartCount = 0;

      cart.forEach(cP => {
        let { price } = countProductDiscountPrice(cP.product);

        // let {price} = isAgent ? countProductPrice(isAgent!, cP, cP.discount!) : ;
        totalCartWeight += cP.product.totalWeight!;
        totalCartPrice += price * cP?.count!;
        totalCartCount += cP.count!;
      });

      setDeliveryTime(maxDeliveryTime);
      setTotalWeight(totalCartWeight);
      setTotalCount(totalCartCount);
      setTotalPrice(totalCartPrice);
    }
  }, [cartProducts, isAgent, cart]);
  const { buyPolicy, language } = useAppSelector(state => ({
    buyPolicy:
      state.languages.language === "he"
        ? state.siteConfig.BuyPolicyHebrew
        : state.siteConfig.BuyPolicy,
    language: state.languages.language,
  }));

  const policyCloseBtn = (e: any) => {
    e.preventDefault();
    let buyPol = document.getElementById("buyPolicy");
    if (buyPol) {
      buyPol.style.visibility = "hidden";
      buyPol.style.opacity = "0";
      buyPol.style.display = "none";
    }
  };

  const termsPopUp = (e: any) => {
    e.preventDefault();
    let buyPol = document.getElementById("buyPolicy");
    let btn = buyPol?.getElementsByTagName("button")[0];
    let span: any = buyPol?.getElementsByTagName("span")[0]!;
    if (buyPol !== null && btn) {
      buyPol.style.display = "block";
      buyPol.style.visibility = "visible";
      buyPol.style.opacity = "1";
      buyPol.style.backgroundColor = "rgba(17, 70, 73, 0.9)";
      btn.style.color = "rgba(255,255,255, 1)";
      span.innerHTML = buyPolicy ? buyPolicy : "Loading...";
    }
  };

  const redirectToOrders = () => {
    dispatch(push(config.routes.ordersHistory));
  };

  return (
    <div className={classes.root}>
      <CategoryPanel />
      <div className={classes.title}>{activePhrases && activePhrases["checkout"]}</div>
      <form className={classes.content} autoComplete="off" method="POST" ref={formRef}>
        <div className={classes.address}>
          <div className={clsx(classes.addressItem)}>
            <div className={clsx(classes.subtitle, classes.addressTitle)}>
              {activePhrases && activePhrases["order_summary"]}
            </div>
            <div className={classes.orderSummary}>
              <OrderSummary
                className={{ root: classes.ordersSummary, proceed: classes.proceedBtn }}
                shippingMethod={shippingMethod}
                totalPrice={totalPrice}
                couponDiscount={couponDiscount}
              />
            </div>

            {/* <div className={clsx(classes.addressContainer, !availableShipping && classes.inactive)}>
              {createAddressForms("shipping")}
            </div>    */}
          </div>
          <div className={classes.addressItem}>
            <div className={clsx(classes.subtitle, classes.addressTitle)}>
              {activePhrases && activePhrases["billing_address"]}
            </div>
            <div className={clsx(classes.addressContainer, classes.billingAddressContainer)}>
              {createAddressForms("billing")}
              <div className={clsx(classes.addressItem, classes.textAreaContainer)}>
                <div className={clsx(classes.grayText, classes.textAreaLabel)}>
                  {activePhrases && activePhrases["notes"]}
                </div>
                <textarea
                  className={clsx(classes.input, classes.textArea)}
                  name="notes"
                  onChange={onTextAreaChange}
                  value={formState.billing.data.notes}
                />
              </div>
            </div>

            <div className={clsx(classes.subtitle)}>
              {activePhrases && activePhrases["different_address"]}
            </div>
            <div className={clsx(classes.differentAddressBox)} style={{ display: "flex" }}>
              <span style={{ paddingTop: "7px" }}>
                {activePhrases && activePhrases["different_address"]}
              </span>
              <div
                className={clsx(classes.circle, availableShipping && classes.circleActive)}
                onClick={onDifferentAddressClick}
              />
            </div>
            <div
              className={clsx(
                classes.differentAddressContainer,
                !availableShipping && classes.diffInactive,
                !availableShipping && classes.inactive
              )}
            >
              {createAddressForms("shipping")}
            </div>
          </div>
        </div>
        <div className={classes.deliveryMethod}>
          <div className={classes.subtitle}>
            {activePhrases && activePhrases["shipping_method"]}
          </div>
          <div className={classes.shipping}>
            <div className={classes.shippingMethod}>
              <div
                className={clsx(
                  classes.grayText,
                  classes.shippingText,
                  shippingMethod === "pick_up" && classes.greenText
                )}
                onClick={onShippingMethodClick("pick_up")}
              >
                {activePhrases && activePhrases["pick_up"]}&nbsp;
                {activePhrases && activePhrases["free"]}
                &nbsp;
                <div
                  className={clsx(
                    classes.circle,
                    classes.circleInactive,
                    classes.circleShippingMethod,
                    shippingMethod === "pick_up" && classes.circleActive
                  )}
                />
              </div>
              <div
                className={clsx(
                  classes.grayText,
                  classes.shippingText,
                  shippingMethod === "ship_by_post" && classes.greenText,
                  classes.shipByPost,
                  maxOrderWeight! > totalWeight && maxOrderPrice! > totalPrice && classes.active
                )}
                onClick={onShippingMethodClick("ship_by_post")}
              >
                {!!activePhrases && activePhrases["ship_by_post"]}&nbsp;
                <div
                  className={clsx(
                    classes.circle,
                    classes.circleInactive,
                    shippingMethod === "ship_by_post" && classes.circleActive
                  )}
                />
              </div>

              <div
                className={clsx(classes.grayText, classes.shippingText, classes.upToBusinessDays)}
              >
                {activePhrases &&
                  activePhrases["up_to_business"].replace("?", deliveryTime?.toString())}
              </div>
              <div
                className={clsx(
                  classes.grayText,
                  classes.shippingText,
                  (shippingMethod === "delivery_by_courier" ||
                    shippingMethod === "delivery_by_courier_free") &&
                    classes.greenText
                )}
                onClick={onShippingMethodClick("delivery_by_courier")}
              >
                {activePhrases && activePhrases["express_courier"]}{" "}
                {maxOrderPrice! <= totalPrice && activePhrases && activePhrases["free"]}&nbsp;
                <div
                  className={clsx(
                    classes.circle,
                    classes.circleInactive,
                    (shippingMethod === "delivery_by_courier" ||
                      shippingMethod === "delivery_by_courier_free") &&
                      classes.circleActive
                  )}
                />
              </div>
            </div>
          </div>
          <div className={clsx(classes.subtitle, classes.addressTitle)}>
            {activePhrases && activePhrases["coupon"]}
          </div>
          <div className={classes.couponContainer}>
            {couponStatus && coupon && <div className={classes.statusBox}>{couponStatus}</div>}
            <div className={classes.couponForm}>
              <input
                className={classes.input}
                type="text"
                value={coupon}
                onChange={e => setCoupon(e.target.value)}
              />
            </div>
          </div>

          <div className={classes.subtitle}>{activePhrases && activePhrases["tc_agree"]}</div>

          <div className={clsx(classes.tc)}>
            <div className={clsx(classes.grayText, classes.tcText)}>
              <a onClick={termsPopUp} className={classes.terms}>
                {activePhrases && activePhrases["tc_agree"]}
              </a>
              <div id="buyPolicy" className={classes.termsPopUp}>
                <button className={classes.policyCloseBtn} onClick={policyCloseBtn}>
                  X
                </button>
                <div>
                  <span>Popup text...</span>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                classes.circle,
                checkedTerms && classes.circleActive,
                inputErrors?.termsAndConditions! && !checkedTerms && classes.tcCheckBoxFail
              )}
              onClick={onCheckTermsClick}
            />
          </div>

          <div className={clsx(classes.tc)}>
            <div className={clsx(classes.grayText, classes.tcText)}>
              <a className={classes.newsLetter}>
                {activePhrases && activePhrases["join_newsletter"]}
              </a>
            </div>
            <div
              className={clsx(
                classes.circle,
                newsLetter && classes.circleActive,
                inputErrors?.termsAndConditions! && !newsLetter && classes.tcCheckBoxFail
              )}
              onClick={onCheckNewsLetter}
            />
          </div>

          <div className={classes.subtitle}>{activePhrases && activePhrases["payment"]}</div>
          <div className={classes.payment}>
            <div className={classes.paymentDetails}>
              {/* <div className={classes.grayText}>
              {activePhrases && activePhrases["payment_method"]}
            </div> */}
              <div className={classes.paymentMethod}>
                <Button
                  disabled={isDisabled && isDisabledPayment}
                  borderRadius={25}
                  width={260}
                  height={33}
                  fill={theme.colors.text}
                  outline={theme.colors.blue}
                  fillHover={isMobile ? "" : theme.colors.blue}
                  borderWidth={2}
                  textSize={23}
                  textColor={theme.colors.blue}
                  textColorHover={isMobile ? "" : theme.colors.text}
                  type="button"
                  text={activePhrases && activePhrases["payment"]}
                  action={event => handleClickCreditCard(event, "bit")}
                  img={BitIcon}
                />
                <Button
                  disabled={isDisabledPayment}
                  borderRadius={25}
                  width={260}
                  height={33}
                  fill={theme.colors.text}
                  outline={theme.colors.blue}
                  fillHover={isMobile ? "" : theme.colors.blue}
                  borderWidth={2}
                  textSize={23}
                  textColor={theme.colors.blue}
                  textColorHover={isMobile ? "" : theme.colors.text}
                  type=" "
                  text={activePhrases && activePhrases["phone"]}
                  action={event => onPlaceOrderClick(event, false, "phone")}
                />
                <Button
                  disabled={isDisabled && isDisabledPayment}
                  borderRadius={25}
                  width={260}
                  height={33}
                  fill={theme.colors.text}
                  outline={theme.colors.blue}
                  fillHover={isMobile ? "" : theme.colors.blue}
                  borderWidth={2}
                  textSize={23}
                  textColor={theme.colors.blue}
                  textColorHover={isMobile ? "" : theme.colors.text}
                  type="button"
                  text={activePhrases && activePhrases["card_payment"]}
                  action={event => handleClickCreditCard(event, "card")}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      
       {showSpinner ? 
       <Popup open={true} overlayStyle={{backdropFilter: 'blur(5px)'}}>
         <PuffLoader color={theme.colors.blue} size={50}/> 
       </Popup>
       : null}
      {togglePaymentBitForm && iframeString && !togglePaymentCardForm && (
        <Popup open={true} overlayStyle={{backdropFilter: 'blur(5px)'}}>   
          <iframe style={{border: "none"}} src={iframeString} />
        </Popup>
      )}

      {togglePaymentCardForm && (
        <Payment
          orderId={idOrder}
          isValid={isValidField}
          onPayment={paymentHandler}
          onPlaceOrder={onPlaceOrderClick}
          totalOrderPrice={finalPrice}
          productDescriptions={productDescriptions}
          customerFullName={formState.billing.data.fullName}
        />
      )}
    </div>
  );
};

export default CheckoutPage;

const useStyles = makeStyles(
  theme => ({
    modal: {
      display: "none" /* Hidden by default */,
      position: "fixed" /* Stay in place */,
      zIndex: 1 /* Sit on top */,
      paddingTop: "100px" /* Location of the box */,
      left: 0,
      top: 0,
      width: "100%" /* Full width */,
      height: "100%" /* Full height */,
      overflow: "auto" /* Enable scroll if needed */,
      backgroundColor: "rgba(0,0,0,0.4)," /* Black w/ opacity */,
    },
    modalContent: {
      backgroundColor: "#fefefe",
      margin: "auto",
      padding: "20px",
      border: "1px solid #888",
      width: "80%",
    },
    close: {
      color: "#aaaaaa",
      float: "right",
      fontSize: "28px",
      fontWeight: "bold",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "75px",
      overflow: "hidden",
      width: "100%",
    },
    title: {
      fontWeight: 700,
      padding: "25px 0",
      fontSize: 35,
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        paddingBottom: 0,
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      padding: "15px 20px",
      width: "100%",
      [theme.device.mobile()]: {
        padding: "15px 0 20px 0",
      },
    },
    subtitle: {
      color: theme.colors.blue,
      fontSize: 26,
      fontWeight: 700,
      width: "100%",
      padding: "15px 0px",
      textAlign: "end",
      boxSizing: "border-box",
      borderBottom: `1px solid ${theme.colors.border}`,
      [theme.device.mobile()]: {
        padding: "8px 20px 8px 0px",
      },
    },
    addressTitle: {
      [theme.device.mobile()]: {
        padding: "15px 0",
        paddingRight: 20,
      },
    },
    orderSummary: {
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-end",
      width: "100%",
      paddingTop: 15,
      color: theme.colors.yellow,
      fontWeight: 600,
      fontSize: 24,
      cursor: "default",
    },
    differentAddress: {
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-end",
      width: "100%",
      paddingTop: 15,
      color: theme.colors.yellow,
      fontWeight: 600,
      fontSize: 24,
      cursor: "default",
    },
    differentAddressBox: {
      paddingTop: "11px",
      display: "flex",
      [theme.device.mobile()]: {
        justifyContent: "flex-end",
        paddingRight: 20,
      },
    },
    deliveryMethod: {
      paddingBottom: 20,
      [theme.device.mobile()]: {
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gridRowGap: 0,
      },
    },
    address: {
      width: "100%",
      boxSizing: "border-box",
      paddingBottom: 20,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: 280,
      // height: 880,
      [theme.device.mobile()]: {
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gridRowGap: 30,
        paddingBottom: 0,
      },
    },
    addressItem: {
      width: "100%",
      display: "flex",
      margin: "0 !important",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        display: "block",
      },
    },
    inputLabel: {
      textAlign: "end",
      color: theme.colors.grayText,
      fontSize: 22,
      fontWeight: 600,
      [theme.device.mobile()]: {
        textAlign: "end",
        paddingRight: 20,
      },
    },
    addressContainer: {
      transition: "0.4s",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "100%",
      boxSizing: "border-box",
      paddingLeft: 100,
      [theme.device.mobile()]: {
        padding: 0,
      },
    },
    couponContainer: {
      paddingTop: "11px",
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      [theme.device.mobile()]: {
        justifyContent: "flex-end",
        paddingRight: 20,
      },
    },
    couponForm: {
      width: "calc(50% - 140px)",
      display: "flex",
      alignItems: "center",
      [theme.device.mobile()]: {
        width: "100%",
      },
      float: "right",
    },
    billingAddressContainer: {
      paddingLeft: 0,
      [theme.device.mobile()]: {
        padding: 0,
      },
    },
    grayText: {
      wordBreak: "break-word",
      textAlign: "end",
      color: theme.colors.grayText,
      fontSize: 22,
      fontWeight: 600,
      // padding: "12px 0",
      [theme.device.mobile()]: {
        textAlign: "start",
      },
    },
    tc: {
      width: "100%",
      boxSizing: "border-box",
      padding: "15px 0 20px 0",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      [theme.device.mobile()]: {
        padding: "15px 20px 20px 0",
      },
    },
    tcText: {
      fontSize: 15,
      paddingTop: 2,
      marginRight: 5,
    },
    tcCheckBox: {
      position: "relative",
      cursor: "pointer",
      width: 30,
      height: 30,
      borderRadius: 5,
      border: `2px solid ${theme.colors.grayText}`,
      transition: "0.3s",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    tcCheckBoxActive: {
      transition: "0.3s",
      borderColor: theme.colors.green,
      "&:after": {
        content: '""',
        width: 25,
        height: 25,
        backgroundColor: theme.colors.green,
        borderRadius: 3,
      },
    },
    input: {
      width: "100%",
      boxSizing: "border-box",
      textAlign: "end",
      border: `1px solid ${theme.colors.green}`,
      padding: "12px 5px",
      fontSize: 22,
      borderRadius: 10,
      color: theme.colors.black,
      [theme.device.mobile()]: {
        marginLeft: 20,
        marginRight: 20,
      },
      "&::placeholder": {
        color: '#d4d4d4',
        opacity: 1,
        fontSize: '18px',
        [theme.device.mobile()]: {
          fontSize: '16px'
        }
      },
      "&::-ms-input-placeholder": {
        color: '#d4d4d4',
        fontSize: '18px',
        [theme.device.mobile()]: {
          fontSize: '16px'
        }
      },
    },
    inputCircle: {
      width: 32,
      height: 32,
      left: -40,
      [theme.device.mobile()]: {
        left: 10,
        marginLeft: 7,
      },
    },
    minusCircle: {
      marginBottom: 6,
      fontSize: 32,
    },
    inputCircleText: {
      fontSize: 32,
      textAlign: "center",
      marginBottom: 6,
      [theme.device.mobile()]: {
        bottom: "1px",
      },
      // margin: "0 auto",
      // marginTop: "-7",

      // marginBottom: 1,
      // marginLeft: 1,
    },
    textAreaWr: {
      [theme.device.mobile()]: {
        paddingLeft: 35,
        boxSizing: "border-box",
      },
    },
    textAreaContainer: {
      [theme.device.mobile()]: {
        paddingRight: 20,
      },
    },
    textAreaLabel: {
      [theme.device.mobile()]: {
        textAlign: "end",
      },
    },
    textArea: {
      resize: "none",
      boxSizing: "border-box",
      height: 200,
      ...scrollStyles(theme, false),
      [theme.device.mobile()]: {
        width: "calc(100% - 20px)",
      },
    },
    shipping: {
      boxSizing: "border-box",
      padding: "30px 0 20px 100px",
      width: "100%",
      display: "flex",
      gridGap: 56,
      justifyContent: "flex-end",
      // alignItems: "flex-start",

      [theme.device.mobile()]: {
        alignItems: "flex-end",
        flexDirection: "column",
        padding: "15px 20px 20px 15px",
      },
    },

    shippingMethod: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      flexGrow: "1",
      [theme.device.mobile()]: {
        // height: "11.7vh",
        flexDirection: "column",
      },
    },
    shippingText: {
      paddingLeft: "2%",
      display: "flex",
      cursor: "pointer",
      textAlign: "end",
      fontWeight: 700,
      [theme.device.mobile()]: {
        paddingRight: 0,
        justifyContent: "flex-end",
        paddingTop: 6,
        paddingBottom: 6,
        textAlign: "start",
        paddingLeft: "0",
      },
    },
    shipByPost: {
      transition: "0.3s",
      height: 0,
      opacity: 0,
      display: "none",
    },
    upToBusinessDays: {
      [theme.device.mobile()]: {
        paddingRight: 40,
      },
    },
    circle: {
      position: "relative",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100%",
      height: 29,
      width: 29,
      transition: "0.2s",
      border: `1px solid ${theme.colors.green}`,
      marginLeft: 7,
    },
    circleShippingMethod: {
      margin: 0,
    },
    circleInactive: {
      borderColor: theme.colors.green,
      [theme.device.mobile()]: {
        marginLeft: 7,
      },
    },
    circleActive: {
      border: `1px solid ${theme.colors.green}`,
      position: "relative",
      backgroundColor: theme.colors.blue,
      borderColor: theme.colors.green,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "0.2s",
      "&:after": {
        position: "absolute",
        content: '""',
        width: 8,
        height: 15,
        transform: "rotate(45deg)",
        borderWidth: "0 2px 2px 0",
        border: `solid ${theme.colors.text}`,
        bottom: 8,
      },
    },
    paymentCircle: {
      "&:before": {
        content: '""',
        width: 26,
        height: 25,
        backgroundColor: theme.colors.green,
        border: `1px solid ${theme.colors.green}`,
        borderRadius: "100%",
      },
    },
    terms: {
      textDecoration: "none",
      color: "blue",
      "&:hover": {
        color: "lightblue",
      },
    },
    newsLetter: {
      textDecoration: "none",
      color: "blue",
    },
    price: {
      display: "flex",
      alignItems: "center",
      fontSize: 22,
      fontWeight: 600,
      color: theme.colors.green,
      [theme.device.mobile()]: {
        marginTop: 10,
      },
    },
    payment: {
      boxSizing: "border-box",
      gridTemplateColumns: "2fr 1fr",
      "& button": {
        marginLeft: "30px",
      },
      [theme.device.mobile()]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        "& button": {
          marginLeft: "30px",
          marginTop: 15,
          marginRight: 20,
        },
      },
    },
    paymentDetails: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    paymentMethod: {
      paddingTop: 5,
      display: "flex",
      // flexDirection: "column",
      gridRowGap: 12,
      [theme.device.mobile()]: {
        flexDirection: "column",
        justifyContent: "flex-end",
      },
    },
    termsPopUp: {
      color: "white",
      width: "50%",
      position: "absolute",
      left: "25%",
      border: "1px solid #114649",
      padding: "2% 3%",
      display: "none",
      borderRadius: "16px",
      vissibility: "vissible",
      opacity: 0,
      overflowX: "hidden",
      overflowY: "scroll",
      zIndex: 2,
      height: "300px",
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#114649",
        outline: `1px solid slategrey`,
      },
      [theme.device.mobile()]: {
        width: "80%",
        left: "10%",
      },
    },
    policyCloseBtn: {
      width: "12px",
      height: "12px",
      position: "absolute",
      top: 12,
      right: 12,
      // padding: "4px",
      // border: "1px solid #114649",
      borderRadius: "18px",
    },
    placeOrder: {
      opacity: 0,
      visibility: "hidden",
      transition: "0.3s",
      marginTop: 30,
      alignSelf: "flex-end",
      padding: 5,
    },
    ordersSummary: {
      textAlign: "right",
      marginTop: 25,
      [theme.device.desktop()]: {
        width: "100%",
      },
      [theme.device.mobile()]: {
        marginLeft: 20,
        marginRight: 20,
      },
    },
    proceedBtn: {
      display: "none",
    },
    additional: {
      transform: "translateY(-100%)",
      opacity: 0,
      visibility: "hidden",
      height: 0,
    },
    error: {
      border: `1px solid ${theme.colors.red}`,
      boxShadow: `0 0 2px 0 ${theme.colors.red}`,
    },
    errorMsg: {
      fontSize: 15,
    },
    additionalActive: {
      transform: "translateY(0)",
      visibility: "visible",
      opacity: 1,
      height: "auto",
    },
    greenText: {
      textAlign: "end",
      wordBreak: "break-word",
      color: theme.colors.green,
      fontWeight: 700,
      transition: "0.3s",
      display: "flex",
      // padding: "0 20px",
    },
    tcCheckBoxFail: {
      borderColor: theme.colors.red,
    },
    active: {
      transition: "0.3s",
      height: "auto",
      opacity: 1,
      visibility: "visible",
    },
    inactive: {
      cursor: "default",
      opacity: 0,
      visibility: "hidden",
      transition: "0.4s",
      [theme.device.mobile()]: {
        height: 0,
      },
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    differentAddressContainer: {
      width: "100%",
      display: "inline-grid",
      position: "relative",
      top: "20px",
    },
    diffInactive: {
      height: "0",
    },
    statusBox: {
      marginRight: "8px",
    },
  }),
  { name: "checkout-page" }
);
