import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import RangeSlider from "./range-slider/range-slider";

import { useAppDispatch, useAppSelector } from "../../store/store";
import categoriesStore from "../../store/categories-store";
import UIfiltersStore, { TPriceRange } from "../../store/ui-filters-store";
import type { Brand } from "../../services/brands/types";
import brandsStore from "../../store/brands-store";
import { getPhrase, NewPhrase } from "../../utils/language";
import Rating from "../common/rating";

import { ReactComponent as ArrowDown } from "../../static/icons/arrowDown.svg";
import languagesStore from "../../store/languages-store";
import { Product } from "../../services/products/types";
import { ChidlCategory } from "../../services/categories/types";
import { push } from "connected-react-router";
import { config } from "../../config";
import { checkProductsPage } from "../../utils/helper";
import { CategoryFreeText } from "./single-product/category-free-text";

type TProps = {
  className?: {
    root?: string;
  };
};
type Standard = {
  label: string;
  value: string;
};
const isofixStrings: NewPhrase[] = ["without", "included", "optional"];
const foldTypesStrings: NewPhrase[] = ["umbrella", "threeDimensional", "book"];

const standardObjects: Standard[] = [
  {
    label: "I-SIZE",
    value: "ISIZE",
  },

  {
    label: "FMVSS 213",
    value: "FMVSS213",
  },

  {
    label: "ECE R44/04",
    value: "ECER4404",
  },
];
const Filters = ({ className }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [priceRange, setPriceRange] = useState<TPriceRange>({
    min: "",
    max: "",
  });

  const categoryUrlId = useAppSelector(state => {
    const routeURL = state.router.location.pathname.split("/");
    return Number(routeURL.pop());
  });

  const [openSubCategories, setOpenSubCategories] = useState<number[]>([]);

  const {
    activeSubSubCategory,
    activeSubCategory,
    activeCategory,
    activeStandard,
    activeFoldType,
    activeBrands,
    activeRating,
    activeIsofix,
    mainCategory,
    categories,
    pathname,
    products,
    search,
  } = useAppSelector(state => ({
    activeSubSubCategory: state.categories.activeSubSubCategory,
    activeSubCategory: state.categories.activeSubCategory,
    activeCategory: state.categories.activeCategory,
    activeStandard: state.UIfilters.standard,
    activeFoldType: state.UIfilters.foldType,
    activeBrands: state.UIfilters.activeBrands,
    activeRating: state.UIfilters.rating,
    activeIsofix: state.UIfilters.isofix,
    mainCategory: state.categories.categoriesData.data.find(
      category => category.id === categoryUrlId
    ),
    categories: state.categories.categoriesData.data,
    pathname: state.router.location.pathname,
    products: state.products.productsData.data,
    search: state.router.location.search,
  }));

  const subCatIsActive = search.startsWith("?sub=");
  const subSubCatIsActive = search.startsWith("?sub_sub=");
  const searchIsActive = search.startsWith("?search=");

  const brands = useAppSelector(brandsStore.selectors.brandsSelector());
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  useEffect(() => {
    if (subCatIsActive) {
      const [, id] = search.split("=");
      setOpenSubCategories([Number(id)]);
      return;
    } else if (subSubCatIsActive) {
      const [, id] = search.split("=");
      const subCategoryId = categories.find(c => c?.id === Number(id))?.parent?.id;
      setOpenSubCategories([subCategoryId]);
    }
  }, [search, categories]);

  useEffect(() => {
    const { min, max } = {
      min: priceRange.min!,
      max: priceRange.max!,
    };

    dispatch(UIfiltersStore.actions.setPriceRange({ min, max }));
  }, [dispatch, priceRange]);

  useEffect(() => {
    dispatch(brandsStore.actions.fetchBrands());
    dispatch(categoriesStore.actions.setActiveCategory(Number(categoryUrlId!)));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(categoriesStore.actions.setActiveCategory(Number(categoryUrlId!)));
  }, [dispatch, categoryUrlId]);

  const categoriesFilter = useCallback(
    (product: Product) => {
      if (!activeCategory) {
        return product;
      }

      if (!!activeSubSubCategory) {
        return !!product.subSubCategories.find(s => s?.id === activeSubSubCategory);
      }

      if (!!activeSubCategory) {
        if (!!product.subCategories.find(s => s?.id === activeSubCategory)) {
          return true;
        }

        return !!product.subSubCategories.find(s => s.parent?.id === activeSubCategory);
      }

      if (!!product.categories.find(c => c?.id === activeCategory)) {
        return true;
      }

      const productCategories = [
        ...new Set(
          product.subSubCategories
            .map(sS => categories.find(c => c?.id === sS.parent?.id))
            .map(s => categories.find(c => c?.id === s?.parent?.id))
            .concat(product.subCategories.map(s => categories.find(c => c?.id === s?.parent?.id)))
        ),
      ];

      return !!productCategories.find(c => c!?.id === activeCategory);
    },
    [activeCategory, activeSubCategory, activeSubSubCategory, categories]
  );

  const onSubCategoryClick = (mainCategoryId: number, subCategoryId: number) => () => {
    dispatch(categoriesStore.actions.setActiveSubCategory(subCategoryId));

    dispatch(
      push(`${config.routes.productsPage}/${mainCategoryId}` + `?sub=${subCategoryId}`, true)
    );

    if (!!activeSubSubCategory) {
      dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
    }
  };

  const onSubSubCategoryClick = (mainCategoryId: number, subSubCategotyId: number) => () => {
    dispatch(categoriesStore.actions.setActiveSubSubCategory(subSubCategotyId));
    dispatch(
      push(`${config.routes.productsPage}/${mainCategoryId}` + `?sub_sub=${subSubCategotyId}`, true)
    );
  };

  const onBrandClick = (brandId: number) => (): void => {
    dispatch(UIfiltersStore.actions.setActiveBrands(brandId));
  };

  const filterdProducts = searchIsActive
    ? products!?.slice().filter(product => {
      if (search.startsWith("?search=")) {
        const [, searchString] = search.split("=");

        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.title.toLowerCase().includes(searchString.toLowerCase()) ||
          product.keywords?.toLowerCase().includes(searchString.toLowerCase())
        );
      }

      return product;
      // !!search
      //   ? new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.name) ||
      //     new RegExp(`(${escapeRegExp(search)})`, "gi").test(product.title)
      //   : product;
    })
    : products!?.filter(product => {
      if (subSubCatIsActive) {
        const [, subSubCategoryId] = search.split("=");

        return product.subSubCategories.find(cat => cat?.id === Number(subSubCategoryId));
      } else if (subCatIsActive) {
        const [, subCategoryId] = search.split("=");

        return (
          product.subCategories.find(cat => cat?.id === Number(subCategoryId)) ||
          product.subSubCategories.find(cat => cat.parent === Number(subCategoryId))
        );
      }

      const currentCategoryId = pathname.split("/").pop();

      return (
        product.categories.find(cat => cat?.id === Number(currentCategoryId)) ||
        product.subCategories.find(subCat => subCat.parent === Number(currentCategoryId)) ||
        product.subSubCategories.find(subSubCat => {
          if (subSubCat.parent) {
            const subCategoryId = subSubCat.parent;
            const subCategory = categories.find(c => c?.id === subCategoryId);
            return subCategory?.parent?.id === Number(currentCategoryId);
          }

          return false;
        })
      );
    });

  const brandsElements = brands
    .filter(b => filterdProducts.some(p => p.brand?.id === b?.id))
    .map((brand: Brand) => (
      <div key={brand?.id} className={classes.brand} onClick={onBrandClick(brand?.id)}>
        <div className={classes.brandTitle}>{brand.name}</div>
        <div
          className={clsx(classes.circle, activeBrands.includes(brand?.id) && classes.circleActive)}
        />
      </div>
    ));

  const onIsofixClick = (isofixString: NewPhrase) => {
    const newValue = isofixString === activeIsofix ? "" : isofixString;
    dispatch(UIfiltersStore.actions.setIsofix(newValue));
  };

  const isofixElements = isofixStrings
    .filter(string => filterdProducts.some(p => p.isofix === string))
    .map((string, i) => (
      <div key={string} className={classes.brand} onClick={() => onIsofixClick(string)}>
        <div className={classes.brandTitle}>{activePhrases && activePhrases[string]}</div>
        <div className={clsx(classes.circle, activeIsofix === string && classes.circleActive)} />
      </div>
    ));

  const onFoldTypeClick = (foldType: NewPhrase) => {
    const newValue = foldType === activeFoldType ? "" : foldType;
    dispatch(UIfiltersStore.actions.setFoldType(newValue));
  };

  const foldTypesElements = foldTypesStrings
    .filter(string => filterdProducts.some(p => p.foldTypes === string))
    .map((string, i) => (
      <div key={string} className={classes.brand} onClick={() => onFoldTypeClick(string)}>
        <div className={classes.brandTitle}>
          {i !== 1 ? activePhrases && activePhrases[string] : "3D"}
        </div>
        <div className={clsx(classes.circle, activeFoldType === string && classes.circleActive)} />
      </div>
    ));

  const onStandardClick = (standard: string) => {
    const newValue = standard === activeStandard ? "" : standard;
    dispatch(UIfiltersStore.actions.setStandard(newValue));
  };

  const standardElements = standardObjects
    .filter(obj => filterdProducts.some(p => p.productStandart === obj.value))
    .map((obj, i) => (
      <div key={obj.label} className={classes.brand} onClick={() => onStandardClick(obj.value)}>
        <div className={classes.brandTitle}>{obj.label}</div>
        <div
          className={clsx(classes.circle, activeStandard === obj.value && classes.circleActive)}
        />
      </div>
    ));

  const toggleSubCategory = (subCategoryId: number) => {
    const indexOfId = openSubCategories.findIndex(num => num === subCategoryId);
    // console.log("indexofId ===>", indexOfId);
    if (indexOfId === -1) {
      // console.log("in the if");
      return setOpenSubCategories(currentIds => [...currentIds, subCategoryId]);
    }

    setOpenSubCategories(currentIds => [
      ...currentIds.slice(0, indexOfId),
      ...currentIds.slice(indexOfId + 1),
    ]);
  };

  const categoryItems = !!mainCategory
    ? mainCategory.childrenCategories.map((subCat: ChidlCategory) => {
      const subcategory = categories.find(c => c?.id === subCat.childCategory?.id);

      return (
        <div key={subcategory?.id} className={classes.subCategory}>
          <div
            className={clsx(
              classes.subCategoryTitle,
              openSubCategories.includes(subcategory?.id!) && classes.underline
            )}
          >
            <div onClick={() => toggleSubCategory(subcategory?.id!)}>
              <ArrowDown
                className={clsx(
                  classes.arrow,
                  openSubCategories.includes(subcategory?.id!) && classes.arrowUp
                )}
              />
            </div>
            <span onClick={onSubCategoryClick(mainCategory?.id, subcategory?.id!)}>
              {subcategory?.name}
            </span>
          </div>
          <div
            className={clsx(
              classes.subSubCategories,
              openSubCategories.includes(subcategory?.id!) && classes.active
            )}
          >
            {subcategory?.childrenCategories?.map((subSubcat: ChidlCategory) => {
              const subSubcategory = categories.find(c => c?.id === subSubcat.childCategory?.id);
              return (
                <div key={subSubcategory?.id} className={classes.subSubCategory}>
                  <div
                    className={clsx(
                      classes.subSubCategoryTitle,
                      activeSubSubCategory === subSubcategory?.id && classes.underline
                    )}
                    onClick={onSubSubCategoryClick(mainCategory?.id, subSubcategory?.id!)}
                  >
                    {subSubcategory?.name} <div className={classes.dot} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    })
    : null;

  const onRangeInputChange = (type: "min" | "max") => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) return;

    setPriceRange(current =>
      type === "min"
        ? { max: current.max, min: value <= 0 ? "" : value }
        : { max: value <= 0 ? "" : value, min: current.min }
    );
  };
   
  const filters = ["isofix", "standart", "fold_types", "price_range", "age", "rate"] as const
  type Filter = typeof filters[number]
  const categoryHiddenFilters: Record<string, Filter[]> = {
    // car seats internal name
    "מושבי בטיחות במבינו": ["isofix", "age"]
  }
  
  const isFilterHidden = (categoryInternalName: string, filter: Filter) => {
    const hiddenFilters = categoryHiddenFilters[categoryInternalName]
    if(!hiddenFilters || !hiddenFilters.length) return false 
    if(hiddenFilters.includes(filter)) return true
    return false
  }

  return (
    <div
      className={clsx(
        classes.root,
        className?.root,
        categoryItems!?.length <= 0 && classes.subcatsNone
      )}
    >
      <p className={classes.title}>{mainCategory?.name}</p>
      <div className={classes.subCategories}>{categoryItems}</div>
      {isofixElements.length > 0 && !isFilterHidden(mainCategory?.internalName ?? "", "isofix") && (
        <div className={classes.filter}>
          <div className={classes.title}>{activePhrases && activePhrases["isofix"]}</div>
          {isofixElements}
        </div>
      )}
      {standardElements.length > 0 && (
        <div className={classes.filter}>
          <div className={classes.title}>{activePhrases && activePhrases["standart"]}</div>
          {standardElements}
        </div>
      )}
      {foldTypesElements.length > 0 && (
        <div className={classes.filter}>
          <div className={classes.title}>{activePhrases && activePhrases["fold_types"]}</div>
          {foldTypesElements}
        </div>
      )}
      <div className={classes.filter}>
        <div className={classes.title}>{activePhrases && activePhrases["price_range"]}</div>
        <div className={classes.price}>
          <div className={classes.priceItem}>
            <input
              className={classes.priceInput}
              type="text"
              value={priceRange.max!}
              onChange={onRangeInputChange("max")}
            />
            <div className={classes.priceText}>{activePhrases && activePhrases["max"]}</div>
          </div>
          <div className={classes.priceItem}>
            <input
              className={classes.priceInput}
              type="text"
              value={priceRange.min!}
              onChange={onRangeInputChange("min")}
            />
            <div className={classes.priceText}>{activePhrases && activePhrases["min"]}</div>
          </div>
        </div>
      </div>
      {!isFilterHidden(mainCategory?.internalName ?? "", "age") ? 
        <div className={classes.filter}>
          <div className={classes.title}>{activePhrases && activePhrases["age"]}</div>
          <RangeSlider />
        </div>
      : null}
      <div className={classes.filter}>
        <div className={classes.title}>{activePhrases && activePhrases["rate"]}</div>
        <div className={classes.ratingWrapper}>
          {activeRating < 5 ? (
            <p className={classes.andUpLabel}>{activePhrases && activePhrases["and_up"]}</p>
          ) : null}
          <Rating className={{ stars: classes.stars }} isClickable={true} rating="" size="big" />
        </div>
      </div>
    </div>
  );
};

export default Filters;

const useStyles = makeStyles(
  theme => ({
    root: {
      transition: "0.3s",
      height: "auto",
      width: "100%",
      paddingTop: 15,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      borderLeft: `2px solid ${theme.colors.blue}`,
      [theme.device.mobile()]: {
        borderLeft: "none",
        padding: "15px 0",
      },
    },
    title: {
      borderBottom: `3px solid ${theme.colors.blue}`,
      fontSize: 26,
      fontWeight: "bold",
      color: theme.colors.blue,
    },
    filter: {
      marginTop: 30,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    subCategories: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        display: "none",
      },
    },

    arrow: {
      marginRight: 15,
      transition: "0.3s",
    },

    arrowUp: {
      transform: "rotate(-180deg)",
      transition: "0.3s",
    },

    subCategory: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    subCategoryTitle: {
      textAlign: "end",
      maxWidth: 300,
      userSelect: "none",
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: 28,
      color: theme.colors.blue,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      wordBreak: "break-word",
    },
    subSubCategories: {
      padding: "5px 20px 0 20px",
      cursor: "default",
      userSelect: "none",
      transition: "0.3s",
      height: 0,
      opacity: 0,
      visibility: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    subSubCategory: {
      display: "flex",
      alignItems: "center",
      marginBottom: 4,
    },
    subSubCategoryTitle: {
      cursor: "pointer",
      fontSize: 23,
      color: theme.colors.blue,
      display: "flex",
      alignItems: "center",
      gridColumnGap: 3,
    },
    dot: {
      marginTop: 5,
      marginLeft: 10,
      width: 12,
      height: 12,
      borderRadius: "100%",
      backgroundColor: theme.colors.blue,
    },
    brand: {
      marginBottom: 6,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    brandTitle: {
      userSelect: "none",
      color: theme.colors.blue,
      fontSize: 26,
      fontWeight: 600,
    },
    circle: {
      marginTop: 5,
      marginLeft: 10,
      cursor: "pointer",
      borderRadius: "100%",
      width: 20,
      height: 20,
      border: `1px solid ${theme.colors.border}`,
    },
    circleActive: {
      position: "relative",
      backgroundColor: theme.colors.blue,
      borderColor: theme.colors.blue,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:after": {
        position: "absolute",
        content: '""',
        width: 7,
        height: 11,
        transform: "rotate(45deg)",
        borderWidth: "0 1px 1px 0",
        border: `solid ${theme.colors.text}`,
        bottom: 6,
      },
    },
    subcatsNone: {
      paddingTop: 0,
      marginTop: 0,
    },
    stars: {
      marginTop: 15,
    },
    price: {
      marginTop: 15,
      display: "grid",
      gridTemplateColumns: "100px 100px",
      gridColumnGap: 8,
    },
    priceItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "100%",
      gridRowGap: 5,
    },
    priceInput: {
      width: "100%",
      height: 30,
      textAlign: "end",
      border: `1px solid ${theme.colors.grayText}`,
      fontSize: 19,
    },
    priceText: {
      color: theme.colors.blue,
      fontSize: 17,
    },

    underline: {
      textDecoration: "underline",
    },
    active: {
      height: "auto",
      opacity: 1,
      visibility: "visible",
      transition: "0.3s",
    },

    ratingWrapper: {
      display: "flex",
      alignContent: "center",
    },
    andUpLabel: {
      fontWeight: 600,
      fontSize: "1.2em",
      margin: "0 10px 0 0",
      paddingTop: 22,
    },
  }),
  { name: "filters" }
);
