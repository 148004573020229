import React, { useState, ChangeEvent, FormEvent } from "react";

import { makeStyles } from "@material-ui/styles";

import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../../store/store";


const initialState = {

  make: "",

  model: "",

  year: "",

  email: "",

  isStayInformed: false,

};


const initialErrors = {

  make: false,

  model: false,

  year: false,

  email: false,

};


const makeRegex = /[a-zA-Z]{1,}\b/g;

const yearRegex = /[0-9]{4}/gm;

const emailRegex =

  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const VehicleNotFound = (): JSX.Element => {

  const [car, setCar] = useState(initialState);

  const [errors, setErrors] = useState(initialErrors);


  const classes = useStyles();


  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {

    const { name, value, type } = event.target;

    if (type === "checkbox") {

      setCar({ ...car, [name]: !car.isStayInformed });

    } else {

      setCar({ ...car, [name]: value });

    }

  };


  const handleSubmit = (event: FormEvent) => {

    event.preventDefault();


    if (!car.make || !makeRegex.test(car.make)) {

      setErrors({ ...errors, make: true });

      return;

    }


    if (!car.model) {

      setErrors({ ...errors, model: true });

      return;

    }


    if (!car.year || !yearRegex.test(car.year)) {

      setErrors({ ...errors, year: true });

      return;

    }


    if (!car.email || !emailRegex.test(car.email)) {

      setErrors({ ...errors, email: true });

      return;

    }


    console.log(car);

    setCar(initialState);

    setErrors(initialErrors);

  };


  const handleBack = (event: FormEvent) => {

    event.preventDefault();

  };


  const { buyPolicy, language } = useAppSelector(state => ({

    buyPolicy:

      state.languages.language === "he"

        ? state.siteConfig.BuyPolicyHebrew

        : state.siteConfig.BuyPolicy,

    language: state.languages.language,

  }));


  const policyCloseBtn = (e: any) => {

    e.preventDefault();

    let buyPol = document.getElementById("buyPolicy");

    if (buyPol) {

      buyPol.style.display = "none";

      buyPol.style.opacity = "0";

    }

  };


  const termsPopUp = (e: any) => {

    e.preventDefault();

    let buyPol = document.getElementById("buyPolicy");

    let btn = buyPol?.getElementsByTagName("button")[0];

    let span: any = buyPol?.getElementsByTagName("span")[0]!;

    if (buyPol !== null && btn) {

      buyPol.style.display = "block";

      buyPol.style.opacity = "1";

      buyPol.style.textAlign = "right";

      buyPol.style.backgroundColor = "#457EC2";

      btn.style.color = "rgba(255,255,255, 1)";

      span.innerHTML = buyPolicy ? buyPolicy : "Loading...";

    }

  };


  return (

    <>

      <section className={classes.sectionContainer}>

        <header className={classes.sectionHeader}>

          <h2 className={classes.tittle}>Vehicle not listed</h2>

          <p className={classes.subTittle}>

            Please let us know the make, model, and year on your vehicle.

          </p>

        </header>


        <form className={classes.selectForm}>

          <input

            type="text"

            name="make"

            placeholder="Make"

            className={clsx(classes.vehicleInput, errors.make && classes.inputError)}

            value={car.make}

            onChange={handleChange}

          />


          <input

            type="text"

            name="model"

            placeholder="Model"

            className={clsx(classes.vehicleInput, errors.model && classes.inputError)}

            value={car.model}

            onChange={handleChange}

          />


          <input

            type="text"

            name="year"

            placeholder="Year"

            className={clsx(classes.vehicleInput, errors.year && classes.inputError)}

            value={car.year}

            onChange={handleChange}

          />


          <input

            type="email"

            name="email"

            placeholder="Email"

            className={clsx(classes.vehicleInput, errors.email && classes.inputError)}

            value={car.email}

            onChange={handleChange}

          />


          <div className={classes.stayInformed}>

            <p className={classes.stayInformedSubs}>

              Stay informed when fitment data is available for your vehicle!{" "}

              <a href="#" onClick={termsPopUp}>

                View Privacy Policy

              </a>

            </p>


            <input

              className={classes.stayInformedCheckbox}

              type="checkbox"

              name="isStayInformed"

              checked={car.isStayInformed}

              onChange={handleChange}

            />

          </div>


          <div className={classes.buttonsContainer}>

            <button className={classes.buttons} onClick={handleSubmit}>

              Next

            </button>

            <button className={classes.buttons} onClick={handleBack}>

              Back

            </button>

          </div>

        </form>

      </section>


      <div className={clsx(classes.tc)}>

        <div className={clsx(classes.grayText, classes.tcText)}>

          <div id="buyPolicy" className={classes.termsPopUp}>

            <button className={classes.policyCloseBtn} onClick={policyCloseBtn}>

              X

            </button>

            <div>

              <span>Popup text...</span>

            </div>

          </div>

        </div>

      </div>

    </>

  );

};


const useStyles = makeStyles(theme => ({

  sectionContainer: {

    marginTop: 80,

  },

  sectionHeader: {

    display: "flex",

    flexDirection: "column",

    alignItems: "center",

    marginBottom: 15,

  },

  tittle: {

    fontSize: 35,

    fontWeight: 700,

    marginBottom: 0,

    color: theme.colors.darkText,

    textAlign: "center",


    [theme.device.mobile()]: {

      fontSize: 25,

      marginBottom: 10,

    },

  },

  subTittle: {

    color: theme.colors.grayText,

    fontSize: 20,

    fontWeight: 600,

    textAlign: "center",


    [theme.device.mobile()]: {

      fontSize: 16,

      margin: "0 20px",

    },

  },

  selectForm: {

    width: "50%",

    margin: "0 auto",


    [theme.device.mobile()]: {

      width: "80%",

    },

  },

  vehicleInput: {

    margin: "0 auto",

    border: "2px solid " + theme.colors.blue,

    display: "block",

    width: "60%",

    padding: "10px 14px",

    borderRadius: "14px",

    marginBottom: "8px",

    fontSize: "1.5rem",

    color: theme.colors.blue,

    textAlign: "right",


    "&::placeholder": {

      color: theme.colors.blue,

      textAlign: "right",

    },


    [theme.device.mobile()]: {

      width: "90%",

    },

  },

  stayInformed: {

    display: "flex",

    alignItems: "center",

    margin: "0 auto",

    width: "60%",


    [theme.device.mobile()]: {

      width: "90%",

    },

  },

  stayInformedSubs: {

    textAlign: "right",

    color: theme.colors.overlayDark,

    fontWeight: 600,

    marginRight: 20,


    "& a": {

      color: theme.colors.blue,

      fontWeight: 700,

      letterSpacing: 0.5,

    },


    [theme.device.mobile()]: {

      fontSize: 14,

    },

  },

  stayInformedCheckbox: {

    cursor: "pointer",

    height: 40,

    width: 50,

  },

  buttonsContainer: {

    display: "flex",

    justifyContent: "center",

  },

  buttons: {

    border: "1px solid " + theme.colors.blue,

    padding: "7px 23px",

    borderRadius: "24px",

    fontWeight: 700,

    margin: "12px",

    color: theme.colors.blue,


    "&:hover": {

      backgroundColor: theme.colors.blue,

      color: "white",

    },

  },

  grayText: {

    wordBreak: "break-word",

    direction: "rtl",

    textAlign: "end",

    color: theme.colors.grayText,

    fontSize: 22,

    fontWeight: 600,


    [theme.device.mobile()]: {

      textAlign: "start",

    },

  },

  tc: {

    width: "100%",

    boxSizing: "border-box",

    padding: "15px 0 20px 0",

    display: "flex",

    justifyContent: "flex-end",

    alignItems: "center",


    [theme.device.mobile()]: {

      padding: "15px 20px 20px 0",

    },

  },

  tcText: {

    fontSize: 15,

    paddingTop: 2,

    marginRight: 5,

  },

  termsPopUp: {

    color: "white",

    width: "50%",

    display: "none",

    position: "absolute",

    left: "25%",

    border: "1px solid #114649",

    padding: "2% 3%",

    borderRadius: "16px",

    opacity: 0,

    overflowX: "hidden",

    overflowY: "scroll",

    zIndex: 2,

    height: "300px",


    "&::-webkit-scrollbar": {

      width: 7,

    },

    "&::-webkit-scrollbar-track": {

      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,

    },

    "&::-webkit-scrollbar-thumb": {

      backgroundColor: theme.colors.blue,

      outline: `1px solid slategrey`,

    },


    [theme.device.mobile()]: {

      width: "80%",

      left: "10%",

    },

  },

  policyCloseBtn: {

    width: "12px",

    height: "12px",

    position: "absolute",

    top: 12,

    right: 12,

    borderRadius: "18px",

  },

  inputError: {

    border: "2px solid " + theme.colors.red,

  },

}));


export default VehicleNotFound;