import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React from "react";
import { useAppSelector, useAppDispatch } from "../../../store/store";
const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        alignItems: "center",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        paddingBottom: 10,
        width: "70%",
        alignItems: "center",
        borderBottom: `1px solid ${theme.colors.blue}`,
      },
    },
    item: {
      userSelect: "none",
      color: theme.colors.darkBlue,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      lineHeight: 1.7,
      textDecoration: "none",
      "&:hover": {
        transition: "0.3s",
        fontWeight: "bold"
      },
      [theme.device.mobile()]: {
        fontWeight: 600,
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
  }),
  { name: "info-links" }
);
type TProps = {
};
const FooterCategoriesLinks = ({ }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const siteCategories = useAppSelector(state => state.siteConfig.categories);
  const onCategoryLinkClick = (path: string) => {
    dispatch(push(path, true));
  };
  const categoryLinks = siteCategories.map(c => (
    <a key={c.category.id} href={`/products/categories/${c.category.id}`} className={classes.item} onClick={() => onCategoryLinkClick(`/products/categories/${c.category.id}`)}>
      {c.name}
    </a>
  ))
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {categoryLinks}
      </div>
    </div>
  );
};
export default FooterCategoriesLinks;