import exploreGracoService from "../services/explore-graco";
import { TExploreGraco } from "../services/explore-graco/types";

import { createAsyncThunk, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { TThunk } from './store';


type State = TExploreGraco;

const initialState: State = {
    exploreCarousel: [],
    exploreCarouselMobile: []
}

const fetchExploreGracoConfig: TThunk<TExploreGraco> = createAsyncThunk(
    "explore-graco/fetch",
    async () => await exploreGracoService.fetchExploreGraco()
);

const reducer = createReducer(initialState, {
    [fetchExploreGracoConfig.fulfilled.type]: (state, action: PayloadAction<TExploreGraco>) => {
        state = action.payload;
        return state;
    },
});

const actions = {
    fetchExploreGracoConfig
};

const exploreGracoStore = {
    reducer,
    actions
};

export default exploreGracoStore;

export type { State };