import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Product } from "../../../../services/products/types";
import languagesStore from "../../../../store/languages-store";
import { useAppSelector } from "../../../../store/store";
import { NewPhrase } from "../../../../utils/language";
import Arrow from "../../../common/arrow";
import SingleProductMenuContent from "./single-product-menu-content";

type TData = {
  title: NewPhrase;
  type: TMenuType;
};

type TMenuType = "description" | "specifications" | "includedInTheKit" | "qna" | "reviews" | null;

const getMenuData = (): TData[] => [
  {
    title: "description",
    type: "description",
  },
  {
    title: "specification",
    type: "specifications",
  },
  {
    title: "kit",
    type: "includedInTheKit",
  },

  {
    title: "qna",
    type: "qna",
  },
  {
    title: "reviews",
    type: "reviews",
  },
];

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      padding: "70px 70px 90px 70px",
      [theme.device.mobile()]: {
        padding: "40px 15px 20px 15px",
      },
    },
    menu: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
    },
    menuItems: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
      justifyItems: "baseline",
    },
    menuItem: {
      textAlign: "end",
      userSelect: "none",
      cursor: "pointer",
      color: theme.colors.grayText,
      fontSize: 21,
      fontWeight: 600,
      transition: "0.2s",
    },
    menuItemActive: {
      cursor: "default",
      color: theme.colors.blue,
    },
    menuItemBlackActive: {
      color: theme.colors.black,
    },
    line: {
      top: "100%",
      position: "absolute",
      width: "100%",
      height: 5,
      borderRadius: 25,
      backgroundColor: theme.colors.blue,
      transition: "0.2s",
    },
    content: {
      marginTop: 25,
      borderRadius: 15,
      border: `1px solid ${theme.colors.blue}`,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      boxSizing: "border-box",
      padding: "8px 10px",
      [theme.device.mobile()]: {
        borderWidth: 3,
        marginTop: 0,
      },
    },
    contentBlack: {
      border: `1px solid ${theme.colors.brown}`,
    },
    dropMenuTitle: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dropMenuContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      height: 0,
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s",
    },
    dropMenuTitleActive: {
      paddingBottom: 7,
    },
    active: {
      height: "auto",
      opacity: 1,
      visibility: "visible",
    },
    activeDesktop: {
      display: "flex",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      display: "flex",
      margin: "1px 0",
      [theme.device.desktop()]: {
        display: "none",
      },
    },

    paginationWrapper: {
      alignSelf: "center",
      display: "flex",
      alignItems: "center",
      width: 100,
      justifyContent: "space-evenly",
    },

    page: {
      border: `2px solid ${theme.colors.blue}`,
      borderRadius: 7,
      height: "37px",
      width: "37px",
      alignSelf: "center",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 22,
      color: theme.colors.blue,
    },

    arrow: {
      border: `solid ${theme.colors.blue}`,
      borderWidth: "0 3px 3px 0",
      height: 25,
      width: 25,
      minWidth: 10,
      transform: "rotate(135deg)",
      position: "relative",
      [theme.device.mobile()]: {
        marginRight: 3,
        height: 20,
        width: 20,
      },

      "&:hover": {
        cursor: "pointer",
      },
    },

    arrowNotActive: {
      "&:hover": {
        cursor: "auto",
      },
      opacity: 0.6,
    },

    arrowRight: {
      transform: "rotate(-45deg)",
    },
  }),
  { name: "single-product-menu" }
);

type TProps = {
  product: Product;
};

type SingleTProps = {
  classes: any;
  activeMenu: TMenuType;
  item: any;
  product: any;
  startIndex: number;
  leftArrowIsNotActive: any;
  handleLeftArrowClick: any;
  currentPage: number;
  rightArrowIsNotActive: any;
  handleRightArrowClick: any;
  theme: any;
  onToggle: any;
  label: any;
  menuTopOffset: any;
};

const SingleProductItem = (props: SingleTProps) => {
  const {
    classes,
    activeMenu,
    item,
    product,
    startIndex,
    leftArrowIsNotActive,
    handleLeftArrowClick,
    currentPage,
    rightArrowIsNotActive,
    handleRightArrowClick,
    theme,
    onToggle,
    label,
    menuTopOffset,
  } = props;

  

  const itemRef: any = useRef(null);

  return (
    <div ref={itemRef} key={item.title} className={clsx(classes.content, classes.activeMobile)}>
      <div
        className={clsx(
          classes.dropMenuTitle,
          activeMenu === item.type && classes.dropMenuTitleActive
        )}
        onClick={() => onToggle(item.type, itemRef)}
      >
        <Arrow color={theme.colors.blue} active={activeMenu === item.type} />

        {
          <div className={clsx(classes.menuItem, classes.menuItemActive)}>{label}</div>
        }
      </div>
      <div className={clsx(classes.dropMenuContent, activeMenu === item.type && classes.active)}>
        <SingleProductMenuContent product={product} type={item.type} startIndex={startIndex} menuTopOffset={menuTopOffset} />
        {activeMenu === "reviews" && (
          <div className={classes.paginationWrapper}>
            <div
              className={clsx(classes.arrow, leftArrowIsNotActive && classes.arrowNotActive)}
              onClick={handleLeftArrowClick}
            />
            <div className={classes.page}>{currentPage}</div>
            <div
              className={clsx(
                classes.arrow,
                classes.arrowRight,
                rightArrowIsNotActive && classes.arrowNotActive
              )}
              onClick={handleRightArrowClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SingleProductMenu = ({ product }: TProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const lang = useAppSelector(state => state.languages.language);


  const menuData = getMenuData().filter(item => product?.[item.type!]?.length > 0);

  const DEFAULT_MENU: TMenuType = isMobile ? null : menuData[0]?.type!;

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const [activeMenu, setActiveMenu] = useState<TMenuType>(DEFAULT_MENU);
  const [linePos, setLinePos] = useState<number>(0);
  const [lineWidth, setLineWidth] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemRef, setItemRef] = useState<any>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const firstMenuElRef = useRef<HTMLLIElement>(null);
  const [menuTopOffset, setMenuTopOffset] = useState<any>(0);
  const menuRef = useRef<any>(null);

  const onMenuItemClick = (menuType: TMenuType) => (event: React.MouseEvent) => {
    const parent = event.currentTarget.parentElement?.getBoundingClientRect();
    const element = event.currentTarget.getBoundingClientRect();

    const linePos = parent!.right - element.right;

    setLinePos(linePos);
    setLineWidth(event.currentTarget.clientWidth);
    setActiveMenu(menuType);
    setCurrentPage(1);
  };

  const menuItems = menuData.map((item, i) => {
    const title = item.title;
    const label = activePhrases && activePhrases[title];
    return (
      <li
        key={i}
        className={clsx(classes.menuItem, activeMenu === item.type &&  activeMenu === item.type ? classes.menuItemActive : "")}
        onClick={onMenuItemClick(item.type)}
        ref={item?.type! === DEFAULT_MENU ? firstMenuElRef : null}
      >
        {label}
      </li>
    );
  });

  const onToggle = (menuType: TMenuType, itemReference: any) => {
    setActiveMenu(menuType === activeMenu ? null : menuType);
    setItemRef(itemReference);
  };

  useEffect(() => {
    setMenuTopOffset(menuRef?.current?.offsetTop)
  }, [])

  useEffect(() => {
    const topOffset = itemRef?.current?.offsetTop;
    const offset = Number(topOffset) + Number(menuTopOffset) - 300;
    window.scrollTo({ top: offset, behavior: "smooth" });
  }, [itemRef]);

  useEffect(() => {
    if (lineRef.current !== null) {
      lineRef.current.style.width = `${lineWidth}px`;
      lineRef.current.style.right = `${linePos}px`;
    }
  }, [lineRef, lineWidth, linePos]);

  useEffect(() => {
    setActiveMenu(menuData[0]?.type!);
    setLinePos(0);
    if (firstMenuElRef.current !== null) {
      setLineWidth(firstMenuElRef.current.clientWidth);
    }
  }, [product]);

  useEffect(() => {
    if (firstMenuElRef.current !== null) {
      setActiveMenu(DEFAULT_MENU);
      setLineWidth(firstMenuElRef.current.clientWidth);
      setLinePos(0);
    }
  }, [lang, DEFAULT_MENU]);

  const numberOfPages = Math.ceil(product.reviews.length / 5);
  const startIndex = currentPage - 1;
  const handleLeftArrowClick = () => currentPage !== 1 && setCurrentPage(currentPage - 1);

  const handleRightArrowClick = () =>
    currentPage < numberOfPages && setCurrentPage(currentPage + 1);

  const leftArrowIsNotActive = currentPage === 1;
  const rightArrowIsNotActive = currentPage === numberOfPages;

  const dropMenuItems = menuData.map((item, i) => {
    const { title } = item;

    const label = activePhrases && activePhrases[title];
    return (
      <SingleProductItem
        key={item.title}
        classes={classes}
        activeMenu={activeMenu}
        item={item}
        product={product}
        startIndex={startIndex}
        leftArrowIsNotActive={leftArrowIsNotActive}
        handleLeftArrowClick={handleLeftArrowClick}
        currentPage={currentPage}
        rightArrowIsNotActive={rightArrowIsNotActive}
        handleRightArrowClick={handleRightArrowClick}
        theme={theme}
        onToggle={onToggle}
        label={label}
        menuTopOffset={menuTopOffset}
      />
    );
  });

  return (
    <div className={classes.root} ref={menuRef}>
      {dropMenuItems}
      <div className={clsx(classes.menu, classes.activeDesktop)}>
        <ul className={classes.menuItems} dir="rtl">
          {menuItems}
        </ul>
        <div className={classes.line} ref={lineRef} />
      </div>
      <div className={clsx(classes.content, classes.activeDesktop)}>
        <SingleProductMenuContent product={product} type={activeMenu} startIndex={startIndex} menuTopOffset={menuTopOffset} />
        {activeMenu === "reviews" && (
          <div className={classes.paginationWrapper}>
            <div
              className={clsx(classes.arrow, leftArrowIsNotActive && classes.arrowNotActive)}
              onClick={handleLeftArrowClick}
            />
            <div className={classes.page}>{currentPage}</div>
            <div
              className={clsx(
                classes.arrow,
                classes.arrowRight,
                rightArrowIsNotActive && classes.arrowNotActive
              )}
              onClick={handleRightArrowClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleProductMenu;

export type { TMenuType };
