import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import categoriesStore from "../../store/categories-store";
import { useAppSelector } from "../../store/store";
import { CATEGORIES, InternalNameToCategoryMap, checkImporterDeals, checkProductsPage } from "../../utils/helper";
import { scrollStyles } from "../../utils/style-utils";
import CarouselLinear from "../carousel/carousel";
import Panel from "../common/panel";
import CategoryItem from "./category-item";
import DropCategories from "./drop-categories";
import CategoryDropdown from "./category-dropdown";

import Strollers from "../../static/images/strollers.jpg"
import Swings from "../../static/images/swing.jpg"
import Feeding from "../../static/images/feeding.jpg"
import Carriers from "../../static/images/carriers.jpg"
import CarSeats from "../../static/images/car_seats.jpg"

import StrollersMobile from "../../static/images/strollers_mobile.jpg"
import SwingsMobile from "../../static/images/swing_mobile.jpg"
import FeedingMobile from "../../static/images/feeding_mobile.jpg"
import CarriersMobile from "../../static/images/carriers_mobile.jpg"
import CarSeatsMobile from "../../static/images/car_seats_mobile.jpg"

type TPopupPos = "left" | "leftMiddle" | "middle" | "afterMiddle" | "middleRight" | "right" | "";
type StyleProps = {
  isHovered: boolean;
};

type CategoryPanelProps = {
  hideCarousel?: boolean;
};

export function resolveCategoryBannerImg(internalName: string, isMobile: boolean){
  switch (InternalNameToCategoryMap[internalName]) {
    case CATEGORIES.STROLLER:
      if(isMobile) return StrollersMobile
      return Strollers 
    case CATEGORIES.CAR_SEAT:
      if(isMobile) return CarSeatsMobile
      return CarSeats 
    case CATEGORIES.CARRIER:
      if(isMobile) return CarriersMobile
      return Carriers 
    case CATEGORIES.FEEDING:
      if(isMobile) return FeedingMobile
      return Feeding 
    case CATEGORIES.SWING:
      if(isMobile) return SwingsMobile
      return Swings 
    default:
      if(isMobile) return CarSeatsMobile
      return CarSeats 
  }
}

const CategoryPanel = ({ hideCarousel }: CategoryPanelProps): JSX.Element => {
  // The empty object is intentional because otherwise the app crashes
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const classes = useStyles({ isHovered });

  const isImporterDeals = checkImporterDeals(
    useAppSelector(state => state.router.location.pathname)
  );
  const isProductsPage = checkProductsPage(
    useAppSelector(state => state.router.location.pathname)
  );
  const categories = useAppSelector(categoriesStore.selectors.categoriesPageSelector());
  const categoriesData = useAppSelector(state => state.categories.categoriesData.data);
  const pathname = useAppSelector(state => state.router.location.pathname)
  const { catalogCarousel } = useAppSelector(state => state.siteConfig);
  const { catalogCarouselMobile } = useAppSelector(state => state.siteConfig);

  const [currentCategory, setCurrentCategory] = useState<number | null>(null);
  const [popupPos, setPopupPos] = useState<TPopupPos>("");
    //console.log(categories)
  const catalogPanel = categories.map(category => {
    //console.log(category)
    return (
      <CategoryItem
        key={category.id}
        currentCategory={currentCategory}
        category={category}
        setCurrentCategory={setCurrentCategory}
      />)
  })

  function getProductsPageBannerSrc(){
    const currentCategoryId = pathname.split("/").pop();
    const currentCategory = categories.find(c => c.id === Number(currentCategoryId))
    if(!currentCategory) return catalogCarousel[0].url 
    
    return resolveCategoryBannerImg(currentCategory.internalName, isMobile)
  }


  const onPopupMouseLeave = (): void => {
    setIsHovered(false);
    setCurrentCategory(null);
  };

  const isMobile = window.innerWidth < 600;


  useEffect(() => {
    const categoryPos = categories.indexOf(categories.find(cat => cat.id === currentCategory)!) + 1;
    //console.log(categoryPos, 'hover position')
    if (categoryPos === 0) return;

    const categoriesCount = categories.length;

    const isEven = categoriesCount % 2 === 0;

    const middle = isEven ? categoriesCount / 2 : Math.round(categoriesCount / 2);
    // console.log(middle, 'mid')

    //if (isEven) {

    if (categoryPos === middle - 1) {
      //console.log('opa tuka')
      setPopupPos("leftMiddle");
      return;
    }

    if (categoryPos < middle) setPopupPos("left");

    if (categoryPos === middle) {
      setPopupPos("middle");
      return;
    }

    if (categoryPos === middle + 1) {
      setPopupPos("afterMiddle");
    }

    if (categoryPos === middle + 2) {
      setPopupPos("middleRight");
      return;
    }

    if (categoryPos > middle + 2) setPopupPos("right");

    //return;
    //}

    if (categoryPos < middle) setPopupPos("left");

    if (categoryPos === middle || categoryPos === middle - 1 || categoryPos === middle + 1) {
      setPopupPos("middle");
      return;
    }

    if (categoryPos > middle) setPopupPos("right");


  }, [currentCategory]);

  return (
    <div className={classes.root}>
      {!hideCarousel && catalogCarousel.length > 0 && catalogCarouselMobile.length > 0 &&
        (isProductsPage ? 
          <img src={getProductsPageBannerSrc()} alt="" className={classes.productPageBanner} />
        :
          <CarouselLinear
            slides={isMobile ? catalogCarouselMobile : catalogCarousel}
            className={{ dots: classes.dots, dot: classes.dot }}
          />
        )
      }
      <DropCategories
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        isImporterDeals={isImporterDeals}
      />
      <div
        className={classes.menu}
        onMouseLeave={onPopupMouseLeave}
        onMouseOver={() => setIsHovered(true)}
      >

        <Panel className={{ panel: classes.panel }}>{catalogPanel}</Panel>
        {/* {console.log(catalogPanel, 'Panelka')}
        {console.log(popupPos, 'location')} */}
        <div
          className={clsx(
            classes.popup,
            popupPos === "left" && classes.left,
            popupPos === "leftMiddle" && classes.leftMiddle,
            popupPos === "middle" && classes.middle,
            popupPos === "afterMiddle" && classes.afterMiddle,
            popupPos === "middleRight" && classes.right,
            //popupPos === "right" && classes.right,
            (!!currentCategory || currentCategory === 0) && classes.active
          )}
        >
          <CategoryDropdown 
            categories={categoriesData} 
            hoveredCategory={currentCategory} 
            setHoveredCategory={setCurrentCategory} 
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryPanel;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    banner: {
      userSelect: "none",
      width: "100%",
    },
    menu: {
      position: "relative",
      width: "100%",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    popup: {
      transition: "0.4s",
      opacity: 0,
      visibility: "hidden",
      zIndex: 3,
      top: "100%",
      position: "absolute",
      width: "fit-content",
      padding: "10px 15px 0 15px",
      boxSizing: "border-box",
    },
    left: {
      left: 0,
    },
    leftMiddle: {
      left: "12%",
    },
    middle: {
      left: "40%",
    },
    afterMiddle: {
      left: "50%",
    },
    middleRight: {
      right: "20%",
    },
    right: {
      right: -7,
    },
    content: ({ isHovered }: StyleProps): CreateCSSProperties => ({
      width: "100%",
      height: "fit-content",
      backgroundColor: theme.colors.text,
      borderRadius: 20,
      border: isHovered ? `1px solid ${theme.colors.grayBorder}` : "",
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      padding: isHovered ? "15px 30px" : "",
    }),
    subCategories: {
      listStyle: "none",
      overflowY: "auto",
      ...scrollStyles(theme, false),
      width: "100%",
      display: "grid",
      gridGap: "12px 15px",
      // gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridColumnGap: 30,
    },
    carousel: {
      minHeight: 200,
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    productPageBanner: {
      width: "100vw",
      objectFit: "cover",
      objectPosition: "center right",
      borderTop: `1px solid ${theme.colors.gray}`,
      [theme.device.mobile()]: {  
        objectPosition: "top center",
      }
    },
    panel: {
      padding: "10px 30px",
      justifyContent: "space-between",
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
    active: {
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "category-panel" }
);
