import { makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import SocialMediaBar from "../social-media-bar/social-media-bar";
import CarouselLinear from "../carousel/carousel";
import { config } from "../../config";

import { TCatalogItem } from "../../services/site-config-service/types";

import { useAppSelector } from "../../store/store";
import { Explore } from "../common/explore";
import { Partner } from "../common/partner";
import { Join } from "../common/join";
import { MeetTheGear } from "../common/meet-the-gear";
import { UnderCarousel } from "../common/under-carousel";
import TestPage from "./test-page";

const HomePage = (): JSX.Element => {
  const classes = useStyles();

  const {
    product,
    categories,
    carousel,
    carouselMobile,
    middleBanner,
    middleBannerMobile,
    pathname,
    siteConfig,
  } = useAppSelector(state => ({
    products: state.siteConfig.products?.map(p => p.product),
    product: state.siteConfig.product!,
    carousel: state.siteConfig.carousel,
    carouselMobile: state.siteConfig.carouselMobile,
    middleBanner: state.siteConfig.middleBanner!,
    middleBannerMobile: state.siteConfig.middleBannerMobile!,
    categories: state.siteConfig.categories,
    pathname: state.router.location.pathname,
    siteConfig: state.siteConfig,
  }));

  const catalogItems: TCatalogItem[] = categories.map(category => ({
    id: category.category.id,
    name: category.name,
    backgroundImg: category?.category?.backgroundImg?.url!,
    path: `${config.routes.productsPage}/${category.category.id}`,
  }));

  const presentationCatalogItem: TCatalogItem = {
    id: product?.id!,
    name: product?.name!,
    backgroundImg: product?.mainPicture?.url!,
    path: `${config.routes.product}/${product?.catalogNumber}`,
  };

  const isMobile = window.innerWidth < 600;

  return (
    <>
      <Helmet>
        <title>{siteConfig.title}</title>
        <meta name="description" content={siteConfig.description} />
        <meta name="keywords" content={siteConfig.keywords} />
      </Helmet>
      <div className={classes.wrapper}>
        
          <Fragment>
            <CarouselLinear
              slides={isMobile ? carouselMobile : carousel}
              className={{ root: classes.carousel, slides: classes.slides, dots: classes.dots }}
            />
            <UnderCarousel />
            <MeetTheGear />
            <TestPage />
            <Explore />
            <Partner />
          </Fragment>
        
        {/* <Join /> */}

        {/*//   <Catalog pathname={pathname} catalogItems={catalogItems} />

    //   {!!middleBanner && (
    //     <img
    //       className={clsx(classes.banner)}
    //       src={isMobile ? middleBannerMobile.url : middleBanner.url}
    //       alt="home middle page banner"
    //     />
    //   )}

    //   {!!product && <Presentation catalogItem={presentationCatalogItem} video={product?.video!} />}*/}
        <SocialMediaBar />
      </div>
    </>
  );
};

export default HomePage;

export const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },

    post: {
      width: 500,
      minHeight: 600,
      border: `1px solid ${theme.colors.border}`,
      borderRadius: 15,
      boxShadow: theme.colors.shadow,
    },

    banner: {
      width: "100%",
      [theme.device.mobile()]: {
        height: "25vh",
      },
    },
    instagramHeading: {
      fontSize: 42,
      textAlign: "center",
      color: theme.colors.blue,
      marginBottom: 40,
      margin: "0 auto",
      [theme.device.mobile()]: {
        marginBottom: 0,
      },
    },
    carousel: {
      height: "100%",
    },

    slides: {
      [theme.device.mobile()]: {
        position: "relative",
      },
    },

    dots: {
      margin: "80px 0",
      [theme.device.tablet()]: {
        margin: "10px 0",
        // position: "relative",
      },
    },

    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },

    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    joinJoie: {
      width: "100%",
      minHeight: 600,
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        minHeight: 0,
      },
    },
    underCarouselContatiner: {
      marginTop: '2%',
      height: '55vh',
      width: '90vw',
      [theme.device.mobile()]: {
        marginTop: '0%',
        width: '100vw',
        height: 'initial'
      },
    },
    underCarouselImg: {
      width: '35%',
      height: '100%',
      objectFit: 'cover',
      [theme.device.mobile()]: {
        width: '100%',
        height: '386px',
        marginTop: '-4px'
      }
    },
    underCarouselIframe: {
      width: '65%',
      height: '100%',
      border: 'none',
      [theme.device.mobile()]: {
        width: '100%',
        height: '224px',
      }
    },
    bigTitle: {
      padding: 0,
      fontWeight: 700,
      fontSize: 40,
      color: theme.colors.text,
    },
    img: {
      zIndex: -1,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    joinJoieForm: {
      width: "100%",
      boxSizing: "border-box",
      padding: "0 30px",
      display: "flex",
      flexDirection: "column",
      gridRowGap: 8,
    },
    exploreInfoText: {
      boxSizing: "border-box",
      padding: "0 15px",
      textAlign: "center",
      width: "100%",
      fontWeight: 600,
      fontSize: 15,
      color: theme.colors.text,
    },
    meetTheGear: {
      padding: "20px 0 35px 0",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.device.mobile()]: {
        padding: "0 0 20px 0",
      },
    },
    meetTheGearTitle: {
      color: theme.colors.blue,
    },
    meetTheGearText: {
      boxSizing: "border-box",
      width: "50%",
      margin: "auto",
      padding: "10px 0",
      textAlign: "center",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        width: "80%"
      },
    },
    meetTheGearCategoriesText: {
      [theme.device.mobile()]: {
        paddingBottom: 0,
        marginBottom: 10
      },
    },
    meetTheGearContainer: {
      paddingTop: 20,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      gridColumnGap: "4%",
      height: 180,
      [theme.device.mobile()]: {
        paddingTop: 0,
        gridColumnGap: 0,
        display: "initial",
        height: "min-content"
      },
    },
    meetTheGearCategoriesContainer: {
      [theme.device.tablet()]: {
        display: "block",
        textAlign: "center",
        marginBottom: 150,
      }
    },
    meetTheGearArrow: {
      height: "100%",
      position: "relative",
    },
    meetTheGearArrowImg: {
      position: "relative",
      top: "20%",
      bottom: 0,
      left: "100%",
      // margin: "auto",
      [theme.device.mobile()]: {
        top: "40%",
      },
    },
    meetTheGearRightArrow: {
      height: "100%",
      position: "relative",
    },
    meetTheGearRightArrowImg: {
      position: "relative",
      top: "20%",
      bottom: 0,
      margin: "auto",
      right: "100%",
      [theme.device.mobile()]: {
        top: "40%",
      },
    },
    meetTheGearItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    meetTheGearItemImg: {
      width: 250,
      height: "auto",
      display: "block",
      margin: "0 auto",
      marginTop: 10,
      [theme.device.tablet()]: {
        width: 120,
      },
    },
    meetTheGearItemImgUnder: {
      width: 170,
      height: "auto",
      display: "block",
      margin: "0 auto",
      [theme.device.mobile()]: {
        width: 170,
      },
    },
    meetTheGearCategoriesItemImg: {
      [theme.device.tablet()]: {
        width: 40,
      },
    },
    meetTheGrearItemText: {
      color: theme.colors.darkBlue,
      fontWeight: "bold"
    },
    text: {
      fontSize: 13,
      fontWeight: 600,
    },
    title: {
      padding: 0,
      fontWeight: 700,
      fontSize: 30,
    },
    meetTheGearItemTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.colors.blue,
      textAlign: "center",
    },
    meetTheGearItemPara: {
      fontSize: 16,
      fontWeight: 700,
      marginBottom: 12,
      color: theme.colors.blue,
      textAlign: "center",
    },
    joinJoieInput: {
      border: "none",
      borderRadius: 5,
      textAlign: "end",
      padding: "12px 10px",
      boxSizing: "border-box",
      color: theme.colors.grayText,
      fontSize: 21,
      "&::placeholder": {
        color: theme.colors.gray,
      },
    },
    joinJoieSubmitBtn: {
      color: theme.colors.white,
      textDecoration: "underline",
      textAlign: "end",
      fontSize: 18,
    },
  }),
  { name: "home-page" }
);
