import { makeStyles } from "@material-ui/styles";
import React from "react";

import { useAppSelector } from "../../../store/store";
import CarouselLinear from "../../carousel/carousel";
import { getPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";

const BuyPolicyPanel = (): JSX.Element => {
  // The empty object is intentional because otherwise the app crashes
  const classes = useStyles({});

  const { lang, slides, slidesMobile } = useAppSelector(state => ({
    lang: state.languages.language,
    slides: state.siteConfig.catalogCarousel,
    slidesMobile: state.siteConfig.catalogCarouselMobile,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const isMobile = window.innerWidth < 600;

  return (
    <div className={classes.root}>
      <CarouselLinear
        slides={isMobile ? slidesMobile : slides}
        className={{ root: classes.carousel, dots: classes.dots, dot: classes.dot }}
      />
        <div className={classes.welcome} >
          {activePhrases && activePhrases["buy_policy"]}
        </div>
    </div>



  );
};

export default BuyPolicyPanel;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      // paddingTop: "60px",
    },
    welcome: {
      display: "grid",
      placeItems: "center",
      color: "#fff",
      width: "100%",
      height: "65px",
      fontSize: 40,
      textAlign: "center",
      backgroundColor: theme.colors.blue,
      [theme.device.mobile()]: {
        fontSize: 30,
      },
    },

    welcomeBlack: {
      display: "grid",
      placeItems: "center",
      color: "#fff",
      width: "100%",
      height: "65px",
      fontSize: 40,
      textAlign: "center",
      backgroundColor: theme.colors.black,
      [theme.device.mobile()]: {
        fontSize: 30,
      },
    },
    carousel: {
      [theme.device.mobile()]: {
        minHeight: 100,
      },
    },
    dots: {
      marginBottom: 20,
      [theme.device.mobile()]: {
        gridColumnGap: 15,
      },
    },
    dot: {
      height: 22,
      width: 22,
      [theme.device.mobile()]: {
        height: 17,
        width: 17,
      },
    },
  }),
  { name: "buy-policy-panel" }
);
