//@ts-nocheck
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { ReactComponent as FacebookIcon } from "../../../static/icons/fb.svg";
import { ReactComponent as LinkedinIcon } from "../../../static/icons/linkedin.svg";
import { ReactComponent as PinterestIcon } from "../../../static/icons/pinterest.svg";
import { ReactComponent as TwitterIcon } from "../../../static/icons/twitter.svg";
import { useAppSelector } from "../../../store/store";

type TProps = {
  links?: {
    facebook: string;
    pinterest: string;
    twitter: string;
    linkedin: string;
  };
  className?: {
    root?: string;
    icon?: string;
  };
};

const Social = ({ className, links }: TProps): JSX.Element => {
  const classes = useStyles();

  const { socialMedia } = useAppSelector(state => state.siteConfig);

  const setUrl = (url: string) => {
    let http = "http://";
    if (url.substring(0, 7) == 'http://'){ return url}
    if (url.substring(0, 8) == 'https://'){ return url}
    return http + url;
  }

  return (
    <div className={clsx(classes.root, className?.root)}>
      {socialMedia?.facebook ?
      <a
        className={classes.link}
        href={setUrl(socialMedia?.facebook)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon className={clsx(classes.icon, classes.facebookIcon, className?.icon)} />
      </a> : null }
      {socialMedia?.twitter ?
      <a
        className={classes.link}
        href={setUrl(socialMedia?.twitter)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon className={clsx(classes.icon, classes.twitterIcon, className?.icon)} />
      </a> : null }
      {socialMedia?.linkedin ?
      <a
        className={classes.link}
        href={setUrl(socialMedia?.linkedin)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedinIcon className={clsx(classes.icon, classes.linkedInIcon, className?.icon)} />
      </a> : null }
      {socialMedia?.pinterest ?
      <a
        className={classes.link}
        href={setUrl(socialMedia?.pinterest)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PinterestIcon className={clsx(classes.icon, classes.pinterestIcon, className?.icon)} />
      </a> : null }
    </div>
  );
};

export default Social;

const useStyles = makeStyles(
  theme => ({
    root: {
      marginTop: 15,
      gridColumnGap: 15,
      display: "flex",
      // justifyContent: "space-between",
      margin: "0 auto",
      [theme.device.mobile()]: {
        width: 220,
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
      },
    },
    link: {
      textDecoration: "none",
    },
    icon: {
      height: 30,
      width: 30,
      cursor: "pointer",
      [theme.device.mobile()]: {
        height: 40,
        width: 40,
      },
    },
    facebookIcon: {
      "&:hover, &:focus": {
        filter:
          "invert(34%) sepia(61%) saturate(4330%) hue-rotate(198deg) brightness(95%) contrast(96%)",
      },
    },
    twitterIcon: {
      "&:hover, &:focus": {
        filter:
          "invert(52%) sepia(37%) saturate(3021%) hue-rotate(168deg) brightness(91%) contrast(110%)",
      },
    },
    linkedInIcon: {
      "&:hover, &:focus": {
        filter:
          "invert(32%) sepia(80%) saturate(5861%) hue-rotate(185deg) brightness(95%) contrast(101%)",
      },
    },
    pinterestIcon: {
      "&:hover, &:focus": {
        filter:
          "invert(16%) sepia(77%) saturate(7494%) hue-rotate(352deg) brightness(93%) contrast(88%)",
      },
    },
  }),
  { name: "social" }
);
