import React, { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as CotsCribsIcon } from "../../static/icons/nav-travelcots.svg";
import { ReactComponent as CotsCribsBrownIcon } from "../../static/icons/nav-travelcots-brown.svg";
import { ReactComponent as SoothersBrownIcon } from "../../static/icons/nav-soothers-brown.svg";
import { ReactComponent as HighChairsIcon } from "../../static/icons/nav-highchairs.svg";
import PushChairsIcon from "../../static/icons/nav-pushchairs.png";
import CarSeatsIcon from "../../static/icons/nav-carseats.png";
import SoothersIcon from "../../static/icons/nav-soothers.png";
import { ReactComponent as CareerIcon } from "../../static/icons/carrier_icon.svg";
import CategoryDropdown from "../category/category-dropdown";
import { InternalNameToCategoryMap, CATEGORIES } from "../../utils/helper";
import { TConnectedCategory } from "../../services/site-config-service/types";

type Props = {
  onMenuItemClick: (path: string, id?: number) => void
}

function HeaderCategories({ onMenuItemClick }: Props){
  const classes = useStyles()
  const siteCategories = useAppSelector(state => state.siteConfig.categories);
  const categories = useAppSelector(state => state.categories.categoriesData.data);
  const [hoveredCategory, setHoveredCategory] = useState<number | null>(null)

  const iconSelectByPath = (path: string) => {
    switch (InternalNameToCategoryMap[path]) {
      case CATEGORIES.STROLLER:
        return <img src={PushChairsIcon} className={classes.menuIcon} />

      case CATEGORIES.CAR_SEAT:
        return <img src={CarSeatsIcon} className={classes.menuIcon}/>

      case CATEGORIES.CARRIER:
        return <CareerIcon className={classes.menuIcon} />;

      case CATEGORIES.FEEDING:
        return <HighChairsIcon className={classes.menuIcon} />

      case CATEGORIES.SWING:
        return <img src={SoothersIcon} className={classes.menuIcon}/>

      default:
        return <CareerIcon className={classes.menuIcon} />;
    }
  };
  
  return (
    <>
      {siteCategories.map(c => (
        <div
          key={c.category.id}
          className={classes.menuItem}
          onMouseOver={() => setHoveredCategory(c.category.id)}
          onMouseLeave={() => setHoveredCategory(null)}
        >
          <div
            className={classes.headerCategoryWrapper} 
            onClick={() => onMenuItemClick(`/products/categories/${c.category.id}`)}>
            {c.name && iconSelectByPath(c.category.internalName)}
            <div>{c.name}</div>
          </div>
          {hoveredCategory === c.category.id && 
            <div className={classes.dropdownWrapper}>
              <div className={classes.arrow}></div>
              <CategoryDropdown isHeaderMenu={true} categories={categories} hoveredCategory={hoveredCategory} setHoveredCategory={setHoveredCategory} />
            </div>
          }
        </div>
      ))}
    </>
  )
}

export default HeaderCategories;

export const useStyles = makeStyles(
  theme => ({
    dropdownWrapper: {
      position: "absolute",
      marginTop: 50,
    },
    arrow: {
      margin: 'auto',
      marginTop: 20,
      position: "relative",
      width: 0, 
      height: 0, 
      borderLeft: "20px solid transparent",
      borderRight: "20px solid transparent",
      borderBottom: `20px solid ${theme.colors.black}`,
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0.9,
        left: -20,
        right: 0,
        bottom: 0,
        borderLeft: "20px solid transparent",
        borderRight: "20px solid transparent",
        borderBottom: `20px solid ${theme.colors.white}`,
      },
    },
    menuItem: {
      width: "7%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: 17,
      lineHeight: "90%",
      color: theme.colors.darkBlue,
      "& span": {
        textAlign: "center",
        marginTop: 5,
      },
      "&:hover": {
        backgorund: theme.colors.orange,
      },
    },
    menuItemBlack: {
      display: "flex",
      width: "7%",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontSize: 17,
      lineHeight: "80%",
      color: "#707070",
      "& span": {
        marginTop: 5,
      },
      "&:first-of-type": {
        borderTop: "1px solid #fff",
      },
    },
    headerCategoryWrapper: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      gap: 5,
      flexDirection: "column"
    },
    menuIcon: {
      cursor: "pointer",
      width: 23,
      height: 30,
    },
    menuIconBlack: {
      cursor: "pointer",
      width: 23,
      height: 30,
    },
  })
)