import strapiService from "../strapi/index";
import { buildProduct } from "../../utils/helper";
import { Product } from "./types";
import { Favorites } from "../users/types";
import { filter } from "lodash";

const getProducts = async (): Promise<Product[]> => {
  const fetchedProducts = await strapiService.fetchProducts();

  try {
    const filteredProducts = fetchedProducts.filter(
      (p: Product) => p?.isPublished && p?.pictures?.length > 0 && p !== null
    );
    return filteredProducts
      ?.map((dto: Product) => buildProduct(dto))
      ?.filter(p => {
        if (
          (!p.parentColor &&
            p.subSubCategories.length <= 0 &&
            p.subCategories.length <= 0 &&
            p.categories.length <= 0) ||
          !p.isPublished
        ) {
          return false;
        }

        return true;
      });
  } catch (error) {
    console.log("error ===>", error);
    return [];
  }
};

// const getProducts = async (): Promise<Product[]> => {
//   const fetchedProducts = await strapiService.fetchProducts();

//   try {
//     return fetchedProducts
//       .map((dto: Product) => buildProduct(dto))
//       .filter(p => {
//         if (
//           (!p.parentColor &&
//             p.subSubCategories.length <= 0 &&
//             p.subCategories.length <= 0 &&
//             p.categories.length <= 0) ||
//           !p.isPublished
//         )
//           return false;

//         return true;
//       });
//   } catch (error) {
//     console.log("error ===>", error);
//     return [];
//   }
// };

const addFavouriteProductToStorage = (product: Favorites, allProducts: Product[]) => {
  let favouritesList: Favorites[] = [];
  const sessionFavourites = sessionStorage.getItem("favourites");
  let itemRemoved = false;

  // Checks if the favourites collection in session storage exists and create it
  if (sessionFavourites) {
    favouritesList = JSON.parse(sessionFavourites);
  } else {
    sessionStorage.setItem("favourites", JSON.stringify([]));
    favouritesList = sessionFavourites ? JSON.parse(sessionFavourites) : [];
  }

  // Checks if the product is not already added to the session storage
  const existingDuplicate = favouritesList.find(p => p.productId === product.productId);
  if (existingDuplicate) {
    const indexOfDuplicate = favouritesList.indexOf(existingDuplicate);
    favouritesList.splice(indexOfDuplicate, 1);
    itemRemoved = true;
    sessionStorage.setItem("favourites", JSON.stringify(favouritesList));
  }

  // Checks if the same product but with different color is in the session storage
  // and if so removes all of them
  const currentProduct = allProducts.find(p => p.id === product.productId);

  if (!currentProduct) {
    return;
  }

  const productsInStorage = favouritesList
    .map((storageProduct: Favorites) => {
      return allProducts.find(p => p.id === storageProduct.productId);
    })
    .filter(p => p !== undefined);

  if (currentProduct.isMainColor && productsInStorage.length > 0) {
    const currentProductColors = productsInStorage.filter(
      p =>
        !p?.isMainColor &&
        p?.parentColor &&
        p.parentColor.catalogNumber === currentProduct.catalogNumber
    );

    currentProductColors.forEach(p => {
      favouritesList = favouritesList.filter(
        favouriteProduct => favouriteProduct.productId !== p?.id
      );
    });

    sessionStorage.setItem("favourites", JSON.stringify(favouritesList));
  }

  if (!currentProduct.isMainColor && productsInStorage.length > 0) {
    const mainColorProduct = productsInStorage.find(
      p => p?.isMainColor && p.catalogNumber === currentProduct.parentColor?.catalogNumber
    );

    // Delete the main color if it's in the favourites list
    if (mainColorProduct) {
      favouritesList = favouritesList.filter(
        favouriteProduct => favouriteProduct.productId !== mainColorProduct.id
      );
    }

    const currentProductColors = productsInStorage.filter(
      p =>
        p?.parentColor && p.parentColor.catalogNumber === currentProduct.parentColor?.catalogNumber
    );

    currentProductColors.forEach(p => {
      favouritesList = favouritesList.filter(
        favouriteProduct => favouriteProduct.productId !== p?.id
      );
    });

    sessionStorage.setItem("favourites", JSON.stringify(favouritesList));
  }

  if (!itemRemoved) {
    favouritesList.push(product);
    sessionStorage.setItem("favourites", JSON.stringify(favouritesList));
  }
};

const productIsFavourite = (product: Product): boolean => {
  let favouritesList: Favorites[] = [];
  const sessionFavourites = sessionStorage.getItem("favourites");

  if (sessionFavourites) {
    favouritesList = JSON.parse(sessionFavourites);
  }

  const favouriteProduct = favouritesList.find(p => p.productId === product.id);

  return !!favouriteProduct;
};

const removeDuplicateProducts = (favourites: Favorites[]) =>
  favourites.filter(
    (currentProduct, index, array) =>
      array.findIndex(product => product.productId === currentProduct.productId) === index
  );

export { getProducts, addFavouriteProductToStorage, productIsFavourite, removeDuplicateProducts };
