import { NewPhrases } from "./language";

const phonePattern = /^\+?\d+$/;
const emailPattern =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
const stringsPattern = /^[a-zA-Z\u0590-\u05FF\u200f\u200e ]+$/;
const addressPattern = /\D/;
type InputErrors = {
  [stateProps: string]: string;
};

const validateAuth = (
  credentials: { [field: string]: string },
  activePhrases: NewPhrases | undefined,
  isAuthLogin: boolean
): { isValid: boolean; errors: {} } => {
  const { email, address, password, identifier, firstName, lastName, phoneNumber, verifyPassword } =
    credentials;
  let isValid = true;
  const errors: InputErrors = {};

  const emailLength = email?.length;
  const pwdLength = password?.length;
  const identifierLength = identifier?.length;

  const fieldRequiredMessage = activePhrases ? activePhrases["field_required"] : "שדה נדרש";
  const invalidEmailMessage = activePhrases ? activePhrases["invalid_email"] : "אימייל שגוי";
  const invalidFirstName = activePhrases ? activePhrases["invalid_first_name"] : "שם פרטי לא תקין";
  const invalidLastName = activePhrases ? activePhrases["invalid_last_name"] : "שם משפחה לא תקין";
  const invalidAddress = activePhrases ? activePhrases["invalid_address"] : "כתובת לא תקינה";
  const passwordMessage = activePhrases
    ? activePhrases["password_should_be_at_least_6_characters_long"]
    : "הסיסמה צריכה להיות באורך של לפחות 6 תווים";
  const passwordsDontMatchMessage = activePhrases
    ? activePhrases["passwords_dont_match"]
    : "סיסמאות אינן תואמות";
  const invalidPhoneNumberMessage = activePhrases
    ? activePhrases["invalid_phone_number"]
    : "מספר טלפון לא תקין";

  if (isAuthLogin && activePhrases) {
    if (identifierLength === 0) {
      errors.email = fieldRequiredMessage;
      isValid = false;
    } else if (identifierLength > 0 && !emailPattern.test(identifier)) {
      errors.email = invalidEmailMessage;
      isValid = false;
    }

    if (pwdLength === 0) {
      errors.password = fieldRequiredMessage;
      isValid = false;
    } else if (pwdLength > 0 && pwdLength < 6) {
      errors.password = fieldRequiredMessage;
      isValid = false;
    }
  } else {
    if (emailLength === 0) {
      errors.email = fieldRequiredMessage;
      isValid = false;
    } else if (emailLength > 0 && !emailPattern.test(email)) {
      errors.email = invalidEmailMessage;
      isValid = false;
    }

    if (pwdLength === 0) {
      errors.password = fieldRequiredMessage;
      isValid = false;
    } else if (pwdLength > 0 && pwdLength < 6) {
      errors.password = passwordMessage;
      isValid = false;
    }

    const vPwdLength = verifyPassword?.length;
    if (vPwdLength === 0) {
      errors.verifyPassword = fieldRequiredMessage;
      isValid = false;
    } else if (vPwdLength > 0 && verifyPassword !== password) {
      errors.verifyPassword = passwordsDontMatchMessage;
      isValid = false;
    }

    if (firstName?.length === 0) {
      errors.firstName = fieldRequiredMessage;
      isValid = false;
    } else if (firstName?.length > 0 && !stringsPattern.test(firstName)) {
      errors.firstName = invalidFirstName;
      isValid = false;
    }

    if (lastName?.length === 0) {
      errors.lastName = fieldRequiredMessage;
      isValid = false;
    } else if (lastName?.length > 0 && !stringsPattern.test(lastName)) {
      errors.lastName = invalidLastName;
      isValid = false;
    }

    if (phoneNumber?.length === 0) {
      errors.phoneNumber = fieldRequiredMessage;
      isValid = false;
    } else if (
      !phonePattern.test(phoneNumber) ||
      phoneNumber.length < 9 ||
      phoneNumber.length > 12
    ) {
      errors.phoneNumber = invalidPhoneNumberMessage;
      isValid = false;
    }

    if (address?.length === 0) {
      errors.address = fieldRequiredMessage;
      isValid = false;
    } else if (address?.length > 0 && !addressPattern.test(address)) {
      errors.address = invalidAddress;
      isValid = false;
    }
  }

  return { isValid, errors };
};

const validateOrder = (
  termsAndConditions: boolean,
  availableShipping: boolean,
  additionalShippingPhone: boolean,
  additionalBillingPhone: boolean,
  activePhrases: NewPhrases | undefined,
  shipping: { [field: string]: string },
  billing: { [field: string]: string }
): {
  isValid: boolean;
  errors: {
    shipping: InputErrors;
    billing: InputErrors;
    termsAndConditions: boolean;
  };
} => {
  let isValid = true;
  const errors: {
    billing: InputErrors;
    shipping: InputErrors;
    termsAndConditions: boolean;
  } = {
    billing: {},
    shipping: {},
    termsAndConditions: false,
  };

  const fieldRequiredMessage = activePhrases ? activePhrases["field_required"] : "שדה נדרש";
  const invalidEmailMessage = activePhrases ? activePhrases["invalid_email"] : "אימייל שגוי";
  const invalidPhoneNumberMessage = activePhrases
    ? activePhrases["invalid_phone_number"]
    : "מספר טלפון לא תקין";

  if (availableShipping) {
    if (!shipping.email) {
      isValid = false;
      errors.shipping.email = fieldRequiredMessage;
    } else if (shipping.email?.length > 0 && !emailPattern.test(shipping.email)) {
      isValid = false;
      errors.shipping.email = invalidEmailMessage;
    }

    if (!shipping.fullName) {
      isValid = false;
      errors.shipping.fullName = fieldRequiredMessage;
    }

    if (!shipping.fullAddress) {
      isValid = false;
      errors.shipping.fullAddress = fieldRequiredMessage;
    }

    if (!shipping.phone) {
      isValid = false;
      errors.shipping.phone = fieldRequiredMessage;
    } else if (!phonePattern.test(shipping.phone)) {
      isValid = false;
      errors.shipping.phone = invalidPhoneNumberMessage;
    }

    if (additionalShippingPhone) {
      if (!shipping.additionalPhone) {
        isValid = false;
        errors.shipping.additionalPhone = fieldRequiredMessage;
      } else if (!phonePattern.test(shipping.additionalPhone)) {
        isValid = false;
        errors.shipping.phone = invalidPhoneNumberMessage;
      }
    }
  }

  if (!billing.email) {
    isValid = false;
    errors.billing.email = fieldRequiredMessage;
  } else if (billing.email?.length > 0 && !emailPattern.test(billing.email)) {
    isValid = false;
    errors.billing.email = invalidEmailMessage;
  }

  if (!billing.fullName) {
    isValid = false;
    errors.billing.fullName = fieldRequiredMessage;
  }

  if (!billing.fullAddress) {
    isValid = false;
    errors.billing.fullAddress = fieldRequiredMessage;
  }

  if (!billing.phone) {
    isValid = false;
    errors.billing.phone = fieldRequiredMessage;
  } else if (!phonePattern.test(billing.phone)) {
    isValid = false;
    errors.billing.phone = invalidPhoneNumberMessage;
  }

  if (additionalBillingPhone) {
    if (!billing.additionalPhone) {
      isValid = false;
      errors.billing.additionalPhone = fieldRequiredMessage;
    } else if (!phonePattern.test(billing.additionalPhone)) {
      isValid = false;
      errors.billing.phone = invalidPhoneNumberMessage;
    }
  }

  if (!termsAndConditions) {
    isValid = false;
    errors.termsAndConditions = true;
  }

  return { isValid, errors };
};

export { validateAuth, validateOrder };
