import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";

import productsService from "../services/products";
import { Product, Review } from "../services/products/types";
import strapiService from "../services/strapi";
import { getDefaultResourceState, Resource } from "./resource-state";
import { ApplicationState } from "./root-reducer";
import { TThunk, useAppSelector } from "./store";

type State = {
  productsData: Resource<Product>;
  activeProduct: Product | null;
};

type UpdateProductProps = {
  catalogNumber: string;
  reviews: Review[];
};

const initialState: State = {
  productsData: getDefaultResourceState(),
  activeProduct: null,
};

const fetchProducts: TThunk<Product[]> = createAsyncThunk(
  "products/fetch",
  async () => await productsService.getProducts(),
  {
    condition: (_, { getState }) => {
      const state = getState();
      return !state.products.productsData.loaded;
    },
  }
);

const updateProduct: TThunk<Product[] | undefined, UpdateProductProps[]> = createAsyncThunk(
  "product/update",
  async productsArray => await strapiService.updateProduct(productsArray)
);

const setActiveProduct = createAction("products/active-product", (product: Product) => ({
  payload: product,
}));

const reducer = createReducer(initialState, {
  [fetchProducts.pending.type]: state => {
    state.productsData.loading = true;
  },
  [fetchProducts.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
    state.productsData.data = [...state.productsData.data, ...action.payload];
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },
  [fetchProducts.rejected.type]: (
    state,
    action: PayloadAction<null, string, unknown, SerializedError>
  ) => {
    state.productsData.error = action.error.message || "General Error";
    state.productsData.loading = false;
  },
  [setActiveProduct.type]: (state, action: PayloadAction<Product>) => {
    state.activeProduct = action.payload;
  },

  [updateProduct.pending.type]: state => {
    state.productsData.loading = true;
    state.productsData.loaded = false;
  },

  [updateProduct.fulfilled.type]: (state, action: PayloadAction<Product[]>) => {
    const products = action.payload;

    products.map(product => {
      const indexOfProductInStore = state.productsData.data.findIndex(p => p.id === product.id);
      state.productsData.data = [
        ...state.productsData.data.slice(0, indexOfProductInStore),
        product,
        ...state.productsData.data.slice(indexOfProductInStore + 1),
      ];
    });
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },

  [updateProduct.rejected.type]: (state, action) => {
    state.productsData.loading = false;
    state.productsData.loaded = true;
  },
});

// Selectors
const productsPageSelector = () =>
  createSelector(
    (state: ApplicationState) => state.products.productsData.data,
    (products: Product[]) => {
      const activeBrands = useAppSelector(state => state.UIfilters.activeBrands);
      const activeRating = useAppSelector(state => state.UIfilters.rating);

      if (activeBrands.length > 0) {
        products = products.filter((product: Product) => activeBrands.includes(product.brand?.id!));
      }

      if (activeRating !== 0) {
        products = products.filter((product: Product) => product.rating === activeRating);
      }

      return products;
    }
  );

const getActiveProductSelector = () =>
  createSelector(
    (state: ApplicationState) => state.products.activeProduct,
    (product: Product | null) => {
      const activeProductId = useAppSelector(state => state.router.location.pathname)
        .split("/")
        .pop();

      product = useAppSelector(state =>
        state.products.productsData.data.find(product => product.catalogNumber === activeProductId)
      )!;

      return product;
    }
  );

const selectors = {
  productsPageSelector,
  getActiveProductSelector,
};

const actions = {
  fetchProducts,
  setActiveProduct,
  updateProduct,
};

const productsStore = {
  actions,
  reducer,
  selectors,
  initialState,
};

export default productsStore;
export type { State };
