import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { Product } from "../../services/products/types";
import { Favorites, LoggedUser } from "../../services/users/types";
import languagesStore from "../../store/languages-store";
import { useAppSelector } from "../../store/store";
import { getPhrase } from "../../utils/language";
import ProductItemGrid from "../products-view/product-item-grid";
import Button from "./button";

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: "0 35px 40px 35px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      [theme.device.mobile()]: {
        padding: "0 15px 30px 15px",
      },
    },
    title: {
      width: "100%",
      textAlign: "end",
      cursor: "text",
      padding: "20px 0",
      color: theme.colors.blue,
      fontWeight: 700,
      fontSize: 21,
      [theme.device.mobile()]: {
        fontSize: 35,
      },
    },
    container: {
      padding: 0,
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridGap: 70,
      [theme.device.mobile()]: {
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: 20,
      },
    },
    showMore: {
      marginTop: 40,
      "&:hover": {
        transition: "0.2s",
      },
      [theme.device.mobile()]: {
        "&:hover": {
          color: theme.colors.text,
          backgroundColor: theme.colors.blue,
        },
      },
    },
  }),
  { name: "items-list" }
);

type TProps = {
  title: string | undefined;
  products: Product[];
  isImporterDeals: boolean;
  user: LoggedUser;
  toggleFavorite: (p: Favorites) => void;
  pageSize?: number
};

const ItemsList = ({
  title,
  products,
  isImporterDeals,
  user,
  toggleFavorite,
  pageSize = 13
}: TProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const lang = useAppSelector(state => state.languages.language);

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const PAGE_SIZE = pageSize;

  const [pageNumber, setPageNumber] = useState<number>(PAGE_SIZE);

  const filteredProducts = products?.filter((_, i) => i < pageNumber);

  const items = filteredProducts?.map(p => {
    const isFavorite = !!user?.favoriteCatalogItems?.find(product => product.productId === p.id);
    return (
      <ProductItemGrid
        key={p.id}
        product={p}
        isFavorite={isFavorite}
        isImporterDeals={isImporterDeals}
        toggleFavorite={toggleFavorite}
        user={user}
      />
    );
  });

  const onShowMoreClick = () => setPageNumber(current => current + PAGE_SIZE);
  console.log(pageNumber, products?.length, PAGE_SIZE)
  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <ul className={classes.container} dir="rtl">
        {items}
      </ul>
      {products?.length > pageNumber && (
        <Button
          borderRadius={25}
          width={120}
          height={30}
          className={classes.showMore}
          text={activePhrases && activePhrases["show_more"]}
          textColor={theme.colors.text}
          textColorHover={theme.colors.orange}
          outline={theme.colors.orange}
          fill={theme.colors.blue}
          fillHover={theme.colors.text}
          action={onShowMoreClick}
        />
      )}
    </div>
  );
};

export default ItemsList;
