import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import ContactPanel from "./contact-panel";

import sendMessageStore from "../../../store/send-message-store";
import { ApplicationState } from "../../../store/root-reducer";
import { useAppSelector } from "../../../store/store";
import useViewport from "../../../hooks/useViewport";
import { getPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";

interface FormValues {
  topic: string;
  message: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const Contact = (): JSX.Element => {
  const [showSentMessageText, setShowSentMessageText] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

  const { lang, sendMessage } = useAppSelector(
    ({ languages: { language }, sendMessage }: ApplicationState) => ({
      lang: language,
      sendMessage,
    })
  );

  const { width } = useViewport();

  const dispatch = useDispatch();

  const classes = useStyles();

  const postSubmitMessageRef = useRef(null);
  const reRef = useRef<ReCAPTCHA>(null);

  const initialValues: FormValues = {
    topic: "",
    message: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const errorMsg = activePhrases && activePhrases["required_field"];

  const validationSchema: any = Yup.object().shape({
    topic: Yup.string().required(errorMsg),
    message: Yup.string().required(errorMsg),
    firstName: Yup.string().required(errorMsg),
    lastName: Yup.string().required(errorMsg),
    email: Yup.string()
      .email(activePhrases && activePhrases["invalid_email"])
      .required(errorMsg),
    phone: Yup.string().required(errorMsg),
  });

  const onSubmit = (values: FormValues) => {
    dispatch(sendMessageStore.actions.sendMessage(values));
    setShowPopup(true);
    reRef.current?.reset();
    setIsTokenValid(false);
    formik.resetForm();
  };

  const formik = useFormik<FormValues>({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    typeof sendMessage.data === "string" &&
      sendMessage.data.length > 0 &&
      setShowSentMessageText(true);
  }, [sendMessage.data]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (showSentMessageText)
      timeout = setTimeout(() => {
        setShowSentMessageText(false);
        dispatch(sendMessageStore.actions.clearMessageStatus());
      }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showSentMessageText, dispatch]);

  const reCaptchaLanguage = lang === "he" ? "iw" : "ar";
  const popupMessage =
    (activePhrases && activePhrases["contact_us_success_message"]) || "נשלח בהצלחה";

  return (
    <div className={classes.wrapper}>
      <ContactPanel />

      <div className={classes.content}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className={classes.formUp}>
            <div className={classes.formUpLeft}>
              <div className={classes.row}>
                <label htmlFor="topic">
                  {activePhrases && activePhrases["topic"]}
                </label>
                <input
                  id="topic"
                  className={clsx(
                    classes.input,
                    formik.errors.topic && formik.touched.topic && classes.invalidField
                  )}
                  name="topic"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.topic}
                />
                {formik.errors.topic && formik.touched.topic && (
                  <span className="error">{formik.errors.topic}</span>
                )}
              </div>

              <div className={classes.row}>
                <label htmlFor="message">{activePhrases && activePhrases["message"]}</label>
                <textarea
                  id="message"
                  className={clsx(
                    formik.errors.message && formik.touched.message && classes.invalidField
                  )}
                  name="message"
                  rows={width! > 1200 ? 18 : 10}
                  cols={width! > 600 ? 72 : 10}
                  maxLength={5000}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
                {formik.errors.message && formik.touched.message && (
                  <span className="error">{formik.errors.message}</span>
                )}
              </div>
            </div>

            <div className={classes.formUpRight}>
              <div className={classes.row}>
                <label htmlFor="firstName">{activePhrases && activePhrases["first_name"]}</label>
                <input
                  id="firstName"
                  className={clsx(
                    classes.input,
                    formik.errors.firstName && formik.touched.firstName && classes.invalidField
                  )}
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <span className="error">{formik.errors.firstName}</span>
                )}
              </div>

              <div className={classes.row}>
                  <label htmlFor="lastName">
                    {activePhrases && activePhrases["last_name"]}
                  </label>
                <input
                  id="lastName"
                  className={clsx(
                    classes.input,
                    formik.errors.lastName && formik.touched.lastName && classes.invalidField
                  )}
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <span className="error">{formik.errors.lastName}</span>
                )}
              </div>

              <div className={classes.row} >
                <label htmlFor="email">{activePhrases && activePhrases["email"]}</label>
                <input
                
                  id="email"
                  className={clsx(
                    classes.input,
                    formik.errors.email && formik.touched.email && classes.invalidField
                  )}
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <span className="error">{formik.errors.email}</span>
                )}
              </div>

              <div className={classes.row}>
                <label htmlFor="phone">{activePhrases && activePhrases["phone"]}</label>
                <input
                  id="phone"
                  className={clsx(
                    classes.input,
                    formik.errors.phone && formik.touched.phone && classes.invalidField
                  )}
                  name="phone"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <span className="error">{formik.errors.phone}</span>
                )}
              </div>

              <div className={classes.row + " rc"}>
                <ReCAPTCHA
                  key={reCaptchaLanguage}
                  hl={reCaptchaLanguage}
                  ref={reRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                  onChange={token => token && token.length > 0 && setIsTokenValid(true)}
                />
              </div>
            </div>
          </div>

          {showSentMessageText && (
            <p className="post-submit-message" ref={postSubmitMessageRef}>
              {activePhrases && activePhrases["send_message_success"]}
            </p>
          )}
          {showPopup && (
            <div className={classes.successPopup}>
              <button className={classes.policyCloseBtn} onClick={() => setShowPopup(false)}>
                X
              </button>
              <p className={classes.successMessage}>{popupMessage}</p>
            </div>
          )}

          <div className={classes.formDown}>
            <button type="submit" disabled={!isTokenValid}>
              {sendMessage.loading ? (
                <Loader type="ThreeDots" color="#114649" height={20} width={40} />
              ) : (
                activePhrases && activePhrases["send_message"]
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      width: "100%",
    },

    content: {
      padding: "50px 10px",

      "&>form": {
        margin: "0 auto",
        maxWidth: 600,

        "&>p.post-submit-message": {
          color: theme.colors.blue,
          textAlign: "center",
          padding: 0,
          margin: 0,
          animation: "hidePostSubmitMsg 0s ease-in 4s forwards",
          animationFillMode: "forwards",
        },

        "@media (min-width: 1200px)": {
          maxWidth: 900,
        },
      },
    },

    "@keyframes hidePostSubmitMsg": {
      to: {
        width: 0,
        height: 0,
        overflow: "hidden",
      },
    },

    formUp: {
      "@media (min-width: 1200px)": {
        display: "flex",
      },
    },

    formUpLeft: {
      "@media (min-width: 1200px)": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },

    formUpRight: {
      "@media (min-width: 1200px)": {
        flex: 1,
      },
    },

    row: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 14,

      "&.rc": {
        alignItems: "flex-end",

        "@media (min-width: 1200px)": {
          alignItems: "flex-end",
          margin: "50px 0 0 60px",
        },
      },

      "&>label": {
        color: theme.colors.blue,
        fontWeight: "bold",
        marginBottom: 6,
        textAlign: "end",
        fontSize: 22,

        "@media (min-width: 1200px)": {
          fontSize: 24,
        },
      },

   

      "&>input": {
        // border: `1px solid ${theme.colors.green}`,
        fontFamily: "Arial, Helvetica, sans-serif",
        borderRadius: 6,
        // textAlign: "end",
        direction: "rtl",
        paddingRight: 10,
        maxWidth: 500,
        fontSize: 18,
        height: 58,

        [theme.device.desktop()]: {
          width: 404,
        },

        "@media (min-width: 1200px)": {
          borderRadius: 16,
          paddingLeft: 20,
          fontSize: 24,
          height: 57,
        },
      },

      "&>input:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0px 1000px #fff inset",
      },

      "&>textarea": {
        fontFamily: "Arial, Helvetica, sans-serif",
        resize: "vertical",
        direction: "rtl",
        // textAlign: "end",
        borderRadius: 6,
        minHeight: 40,
        fontSize: 24,
        padding: 10,

        [theme.device.desktop()]: {
          width: 404,
          height: 436,
        },

        "@media (min-width: 1200px)": {
          borderRadius: 16,
        },
      },

      "&>span.error": {
        color: theme.colors.red,
        textAlign: "end",
        fontSize: 16,

        // // [theme.device.desktop()]: {
        // //   fontSize: 20,
        // // },

        // "@media (min-width: 1200px)": {
        //   fontSize: 18,
        // },
      },

      [theme.device.desktop()]: {
        alignItems: "flex-end",
      },

      "@media (min-width: 1200px)": {
        alignItems: "flex-end",
      },
    },

    formDown: {
      display: "grid",
      placeItems: "center",
      marginTop: 40,

      "&>button": {
        backgroundColor: theme.colors.blue,
        padding: "6px 10px",
        textAlign: "center",
        fontWeight: "bold",
        borderRadius: 30,
        color: "#fff",
        fontSize: 20,
        width: 150,
        opacity: 1,

        "&:disabled": {
          opacity: 0.5,
        },

        "@media (min-width: 1200px)": {
          fontSize: 28,
          width: "fit-content",
          padding: "6px 20px",
        },
      },
    },

    input: {
      border: `1px solid ${theme.colors.black}`,
    },
    invalidField: {
      borderColor: "red",
    },
    successPopup: {
      color: "white",
      width: "30%",
      position: "absolute",
      left: "35%",
      bottom: "53%",
      border: "1px solid #114649",
      backgroundColor: theme.colors.blue,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: "16px",
      overflowX: "hidden",
      zIndex: 2,
      height: "150px",
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#114649",
        outline: `1px solid slategrey`,
      },
      [theme.device.mobile()]: {
        width: "80%",
        left: "10%",
        bottom: "45%",
      },
    },
    successMessage: {
      color: theme.colors.white,
      fontSize: 26,
      fontWeight: 700,
      width: "100%",
      padding: "15px 0px",
      textAlign: "center",
      boxSizing: "border-box",
      margin: 0,
      [theme.device.mobile()]: {
        border: "none",
      },
    },
    policyCloseBtn: {
      width: "12px",
      height: "12px",
      position: "absolute",
      top: 12,
      right: 12,
      borderRadius: "18px",
    },
  }),
  
  { name: "contact-page" }
);
