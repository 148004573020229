import React, { useEffect, useRef } from "react";
import { Category } from "../../services/categories/types";
import SubcategoryItem from "./subcategory-item";
import { scrollStyles } from "../../utils/style-utils";
import makeStyles from "@material-ui/styles/makeStyles";
type Props = {
    hoveredCategory: number | null
    setHoveredCategory: React.Dispatch<React.SetStateAction<number | null>>
    categories: Category[]
    isHeaderMenu?: boolean
}
function CategoryDropdown({ hoveredCategory, setHoveredCategory, categories, isHeaderMenu = false }: Props){
  const classes = useStyles({});
  const ref = useRef(null);
  
  const clipDropdownToScreen = () => {
    if(ref.current){
      const elem = ref.current as HTMLDivElement
      elem.style.marginRight = `${elem.offsetWidth / 2 + 40}px`
    }
  }
  
  useEffect(() => {
    if(isHeaderMenu){
      clipDropdownToScreen()
    }
  }, [hoveredCategory])

  const items = categories
    .filter(category => category.parent?.id === hoveredCategory)
    ?.map(child => {
      return (
        <SubcategoryItem
          key={child?.id}
          subcategory={child}
          setCurrentCategory={setHoveredCategory}
        />
      );
    })
    return (
        <div ref={ref} >
          {items.length ?
            <div className={classes.content}>
              <ul
                dir="rtl"
                className={classes.subCategories}
                style={{ gridTemplateColumns: `repeat(${items?.length}, auto)` }}
              >
                {items}
              </ul>
            </div>
          : null}
        </div>
    )
}
export default CategoryDropdown;
const useStyles = makeStyles(
  theme => ({
    content: {
      width: "100%",
      height: "fit-content",
      backgroundColor: theme.colors.text,
      borderRadius: 20,
      border: `1px solid ${theme.colors.darkText}`,
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      padding: "15px 30px",
    },
    subCategories: {
      listStyle: "none",
      overflowY: "auto",
      ...scrollStyles(theme, false),
      width: "100%",
      display: "grid",
      gridGap: "12px 15px",
      gridColumnGap: 30,
    },
  })
);