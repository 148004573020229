import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import Button from "./button";
import { ReactComponent as BookIcon } from "../../static/icons/book.svg";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getPhrase } from "../../utils/language";
import { useFormik } from "formik";
import * as Yup from "yup";
import usersStore from "../../store/users-store";
import languagesStore from "../../store/languages-store";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "40px",
      justifyContent: "center",
      marginTop: 20,
      width: '100vw',
      boxSizing: "border-box",
      backgroundColor: theme.colors.blue,
    },
    title: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr",
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      color: theme.colors.text,
      [theme.device.mobile()]: {
        alignItems: "center",
        width: "100%",
      },
    },
    mainText: {
      fontWeight: 600,
      fontSize: 22,
      textAlign: "right",
      [theme.device.mobile()]: {
        fontSize: 35,
      },
      ["@media (max-width: 400px)"]: {
        fontSize: 28,
      },
      ["@media (max-width: 300px)"]: {
        fontSize: 25,
      },
    },
    subText: {
      fontSize: 15,
      [theme.device.mobile()]: {
        fontSize: 25,
      },
      ["@media (max-width: 400px)"]: {
        fontSize: 20,
      },
      ["@media (max-width: 300px)"]: {
        fontSize: 18,
      },
    },
    icon: {
      marginLeft: 12,
      height: 53,
      width: 63,
      [theme.device.mobile()]: {
        gridRow: 1,
        margin: "0 auto",
        height: 83,
        width: 93,
      },
    },
    subscribe: {
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      [theme.device.mobile()]: {
        paddingRight: 0,
      },
    },
    input: {
      width: "100%",
      border: "none",
      outline: "none",
      textAlign: "right",
      borderRadius: 10,
      boxSizing: "border-box",
      padding: "8px 10px",
      fontSize: 17,
      fontWeight: 300,
      color: theme.colors.green,
      "&::placeholder": {
        color: theme.colors.green,
        textTransform: "lowercase",
      },
      [theme.device.mobile()]: {
        borderRadius: 15,
        padding: "12px 14px",
        fontSize: 30,
      },
      ["@media (max-width: 400px)"]: {
        fontSize: 20,
      },
      ["@media (max-width: 300px)"]: {
        fontSize: 18,
      },
    },
    buttons: {
      marginTop: 7,
      display: "flex",
      gridGap: "0 7px",
      width: "100%",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        marginTop: 15,
        flexDirection: "column",
        alignItems: "center",
        gridGap: "15px 0",
      },
    },

    error: {
      color: theme.colors.red,
      fontSize: 16,
    },

    button: {
      padding: "10px 0",
      textTransform: "lowercase",
      [theme.device.desktop()]: {
        border: `1px solid ${theme.colors.white}`,
        "&:hover": {
          backgroundColor: theme.colors.blue,
          border: `1px solid ${theme.colors.white}`,
          color: theme.colors.white,
        },
      },
      [theme.device.mobile()]: {
        height: 25,
      },
    },
    border: {
      border: `1px solid ${theme.colors.text}`,
    },

    errWrap: {
      width: "100%",
      textAlign: "end",
    },
  }),
  { name: "subscribe" }
);

type FormValues = {
  email: string;
};

const Subscribe = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [showFeedbackMessages, setShowFeedbackMessages] = useState(false);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const { successfulSubscribing, subEmailErrMsg, lang } = useAppSelector(state => ({
    successfulSubscribing: state.user.subscribed,
    subEmailErrMsg: state.user.errors.subscription,
    lang: state.languages.language,
  }));

  const errorMsg = activePhrases && activePhrases["required_field"];

  const initialValues: FormValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(activePhrases && activePhrases["invalid_email"])
      .required(errorMsg),
  });

  const onSubmit = () => {
    formik.resetForm();
  };

  const onSubcribeClick = () => {
    if (formik.errors.email || formik.values.email.length === 0) {
      setShowFeedbackMessages(false);
      return;
    }

    setShowFeedbackMessages(true);
    dispatch(usersStore.actions.addSubscriptionEmail({ email: formik.values.email, lang }));
  };

  const formik = useFormik<FormValues>({ initialValues, validationSchema, onSubmit });

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.textContainer}>
          <div className={classes.mainText}>
            {activePhrases && activePhrases["join_subscription"]}
          </div>
          <div className={classes.subText}>{activePhrases && activePhrases["join_newsletter"]}</div>
        </div>

        <BookIcon className={classes.icon} />
      </div>

      <form className={classes.subscribe} autoComplete="off" onSubmit={formik.handleSubmit}>
        <input
          name="email"
          value={formik.values.email}
          className={classes.input}
          placeholder={activePhrases && activePhrases["email"]}
          onChange={formik.handleChange}
        />

        {formik.errors.email && formik.touched.email && (
          <div style={{ width: "100%", textAlign: "end" }}>
            <span className={classes.error}>{formik.errors.email}</span>
          </div>
        )}

        {successfulSubscribing && showFeedbackMessages && (
          <div className={classes.errWrap}>
            <span style={{ color: "lightgreen", textAlign: "end" }}>
              {activePhrases && activePhrases["successful_subscription"]}
            </span>
          </div>
        )}

        {
          <div className={classes.errWrap}>
            <span className={classes.error}>
              {typeof subEmailErrMsg === "string" &&
                subEmailErrMsg?.length > 0 &&
                showFeedbackMessages &&
                subEmailErrMsg}
            </span>
          </div>
        }

        <div className={classes.buttons}>
          <Button
            height={20}
            width={130}
            borderRadius={10}
            textSize={21}
            textWeight={600}
            fillHover = {theme.colors.white}
            type="submit"
            text={activePhrases && activePhrases["submit"]}
            fill={theme.colors.white}
            textColor={theme.colors.blue}
            textColorHover = {theme.colors.blue}
            className={classes.button}
            action={onSubcribeClick}
          />
        </div>
      </form>
    </div>
  );
};

export default Subscribe;
