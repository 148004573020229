import { makeStyles, CreateCSSProperties } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Subscribe from "../common/subscribe";
import InfoLinks, { LinkData } from "./info/info-links";
import ContactUs from "./info/contact-us";
import Social from "./info/social";
import { useAppSelector } from "../../store/store";
import languagesStore from "../../store/languages-store";
import tranzillaSecurity from "../../static/images/tranzilla-security-grey.png";
import { config } from "../../config";
import clsx from "clsx";
import FooterCategoriesLinks from "./info/footer-categories-links";

const getFooterLinks = (): LinkData[] => {
  return [
    {
      name: "product_catalog",
      path: config.routes.catalogPage,
    },

    {
      name: "authorized_resellers",
      path: config.routes.approvedSellers,
    },

    {
      name: "my_account",
      path: config.routes.myAccount,
    },

    {
      name: "contact_us",
      path: config.routes.contact,
    },

    {
      name: "buy_policy",
      path: config.routes.buyPolicy,
    },

    {
      name: "customer_service",
      path: config.routes.customerService,
    },

    {
      name: "careers",
      path: config.routes.careers,
    },

    // { // TODO: Uncomment when starting implementation
    //   name: getPhrase("accumulative_cart", lang),
    //   path: "",
    // },
  ];
};

type TProps = {
  className?: String;
};

function debounce(fn: any, ms: any) {
  let timer: any;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply();
    }, ms);
  };
}

const Footer = ({ className }: TProps): JSX.Element => {
  const [logoWidth, setLogoWidth] = useState(window.innerWidth * 0.13);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setLogoWidth(window.innerWidth * 0.13);
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const classes = useStyles({ logoWidth });

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const links = getFooterLinks();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.subscribe}>
        <Social className={{ root: classes.activeMobile }} />
        <Subscribe />
      </div>
      <div className={classes.content}>
        <div className={classes.media}>
          <Social />
        </div>
        <div className={clsx(classes.info, "")}>
          <div className={classes.links}>
            <ContactUs />
            <FooterCategoriesLinks />
            <InfoLinks list={links} />
          </div>
          <div className={classes.otherLinks}>
          <div className={classes.otherLink}>
              <a
                className="terms-and-conditions"
                href={config.routes.accessibility}
                target="_blank"
                rel="noopener noreferrer"
              >
                {activePhrases && activePhrases["accessibility"]}
              </a>
            </div>
            <span className={classes.separator}>|</span>
            <div className={classes.otherLink}>
              <a
                className="terms-and-conditions"
                href={config.routes.termsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
              >
                {activePhrases && activePhrases["terms_of_use"]}
              </a>
            </div>
            <span className={classes.separator}>|</span>
            <div className={classes.otherLink}>
              <a
                className="terms-and-conditions"
                href={config.routes.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
              >
                {activePhrases && activePhrases["privacy_policy"]}
              </a>
            </div>
          </div>
        </div>
        <img className={classes.paymentLogo} src={tranzillaSecurity} alt="payment-security" />
      </div>
    </div>
  );
};

export default Footer;

type CSSProps = {
  logoWidth: number;
};

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      marginTop: "auto",
      width: "100%",
      [theme.device.mobile()]: {
        paddingTop: 0,
      },
    },

    content: {
      borderTop: `22px solid ${theme.colors.blue}`,
      paddingBottom: 30,
      paddingRight: 180,
      marginTop: 140,
      width: "100%",
      backgroundColor: theme.colors.gray,
      display: "flex",
      flexWrap: "wrap",
      gridTemplateColumns: "1fr 3fr",
      [theme.device.mobile()]: {
        paddingRight: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 0,
      },
    },
    media: ({ logoWidth }: CSSProps): CreateCSSProperties => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.device.mobile()]: {
        display: "none",
      },
    }),
    logo: ({ logoWidth }: CSSProps): CreateCSSProperties => ({
      userSelect: "none",
      width: 130,
      height: 100,
      marginTop: "-1px",
      cursor: "pointer",
    }),
    iconsContainer: {
      marginTop: 15,
      display: "flex",
    },
    icon: {
      marginRight: 15,
      height: 30,
      width: 30,
      cursor: "pointer",
    },
    info: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        alignItems: "center",
        marginTop: 10,
      },
    },
    infoMode: {
      marginTop: 0,
    },
    links: {
      paddingTop: 100,
      width: "100%",
      display: "grid",
      gridColumnGap: 20,
      gridTemplateColumns: "repeat(3, 1fr)",
      justifyContent: "space-around",
      [theme.device.mobile()]: {
        paddingTop: 0,
        gridTemplateColumns: "1fr",
      },
    },
    otherLinks: {
      userSelect: "none",
      alignSelf: "flex-end",
      paddingTop: 30,
      display: "flex",
      alignItems: "center",

      [theme.device.mobile()]: {
        alignSelf: "center",
      },
    },
    paymentLogo: {
      pointerEvents: "none",
      margin: "0 auto",
      [theme.device.mobile()]: {
        marginTop: "20px",
      },
    },
    otherLink: {
      color: theme.colors.blue,
      fontSize: 16,
      cursor: "pointer",

      "&>a": {
        "&.terms-and-conditions": {
          color: theme.colors.blue,
          textDecoration: "none",
        },
      },

      [theme.device.mobile()]: {
        fontWeight: 600,
        fontSize: 17,
      },
    },
    separator: {
      color: theme.colors.grayText,
      margin: "0 30px 0 45px",
      fontSize: 14,
      [theme.device.mobile()]: {
        fontSize: 17,
      },
    },
    subscribe: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gridRowGap: 20,
      [theme.device.mobile()]: {
        position: "relative",
        width: "100%",
        marginTop: "20px",
      },
    },
    active: {
      display: "flex",
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    activeMobile: {
      display: "none",
      // [theme.device.mobile()]: {
      //   display: "flex",
      // },
    },
  }),
  { name: "footer" }
);
