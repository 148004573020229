import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { push } from "connected-react-router";
import React, { useState, useEffect } from "react";
import { TCatalogItem } from "../../services/site-config-service/types";
import languagesStore from "../../store/languages-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { theme, TTypedStyles } from "../../theme";
import { getPhrase } from "../../utils/language";
import Button from "../common/button";
import { isMobile } from "react-device-detect";

type StyleProps = {
  url?: string;
  big?: boolean;
  isHover: boolean;
};

type TProps = {
  big?: boolean;
  catalogItem: TCatalogItem | null;
  className?: {
    root?: string;
    overlay?: string;
    content?: string;
  };
};

const CatalogItem = ({ catalogItem, className, big }: TProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isBig, setIsBig] = useState<boolean>(true)
  const lang = useAppSelector(state => state.languages.language);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const width = window.innerWidth;

  const classes = useStyles({ url: catalogItem?.backgroundImg!, big, isHover });

  const onHoverStart = () => setIsHover(true);
  const onHoverEnd = () => setIsHover(false);

  const goToProduct = () => {
    dispatch(push(catalogItem?.path!, true));
  };

  useEffect(() => {
    if(width <= 600) {
      setIsBig(false)
    }
  }, [])

  return (
    <div
      className={clsx(classes.root, className?.root)}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      onClick={goToProduct}
    >
      <video 
        className={clsx(classes.overlay, isHover && classes.overlayActive, className?.overlay)}
        autoPlay loop muted 
        src=""
      />

      <div className={clsx(classes.content, className?.content)}>
        <div className={clsx(classes.title, isBig && classes.titleBig)} onClick={goToProduct}>
          {catalogItem?.name}
        </div>

        <Button
          height={big ? 55 : 40}
          width={big ? 220 : 180}
          outline={isHover ? theme.colors.blue : theme.colors.text}
          fill={isHover ? theme.colors.text : theme.colors.overlayDark}
          textColor={isHover ? theme.colors.blue : theme.colors.text}
          textSize={big ? 24 : 18}
          text={activePhrases && activePhrases["additional_info"]}
          action={goToProduct}
          className={classes.button}
          borderWidth={big ? 4 : 3}
          borderRadius={big ? 40 : 25}
        />
      </div>
    </div>
  );
};

export default CatalogItem;

const useStyles: TTypedStyles<StyleProps> = makeStyles(
  theme => ({
    root: ({ url }: StyleProps): CreateCSSProperties => ({
      backgroundImage: `url(${url})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      cursor: "pointer",
      [theme.device.mobile()]: {
        backgroundSize: "cover",
      },
    }),
    overlay: {
      zIndex: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      backgroundColor: "rgba(255, 164, 0, 0.3)",
      objectFit: "cover",
      opacity: 0,
      transition: "1s",
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    overlayActive: {
      brightness: "0.8",
      opacity: 1,
    },
    content: {
      zIndex: 1,
      marginBottom: 30,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      userSelect: "none",
      color: theme.colors.text,
      fontWeight: "bold",
      fontSize: 24,
      marginBottom: 15,
      cursor: "pointer",
      filter: "drop-shadow(2px 4px 6px black)",

      [theme.device.mobile()]: {
        // fontSize: 28,
      },
    },
    titleBig: {
      fontSize: 24,
    },
    button: {
      transition: "0.5s",
      [theme.device.mobile()]: {
        borderColor: theme.colors.text,
        backgroundColor: theme.colors.blue,
        height: 30,
        width: 120,
        fontSize: 14,
        "&:hover": {
          color: theme.colors.text,
        },
      },
    },
  }),
  { name: "catalog-item" }
);
