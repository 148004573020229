import React, {useEffect } from "react";
import { useAppSelector} from "../../store/store";

import { useStyles } from '../pages/home-page';
export const UnderCarousel = () => {
  const classes = useStyles();
  const UnderCrouselElements = useAppSelector(state => state.siteConfig.UnderCrouselElements);
  let youtubeUrl = '';
  let imageUrl = '';

  let isMobile = window.innerWidth < 730;

  useEffect(() => {
    isMobile = window.innerWidth < 730;
  }, [window.innerWidth]);

  if (UnderCrouselElements.length) {
    for (let index = 0; index < UnderCrouselElements.length; index++) {
      if (UnderCrouselElements[index].title == 'youtube') {
        youtubeUrl = UnderCrouselElements[index]?.link;
      }else if(UnderCrouselElements[index].title == 'image'){
        imageUrl = UnderCrouselElements[index]?.image?.url
      }
    }
  }
  const buildYoutubeUrl = (url: string): string => {
    if (!url) {
      return "";
    }
    const URL_TEMPLATE = "https://www.youtube.com/embed/";
  
    if (!new URL(url).searchParams.get("v")) {
      return url;
    }
    return URL_TEMPLATE + new URL(url).searchParams.get("v");
  };

  return (
    <div className={classes.underCarouselContatiner}>
    <iframe className={classes.underCarouselIframe} src={buildYoutubeUrl(youtubeUrl)} />
    <img className={classes.underCarouselImg} src={imageUrl} alt="image" />
    </div>
  )
}