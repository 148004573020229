import { CreateCSSProperties, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { TTypedStyles } from "../../theme";

type StyleProps = {
  height?: number | string;
  width?: number | string;
  outline?: string;
  outlineHover?: string;
  fill?: string;
  fillHover?: string;
  textColor?: string;
  textColorHover?: string;
  textSize?: number;
  textWeight?: number;
  borderWidth?: number;
  borderRadius?: number;
};

const useStyles: TTypedStyles<StyleProps> = makeStyles(
  theme => ({
    root: ({
      height,
      width,
      outline,
      outlineHover,
      fill,
      fillHover,
      textSize,
      textWeight,
      textColor,
      textColorHover,
      borderRadius,
      borderWidth,
    }: StyleProps): CreateCSSProperties => ({
      userSelect: "none",
      width: width,
      height: height,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: fill,
      border: `${borderWidth || 2}px solid ${outline}`,
      borderRadius: borderRadius,
      color: textColor,
      fontSize: textSize || 17,
      fontWeight: textWeight || 700,
      transition: "0.3s",
      "&:hover": {
        color: textColorHover,
        backgroundColor: fillHover,
        border: `${borderWidth || 2}px solid ${outlineHover}`,
      },
    }),
  }),
  { name: "button" }
);

type TButton = "button" | "reset" | "submit";

type TProps = {
  disabled?: boolean;
  type?: TButton;
  text?: string;
  height?: number | string;
  width?: number | string;
  outline?: string;
  outlineHover?: string;
  fill?: string;
  fillHover?: string;
  textColor?: string;
  textColorHover?: string;
  textSize?: number;
  textWeight?: number;
  borderWidth?: number;
  borderRadius?: number;
  className?: string;
  icon?: JSX.Element;
  img?: string
  action?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = ({
  disabled,
  type,
  text,
  height,
  width,
  outline,
  outlineHover,
  fill,
  fillHover,
  textColor,
  textColorHover,
  textSize,
  textWeight,
  borderRadius,
  borderWidth,
  className,
  icon,
  img,
  action,
}: TProps): JSX.Element => {
  const classes = useStyles({
    height,
    width,
    outline,
    outlineHover,
    fill,
    fillHover,
    textColor,
    textColorHover,
    textSize,
    textWeight,
    borderRadius,
    borderWidth,
  });
  return (
    <button
      className={clsx(classes.root, className)}
      type={type}
      onClick={action}
      disabled={disabled}
    >
      {text}
      {img ? <img style={{width: 30, height: 32, marginLeft: 5}} src={img}/> : null}
      {icon}
    </button>
  );
};

export default Button;

export type { TButton };
