import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { getPhrase } from "../../utils/language";
import { useAppSelector, useAppDispatch } from "../../store/store";
import clsx from "clsx";
import LeftArrow from "../../static/icons/Path159.svg";
import RightArrow from "../../static/icons/Path160.svg";
import { useStyles } from "../pages/home-page";
import joinJoie from "../../static/images/join_joie_img.png";
import languagesStore from "../../store/languages-store";
import { GearData } from "../../services/site-config-service/types";
import { useHistory } from "react-router";

export const MeetTheGear = () => {
  const classes = useStyles();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const MeetTheGearTitle = useAppSelector(state => state.siteConfig.MeetTheGearTitle);
  const MeetTheGearDescription = useAppSelector(state => state.siteConfig.MeetTheGearDescription);
  const MeetTheGearComponent = useAppSelector(state => state.siteConfig.MeetTheGearComponent);
  const [currIndex, setCurrIndex] = useState(1);
  const history = useHistory();
  let isMobile = window.innerWidth < 730;

  useEffect(() => {
    isMobile = window.innerWidth < 730;
  }, [window.innerWidth]);

  const handleLeftArrowClick = (e: any) => {
    if (currIndex > 0) {
      setCurrIndex(oldState => oldState - 1)
    }
    if (currIndex === 0) {
      setCurrIndex(MeetTheGearComponent.length - 1)
    }
  }

  const handleRightArrowClick = (e: any) => {
    if (currIndex < MeetTheGearComponent.length - 1) {
      setCurrIndex(oldState => oldState + 1);
    }
    if (currIndex === MeetTheGearComponent.length - 1) {
      setCurrIndex(0)
    }
  }

  const handleImageClick = (data: any) => {
    console.log("link", data);
    // data && (window.location.pathname = data);
    data && (history.push(data));
  }


  return (
    <div className={classes.meetTheGear}>
      <div className={clsx(classes.title, classes.meetTheGearTitle)}>{MeetTheGearTitle}</div>
      <div className={clsx(classes.text, classes.meetTheGearText)}>{MeetTheGearDescription}</div>
      <div className={classes.meetTheGearContainer}>
        {MeetTheGearComponent.map(el => {
          return (
            <div key={el.id} onClick={() => handleImageClick(el.link)}>
              <img className={clsx(classes.meetTheGearItemImgUnder, classes.meetTheGearCategoriesItemImg)} src={el?.image?.url} alt={el.title} />
              <div className={clsx(classes.meetTheGearItemTitle)}>{el.title}</div>
              <div className={clsx(classes.meetTheGearItemPara)}>{el.paragraph}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
};
