import React, { useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./explore_joie.css";
import Slider from "react-slick";

import { makeStyles } from "@material-ui/styles";
//import sayHelloImg from "../../../static/images/say-hello-to-joie.png";
//import sayHelloMobileImg from "../../../static/images/say-hello-to-joie-mobile.png";

//import commitedBackgroundImg from "../../../static/images/commited.png";
//import commitedBackgroundMobileImg from "../../../static/images/commited-mobile.png";

import trdSection from "../../../static/images/3rd-section.png";

import joyfulImg from "../../../static/images/Group_1_copy.png";
import trustworthyImg from "../../../static/images/Group_1_copy2.png";
import devotedImg from "../../../static/images/Group_1_copy3.png";
import stylishImg from "../../../static/images/Group_1_copy4.png";
import responsibleImg from "../../../static/images/Group_1_copy5.png";
import joyfullTitle from "../../../static/images/joyful.png";
import trustWorthyTitle from "../../../static/images/trustworthy.png";
import devotedTitle from "../../../static/images/devoted.png";
import stylishTitle from "../../../static/images/stylish.png";
import responsibleTitle from "../../../static/images/responsible.png";
//import buildThemBetter from "../../../static/images/build_them_better.png";
import wheelImg from "../../../static/images/wheel.png";
import highestQualityImg from "../../../static/images/highest_quality.png";
//import buildThemImg from "../../../static/images/WE_BUILD_THEM.png";
import betterImg from "../../../static/images/better.png";
import weAreImg from "../../../static/images/we_are.png";
import commitedTextImg from "../../../static/images/commitedText.png";
import sharingTextImg from "../../../static/images/sharing.png";
import lightBulb from "../../../static/images/lightbulb.png";
import exploreRight from "../../../static/images/exploreRight.png";
import exploreLeft from "../../../static/images/exploreLeft.png";
import brilliantTitle from "../../../static/images/brilliantTitle.png";
import babyImg from "../../../static/images/babyImg.png";
import babyImgMobile from "../../../static/images/bSectionBaby-mobile.png";
import rectangle from "../../../static/images/orangeRect.png";
import Item from "../../sayHello-item/sayHelloItem";
import clsx from "clsx";
import { useHistory } from "react-router";

import sayHelloImg from "../../../static/images/SHTJ.jpg";
import sayHelloMobileImg from "../../../static/images/SHTJ1mobile.jpg";
import commitedBackgroundImg from "../../../static/images/SHTJ1.jpg";
import commitedBackgroundMobileImg from "../../../static/images/SHTJ1.jpg";

import buildThemBetter from "../../../static/images/SHTJ2.jpg";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAppSelector } from "../../../store/store";

type Item = {
  title: string;
  img: string;
  text: string;
};

const firstRowItemsData: Item[] = [
  {
    title: "שמח ועליז",
    img: joyfulImg,
    text: "אנחנו יודעים שהמון משפחות חוות רגעים חדשים ומיוחדים בכל יום ואנחנו גאים להיות חלק מהמסע המופלא.",
  },
  {
    title: "אמין ובטוח",
    img: trustworthyImg,
    text: "אנחנו הולכים הרבה מעבר לסטנדרטי בנוגע לחומרים שהם אנחנו משתמשים ובבחינות הבטיחות שכל מוצר עובר.",
  },
  {
    title: "מסור וקשוב",
    img: devotedImg,
    text: "בכל מה שאנחנו עושים יש כוונה, מטרה ותשוקה מתוך מחשבה כיצד זה משפיע על משפחות צעירות מסביב לעולם.",
  },
];

const secondRowItemsData: Item[] = [
  {
    title: "עיצוב וסטייל",
    img: stylishImg,
    text: "שנים של מחקר (וילדים) לימדו אותנו מה הורים רוצים ולכן המוצרים שלנו עונים על מגוון הצרכים המשפחתיים",
  },
  {
    title: "אחראי ודואג",
    img: responsibleImg,
    text: "אנחנו עושים מאמץ גדול כדי לצמצם את ההשפעה הסביבתית שלנו ומעניקים בחזרה בתרומות ליוזמות קיימות.",
  },
];

const mobileThirdSectionSlider = firstRowItemsData.concat(secondRowItemsData);

const ExploreGraco= (): JSX.Element => {
  const matches = useMediaQuery("(min-width: 460px)");
  const width460 = useMediaQuery("(min-width: 460px)");
  const initMobileSlider = useMediaQuery("(max-width: 1024px)");
  const classes = useStyle();
  const [current, setCurrent] = useState<number>(0);

  const { exploreCarousel, exploreCarouselMobile } = useAppSelector(state => state.exploreGraco);

  // const history = useHistory();

  // const handleImageClick = (data: any) => {
  // 	data && (history.push(data));
  // }

  console.log(exploreCarouselMobile);

  const setUrl = (url: string) => {
    let http = "http://";
    if (url.substring(0, 7) == "http://") {
      return url;
    }
    if (url.substring(0, 8) == "https://") {
      return url;
    }
    return http + url;
  };

  const firstRowItems: JSX.Element[] = firstRowItemsData.map(item => (
    <Item title={item.title} img={item.img} text={item.text} />
  ));

  const secondRowItems: JSX.Element[] = secondRowItemsData.map(item => (
    <Item title={item.title} img={item.img} text={item.text} />
  ));

  const mobileConcatRows: JSX.Element[] = mobileThirdSectionSlider.map((item, index) => (
    <Item key={index} title={item.title} img={item.img} text={item.text} />
  ));

  // let backgroundImageUrl = `url(${commitedBackgroundImg})`;
  // if (!matches) {
  // 	backgroundImageUrl = `url(${commitedBackgroundMobileImg})`
  // }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 2, marginRight: 80 }}
        onClick={onClick}
      >
        {/* <img src={exploreRight} /> */}
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 2, marginLeft: 80 }}
        onClick={onClick}
      >
        {/* <img src={exploreLeft} /> */}
      </div>
    );
  }

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className={classes.root}>
      <iframe
        src="https://d2aui8isulw3we.cloudfront.net/il/"
        width="150%"
        height="100%"
        style={{ position: "absolute", left: "-25%" }}
        frameBorder="0"
      />

      {/* <section className={classes.sayHelloSection}>
				{matches ?
					<img className={classes.sayHelloImg} src={sayHelloImg} alt="sayHello" /> :
					<img className={classes.sayHelloImg} src={sayHelloMobileImg} alt="sayHello" />
				}

			</section>
			<section
				className={classes.commitedSection}
			>
				<img className={classes.commitedBackgroundImg} src={commitedBackgroundImg} alt="commited" />
			</section>
			<section className={classes.thirdSection}>
				<img className={classes.trdSectionImg} src={trdSection} alt="thirdSection" />
				{!initMobileSlider && <div className={classes.rowsWrapper}>
					<div className={classes.firstRowWrapper}>
						{firstRowItems}
					</div>
					<div className={classes.secondRowWrapper}>
						{secondRowItems}
					</div>
				</div>}

				{initMobileSlider && <div className={classes.sliderContainer}>
					<Slider {...settings}>
						{mobileConcatRows}
					</Slider>
				</div>}
			</section>
			<section className={classes.buildBetterSection}>
				<img className={classes.buildBetterBackgroundImg} src={buildThemBetter} alt="build-them-better" />
			</section>
			<section className={classes.brilliantSection}>
				<Slider {...settings2}>
					{matches ?
						exploreCarousel.map(img => (
							<div className={img?.caption === null ? classes.imageContainer : classes.imageContainerCursor}
								onClick={() => {
									if (img?.caption !== "" && img?.caption) {
										window.open(setUrl(img?.caption), "_blank")
									}
								}} >
								<img className={classes.carouselImg} src={img.url} alt='image' />
							</div>
						)) :
						exploreCarouselMobile.map(img => (
							<div className={img?.caption === null ? classes.imageContainer : classes.imageContainerCursor}
								onClick={() => {
									if (img?.caption !== "" && img?.caption) {
										window.open(setUrl(img?.caption), "_blank")
									}
								}} >
								<img className={classes.carouselImg} src={img.url} alt='image' />
							</div>
						))
					}

				</Slider>
			</section> */}
    </div>
  );
};

const useStyle = makeStyles(theme => ({
  root: {
    height: "100vh",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },

  sayHelloSection: {
    width: "100%",
  },

  sayHelloImg: {
    width: "100%",
    height: "100%",
  },

  commitedSection: {
    display: "flex",
    alignItems: "center",
    minHeight: 700,
    width: "100%",
    position: "relative",
    justifyContent: "center",
    ["@media (max-width: 1280px)"]: {
      minHeight: "unset",
      height: "auto",
      //padding: "40px"
    },
  },

  commitedBackgroundImg: {
    width: "100%",
    height: "100%",
    ["@media (max-width: 1280px)"]: {
      objectFit: "cover",
      width: "150%",
      marginRight: "20px",
    },
  },

  commitedText: {
    paddingTop: 40,
    paddingBottom: 40,
    width: "70%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },

    ["@media (max-width: 560px)"]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },

  thirdSection: {
    display: "flex",
    alignItems: "center",
    minHeight: 800,
    width: "100%",
    position: "relative",
    justifyContent: "center",
    ["@media (max-width: 1024px)"]: {
      display: "block",
      minHeight: "unset",
    },
  },

  trdSectionImg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1,
  },

  rowsWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 1000,
    width: "100%",
  },

  firstRowWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gridColumnGap: 80,
    marginBottom: 40,
  },

  secondRowWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gridColumnGap: 100,
  },

  buildBetterSection: {
    display: "flex",
    alignItems: "center",
    minHeight: 800,
    width: "100%",
    position: "relative",
    justifyContent: "center",
    ["@media (max-width: 1280px)"]: {
      minHeight: "unset",
      height: "auto",
      //padding: "40px"
    },
  },

  buildBetterBackgroundImg: {
    width: "100%",
    height: "100%",
    ["@media (max-width: 1280px)"]: {
      objectFit: "cover",
      width: "150%",
      marginRight: "20px",
    },
    //position: "absolute",
    //zIndex: -1
  },

  buildBetterWrapper: {
    maxHeight: 600,
    display: "flex",
    ["@media (max-width: 560px)"]: {
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
    },
  },

  wheelImageWrapper: {
    display: "flex",
    alignItems: "center",
    "& img": {
      ["@media (max-width: 1280px)"]: {
        width: "70%",
      },
      ["@media (max-width: 860px)"]: {
        width: "100%",
      },
      ["@media (max-width: 560px)"]: {
        width: "160px",
        marginBottom: "20px",
      },
      ["@media (max-width: 420px)"]: {
        width: "120px",
      },
    },
  },

  buildBetterTextWrapper: {
    paddingLeft: 20,
  },

  brilliantSection: {
    // display: "flex",
    // alignItems: "center",
    // margin: 'auto',

    marginBottom: "10px",
    position: "relative",
    gridRowGap: 20,
    ["@media (max-width: 1280px)"]: {
      flexDirection: "column",
    },
    ["@media (max-width: 460px)"]: {
      padding: "0",
    },
  },

  carouselImg: {
    width: "100%",
    height: "auto",
    //objectFit: 'cover',
    ["@media (max-width: 460px)"]: {
      height: "640px",
      "& img": {
        width: "100%",
      },
    },
  },

  starsImgActive: {
    width: "100%",
    height: "100%",
    zIndex: -1,
    opacity: 1,
  },

  starsImg: {
    width: "100%",
    height: "100%",
    transition: "0.7s",
    opacity: 0,
    ["@media (max-width: 460px)"]: {
      position: "absolute",
    },
  },

  babyImgActive: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -2,
    opacity: 1,
    top: 0,
    ["@media (max-width: 460px)"]: {
      position: "static",
    },
  },

  babyImg: {
    width: "100%",
    height: "100%",
    position: "absolute",
    transition: "0.7s",
    opacity: 0,
  },

  arrowsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    position: "absolute",
    top: "50%",
    ["@media (max-width: 1280px)"]: {
      height: "100%",
      top: 0,
    },
  },

  lightBulb: {
    position: "absolute",
    top: "20px",
    ["@media (max-width: 740px)"]: {
      height: "70px",
      "& img": {
        height: "100%",
      },
    },
    ["@media (max-width: 460px)"]: {
      height: "50px",
    },

    ["@media (max-width: 420px)"]: {
      display: "none",
    },
  },

  // swipeLeft: {
  // 	position: "absolute",
  // 	left: "50px",
  // 	"& img": {
  // 		width: "90px",
  // 		["@media (max-width: 920px)"]: {
  // 			width: "50px"
  // 		},
  // 		["@media (max-width: 460px)"]: {
  // 			width: "30px"
  // 		},
  // 	}
  // },
  // swipeRight: {
  // 	position: "absolute",
  // 	right: "50px",
  // 	"& img": {
  // 		width: "90px",
  // 		["@media (max-width: 920px)"]: {
  // 			width: "50px"
  // 		},

  // 		["@media (max-width: 460px)"]: {
  // 			width: "30px"
  // 		},
  // 	},

  // },

  brilliantTitle: {
    paddingTop: 20,
    paddingBottom: 20,
    justifyContent: "center",
    margin: "0 auto",
    ["@media (max-width: 920px)"]: {
      textAlign: "center",
      "& img": {
        maxWidth: "70%",
      },
    },
    ["@media (max-width: 740px)"]: {
      "& img": {
        maxWidth: "50%",
      },
    },

    ["@media (max-width: 560px)"]: {
      "& img": {
        maxWidth: "30%",
      },
    },
  },

  brilliantText: {
    fontWeight: 600,
    width: 820,
    textAlign: "center",
    ["@media (max-width: 1280px)"]: {
      bottom: "70px",
      position: "absolute",
    },

    ["@media (max-width: 920px)"]: {
      maxWidth: "60%",
    },
    ["@media (max-width: 740px)"]: {
      bottom: "30px",
    },
    ["@media (max-width: 560px)"]: {
      bottom: "10px",
      fontSize: "14px",
    },

    ["@media (max-width: 460px)"]: {
      fontSize: "12px",
    },

    ["@media (max-width: 420px)"]: {
      display: "none",
    },
  },

  rectangleWrapper: {
    display: "flex",
    width: "100%",
    position: "absolute",
    bottom: 0,
    ["@media (max-width: 460px)"]: {
      height: "100px",
    },
  },

  rectangleImg: {
    width: "100%",
  },

  rectangleText: {
    color: theme.colors.white,
    position: "absolute",
    width: "100%",
    textAlign: "center",
    top: 15,
    fontSize: 45,
    margin: 0,
    textShadow: "-1px 2px black",
    ["@media (max-width: 920px)"]: {
      top: "5px",
      fontSize: "38px",
    },

    ["@media (max-width: 760px)"]: {
      fontSize: "28px",
    },

    ["@media (max-width: 580px)"]: {
      fontSize: "23px",
    },
    ["@media (max-width: 460px)"]: {
      top: 0,
      height: "50px",
    },
  },

  contentWrapper: {
    position: "absolute",
    textAlign: "center",
    ["@media (max-width: 375px)"]: {
      display: "none",
    },
  },
  commitedTopImg: {},

  commitedBottomImg: {
    "& img": {
      ["@media (max-width: 560px)"]: {
        width: "70%",
      },
    },
  },

  buildImage: {
    ["@media (max-width: 1280px)"]: {
      width: "50%",
    },

    ["@media (max-width: 860px)"]: {
      width: "70%",
    },
    ["@media (max-width: 380px)"]: {
      width: "100%",
    },
  },
  betterImage: {
    ["@media (max-width: 1280px)"]: {
      width: "30%",
    },
    ["@media (max-width: 860px)"]: {
      width: "40%",
    },
    ["@media (max-width: 380px)"]: {
      width: "60%",
    },
  },
  qualityImage: {
    ["@media (max-width: 1280px)"]: {
      width: "70%",
    },

    ["@media (max-width: 860px)"]: {
      width: "100%",
    },
    ["@media (max-width: 360px)"]: {
      width: "300px",
    },
  },
  sliderContainer: {
    padding: "40px",
  },
  imageContainer: {
    width: "100%",
    "& img": {
      cursor: "default",
      width: "100%",
      // height: 350,
    },
  },
  imageContainerCursor: {
    width: "100%",
    "& img": {
      cursor: "pointer",
      width: "100%",
      // height: 350,
    },
  },
}));

export default ExploreGraco;
