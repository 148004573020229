import { RouterLocation } from "connected-react-router";
import { Category } from "../services/categories/types";
import { Picture, Product, TFeature, TQnA } from "../services/products/types";
import { LoggedUser } from "../services/users/types";
import parse from "html-react-parser";

const CMS_LINK = process.env.REACT_APP_STRAPI_CMS_URL;

const CLIENT_LINK = process.env.REACT_APP_CLIENT;

const buildClientUrl = (url: string) => CLIENT_LINK + url;

const buildMediaUrl = (url: string | any) => {
  return isAbsoluteUrl(url) ? url : CMS_LINK + url;
};

const setImageUrl = (dto: any) => {
  let image = dto.backgroundImg ?? dto.freeTextImg;
  if (image.formats == null) {
    return image.url;
  }
  // else if (image.formats && image.formats.medium) {return image.formats.medium.url}
  else if (image.formats && image.formats.small) {
    return image.formats.small.url;
  } else if (image.formats && image.formats.xsmall) {
    return image.formats.xsmall.url;
  }
  return image.url;
};

const isAbsoluteUrl = (url: string): boolean =>
  url?.startsWith("http://") || url?.startsWith("https://");

const buildYoutubeUrl = (url: string): string => {
  if (!url) {
    return "";
  }
  const URL_TEMPLATE = "https://www.youtube.com/embed/";
  const BUILD_URL_TEMPLATE: string = URL_TEMPLATE + new URL(url).searchParams.get("v");
  return BUILD_URL_TEMPLATE;
};

const buildProduct = (dto: Product) => {
  dto.pictures = buildPictureUrls(dto.pictures);
  const product: Product = {
    id: dto!?.id,
    published_at: dto.published_at,
    name: dto.name,
    title: dto.title,
    barCode: dto.barCode,
    brand: !!dto.brand
      ? {
          id: dto.brand!?.id,
          name: dto.brand.name,
          image: !!dto.brand.image
            ? {
                id: dto.brand.image!?.id!,
                url: dto.brand.image.url,
                caption: dto.brand.image.caption,
              }
            : null,
        }
      : null,
    discount: dto.discount!,
    PromotionText: dto.PromotionText!,
    discountDate: dto.discountDate!,
    startDiscountDate: dto.startDiscountDate!,
    video: dto.video,
    pictures: dto.pictures,
    mainPicture: dto.pictures[0],
    smallPictures: dto.pictures.slice(1),
    productLink: dto.productLink!,
    color: dto.color!,
    parentColor: dto.parentColor,
    isMainColor: dto.isMainColor,
    rating: Math.round(dto.rating),
    shippingDetails: dto.shippingDetails,
    deliveryTime: dto.deliveryTime!,
    catalogNumber: dto.catalogNumber,
    weight: dto.weight,
    importerDeal: dto.importerDeal,
    retailPrice: dto.retailPrice,
    salePrice: !!dto.salePrice ? dto.salePrice : dto.retailPrice,
    storePrice: dto.storePrice,
    warranty: !!dto.warranty
      ? {
          id: dto.warranty!?.id!,
          name: dto.warranty!?.name!,
          file: {
            id: dto.warranty.file!?.id!,
            url: buildMediaUrl(dto.warranty.file!?.url!),
            caption: dto.warranty.file!?.caption!,
          },
        }
      : null,
    reviews: dto.reviews,
    qna: dto.qna?.map((dto: TQnA) => ({
      id: dto!?.id,
      answer: dto.answer,
      question: dto.question,
    })),
    includedInTheKit: dto.includedInTheKit,
    features: dto.features?.map((feature: TFeature) => ({
      id: feature!?.id,
      description: feature.description,
      image: {
        id: feature.image!?.id!,
        url: buildMediaUrl(feature.image?.url!),
        caption: feature.image!?.caption!,
      },
    })),
    productLength: dto.productLength,
    productHeight: dto.productHeight,
    productWidth: dto.productWidth,
    relatedProducts: dto.relatedProducts
      ?.filter(p => p.product)
      ?.map(rP => ({
        product: buildRelatedProduct(rP.product),
      })),
    similarProducts: dto.similarProducts
      ?.filter(p => p.product)
      ?.map(sP => ({
        product: buildRelatedProduct(sP.product),
      })),
    quantity: dto.quantity!,
    awards: dto.awards?.map(award => ({
      id: award!?.id!,
      title: award.title!,
      description: award.description!,
      image: !!award.image
        ? {
            id: award.image!?.id!,
            url: buildMediaUrl(award.image.url!),
            caption: award.image!?.caption!,
          }
        : null,
    })),
    specifications: dto.specifications,
    description: dto.description,
    new: dto.new,
    hot: dto.hot,
    size: dto.size,
    age: !!dto.age
      ? {
          from: dto.age.from,
          to: dto.age.to,
        }
      : null,
    promotion: !!dto.promotion
      ? {
          name: dto.promotion.name!,
          requiredPurchase: dto.promotion.requiredPurchase!,
          givenBonus: dto.promotion.givenBonus!,
        }
      : null,
    subSubCategories: dto.subSubCategories?.map(sS => buildCategory(sS, dto.subSubCategories)),
    subCategories: dto.subCategories?.map(s => buildCategory(s, dto.subCategories)),
    categories: dto.categories?.map(c => buildCategory(c, dto.categories)),
    availabilityDate: dto.availabilityDate,
    isPublished: dto.isPublished,
    isofix: dto.isofix,
    productStandart: dto.productStandart!,
    foldTypes: dto.foldTypes,
    keywords: dto.keywords,
  };

  return product;
};

const checkDiscountStatus = (
  startDiscountDate: string,
  productDiscountDate: string,
  importedDeal: boolean
) => {
  let [currYear, currMonth, currDay] = new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "-")
    .split("-");
  const currDate = currYear + "-" + currMonth + "-" + currDay;

  // if (
  //   productDiscountYear == currYear &&
  //   productDiscountMonth == currMonth &&
  //   productDiscountDay == currDay
  // ) {

  //   return { status: "current" };
  // } else
  if (startDiscountDate <= currDate && currDate <= productDiscountDate && importedDeal) {
    return { status: "upcoming" };
  }
  return { status: "gone" };
};

const countProductDiscountPrice = (product: any) => {
  let discountPercentages = product?.discount ? product?.discount : 0;
  let discountAmmount = 0;
  let price = product?.retailPrice;
  let retail = true;

  let { status } = product?.discountDate
    ? checkDiscountStatus(product?.startDiscountDate, product?.discountDate, product?.importerDeal)
    : { status: "not" };

  if (status === "current") {
    price = price * (1 - discountPercentages / 100);
    discountAmmount = product?.retailPrice - price;
    retail = false;
  } else if (status === "upcoming") {
    let { discountPercentage } = countDiscount(product?.retailPrice, product?.salePrice);
    discountPercentages = discountPercentage;
    price = product?.salePrice;
    retail = false;
    discountAmmount = product?.retailPrice - product?.salePrice;
  } else if (status === "gone") {
    price = product?.retailPrice;
    discountPercentages = 0;
  } else if (status == "not") {
    discountPercentages = 0;
  }
  //   else {
  //     let { discountAmount, discountPercentage } = countDiscount(product.retailPrice, product.salePrice);
  //     price = product?.retailPrice;
  //   discountPercentages = discountPercentage;
  //   discountAmmount = discountAmount

  // }

  return { discountPercentages, discountAmmount, price, retail };
};

const buildRelatedProduct = (dto: Product) => {
  dto.pictures = buildPictureUrls(dto.pictures);

  const Relproduct: any = {
    id: dto!?.id,
    published_at: dto.published_at,
    name: dto.name,
    title: dto.title,
    barCode: dto.barCode,
    brand: dto.brand,
    discount: dto.discount!,
    PromotionText: dto.PromotionText!,
    discountDate: dto.discountDate!,
    startDiscountDate: dto.startDiscountDate!,
    video: buildYoutubeUrl(dto.video!),
    pictures: dto.pictures,
    mainPicture: dto.pictures[0],
    smallPictures: dto.pictures.slice(1),
    productLink: dto.productLink!,
    color: dto.color!,
    parentColor: dto.parentColor,
    isMainColor: dto.isMainColor,
    rating: Math.round(dto.rating),
    shippingDetails: dto.shippingDetails,
    deliveryTime: dto.deliveryTime!,
    catalogNumber: dto.catalogNumber,
    weight: dto.weight,
    importerDeal: dto.importerDeal,
    retailPrice: dto.retailPrice,
    salePrice: !!dto.salePrice ? dto.salePrice : dto.retailPrice,
    storePrice: dto.storePrice,
    warranty: !!dto.warranty
      ? {
          id: dto.warranty!?.id!,
          name: dto.warranty!?.name!,
          file: {
            id: dto.warranty.file!?.id!,
            url: buildMediaUrl(dto.warranty.file!?.url!),
            caption: dto.warranty.file!?.caption!,
          },
        }
      : null,
    reviews: dto.reviews,
    qna: dto.qna?.map((dto: TQnA) => ({
      id: dto!?.id,
      answer: dto.answer,
      question: dto.question,
    })),
    includedInTheKit: dto.includedInTheKit,
    features: dto.features?.map((feature: TFeature) => ({
      id: feature!?.id,
      description: feature.description,
      image: {
        id: feature.image!?.id!,
        url: buildMediaUrl(feature.image?.url!),
        caption: feature.image!?.caption!,
      },
    })),
    productLength: dto.productLength,
    productHeight: dto.productHeight,
    productWidth: dto.productWidth,
    quantity: dto.quantity!,
    awards: dto.awards?.map(award => ({
      id: award!?.id!,
      title: award.title!,
      description: award.description!,
      image: !!award.image
        ? {
            id: award.image!?.id!,
            url: buildMediaUrl(award.image.url!),
            caption: award.image!?.caption!,
          }
        : null,
    })),
    specifications: dto.specifications,
    description: dto.description,
    new: dto.new,
    hot: dto.hot,
    size: dto.size,
    age: !!dto.age
      ? {
          from: dto.age.from,
          to: dto.age.to,
        }
      : { from: 0, to: 20 },
    promotion: !!dto.promotion
      ? {
          name: dto.promotion.name!,
          requiredPurchase: dto.promotion.requiredPurchase!,
          givenBonus: dto.promotion.givenBonus!,
        }
      : null,
    subSubCategories: dto.subSubCategories?.map(sS => buildCategory(sS, dto.subSubCategories)),
    subCategories: dto.subCategories?.map(s => buildCategory(s, dto.subCategories)),
    categories: dto.categories?.map(c => buildCategory(c, dto.categories)),
    availabilityDate: dto.availabilityDate,
    isPublished: dto.isPublished,
    isofix: dto.isofix,
    productStandart: dto.productStandart!,
    foldTypes: dto.foldTypes,
  };

  return Relproduct;
};

const countDiscount = (retailPrice: number, salePrice: number) => {
  const PERCENTS = 100;

  const discountAmount = Number((retailPrice - salePrice).toFixed(2));
  const discountPercentage = Number((PERCENTS - (salePrice / retailPrice) * PERCENTS).toFixed(2));

  return { discountAmount, discountPercentage };
};

const countProductPrice = (isAgent: boolean, p: any, discount = 0) => {
  // let {price} = countProductDiscountPrice(p!);
  let price = 0;
  price = p?.salePrice;
  // isAgent ? p?.storePrice - (p?.storePrice / 100) * discount : price;
  return { price };
};

const setImageUrlFeatures = (feature: any) => {
  return feature.image.url;
  // let image = feature.image;
  // if(image.formats == null) {
  //   return image.url
  // }
  // // else if (image.formats && image.formats.medium) {return image.formats.medium.url}
  // else if (image.formats && image.formats.small) {return image.formats.small.url}
  // else if (image.formats && image.formats.xsmall) {return image.formats.xsmall.url}
  // return image.url
};

const setImageUrlBrand = (dto: any) => {
  let image = dto.brand.image;
  if (image.formats == null) {
    return image.url;
  }
  // else if (image.formats && image.formats.medium) {return image.formats.medium.url}
  else if (image.formats && image.formats.small) {
    return image.formats.small.url;
  } else if (image.formats && image.formats.xsmall) {
    return image.formats.xsmall.url;
  }
  return image.url;
};

// const setImageUrl = (dto: any) => {
//   let image = dto.backgroundImg;
//     if(image.formats == null) {
//       return image.url
//     }
//     // else if (image.formats && image.formats.medium) {return image.formats.medium.url}
//     else if (image.formats && image.formats.small) {return image.formats.small.url}
//     else if (image.formats && image.formats.xsmall) {return image.formats.xsmall.url}
//     return image.url
// }

const countVat = (price: number, vat: number, isAgent: boolean | null | undefined): number => {
  isAgent = false;
  if (isAgent) {
    return price * (vat / 100);
  }
  const priceWithoutVat = (price * 100) / (100 + vat);
  //console.log(isAgent, price, priceWithoutVat, 'vat', vat)
  return price - priceWithoutVat;
};

const getPaymentResponse = (response: string): boolean | null => {
  const SUCCESSFUL_RESPONSE_PART = "3D000";
  const parsedResponse = response.split("%");

  if (parsedResponse.filter(str => str !== "").length <= 0) return null;
  return parsedResponse.includes(SUCCESSFUL_RESPONSE_PART);
};

const getProductUrl = (
  path: string,
  category: number | null,
  subCategory: number | null,
  subSubCategory: number | null,
) => {
  let url = path;
  if (!!category) {
    url += `?category=${category}`;
  }

  if (!!subCategory) {
    url += `&subcategory=${subCategory}`;
  }

  if (!!subSubCategory) {
    url += `&sub_subcategory=${subSubCategory}`;
  }
  

  return url;
};
/* eslint-disable */
const buildPictureUrls = (pictures: Picture[]): any => {
  if (pictures?.length > 0) {
    let arr: {}[] = [];
    pictures?.map(pic => {
      arr.push(pic);
    });
    return arr;
  }
  return [];
};
/* eslint-enable */
const buildCategory = (dto: Category, all: Category[]): Category => ({
  id: dto.id,
  name: dto.name,
  internalName: dto.internalName,
  keywords: dto.keywords,
  description: dto.description,
  freeText: dto.freeText,
  parent: dto.parent === null ? null : dto.parent,
  backgroundImg: !!dto.backgroundImg
    ? {
        id: dto.backgroundImg.id!,
        url: buildMediaUrl(setImageUrl(dto)),
        // url: buildMediaUrl(dto.backgroundImg.url!),
        caption: dto.backgroundImg.caption!,
      }
    : null,
  freeTextImg: !!dto.freeTextImg
    ? {
        id: dto.freeTextImg.id!,
        url: buildMediaUrl(setImageUrl(dto)),
        caption: dto.freeTextImg.caption!,
      }
    : undefined,
  subcategories: all
    ?.filter((cat: Category) => cat.parent?.id === dto.id)
    .map((cat: Category) => buildCategory(cat, all)),
  childrenCategories: !!dto.childrenCategories ? dto.childrenCategories : [],
});

const getColors = (all: Product[], product: Product): Product[] => {
  let colors = all!?.filter(p => p!?.parentColor!?.id! === product!?.id!);

  if (product?.parentColor && Object.keys(product.parentColor).length === 0) {
    return [];
  }

  if (!!product?.parentColor) {
    colors = all
      ?.filter(p => p!?.id! === product!?.parentColor!?.id!)
      .concat(all?.filter(p => p!?.parentColor!?.id === product!?.parentColor!?.id!));
  }

  colors.push(product!);

  return [
    ...new Set(
      colors
        .slice()
        .filter(c => c?.quantity > 0)
        .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        .concat(
          colors
            .filter(c => c?.quantity <= 0)
            .slice()
            .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        )
        .reverse()
    ),
  ];
};

const parseRichText = (str: string) =>
  str &&
  parse(
    str!
      ?.replace(/^(\r\n|\n)*|(\r\n|\n)*$/gi, "")
      ?.replace(/\r\n|\n/gi, "<br />")
      ?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      ?.replace(/\*(.*?)\*/g, "<i>$1</i>")!
  );

const userLoggedIn = (user: LoggedUser | null) => (user ? true : false);

const detectUserStatus = (user: LoggedUser, product: Product) => true;

const checkProductAvailability = (qty: number) => !!qty && qty > 0;

const getCategoryParams = (
  params: string,
  name: "category" | "subcategory" | "sub_subcategory"
): number => Number(new URLSearchParams(params).get(name));

const checkImporterDeals = (path: string) => path.includes("importer-deals");
const checkProductsPage = (path: string) => path.includes("products/categories");

enum CATEGORIES {
  SWING = "swing",
  CAR_SEAT = "car_seat",
  STROLLER = "stroller",
  CARRIER = "carrier",
  FEEDING = "feeding",
}

const InternalNameToCategoryMap: { [internalName: string]: CATEGORIES } = {
  "נדנדות וטרמפולינות במבינו": CATEGORIES.SWING,
  "מושבי בטיחות במבינו": CATEGORIES.CAR_SEAT,
  "עגלות וטיולונים במבינו": CATEGORIES.STROLLER,
  "תיקים ומנשאים במבינו": CATEGORIES.CARRIER,
  "האכלה והרגעה במבינו": CATEGORIES.FEEDING,
}

function getMostSpecificCategoryId(location: RouterLocation<unknown>){
  if(location.query["sub_sub"]) return location.query["sub_sub"]
  if(location.query["sub"]) return location.query["sub"]
  
  return location.pathname.split("/").pop()
}

function removeHTML(text: string) {
  let tmp = document.createElement("p");
  tmp.innerHTML = text;

  return tmp.textContent || "";
}

export {
  parseRichText,
  getColors,
  buildClientUrl,
  checkImporterDeals,
  checkProductsPage,
  getCategoryParams,
  getProductUrl,
  countVat,
  checkProductAvailability,
  buildPictureUrls,
  buildMediaUrl,
  buildProduct,
  buildCategory,
  countDiscount,
  countProductPrice,
  getPaymentResponse,
  detectUserStatus,
  userLoggedIn,
  countProductDiscountPrice,
  InternalNameToCategoryMap,
  CATEGORIES,
  getMostSpecificCategoryId,
  removeHTML,
};
