import { Coupon } from "../services/coupons/types";
import { countProductDiscountPrice } from './helper';

const isCouponValid = (coupon: string, coupons: Coupon[]) => {

    let res = coupons.filter(c => c.code === coupon);
    let errorMsg = "";
    let result: any = "";

    if (res.length > 0) {
        let todayM = new Date().getTime();
        let expirationDate = new Date(res[0].validityPeriod).getTime()

        if (todayM < expirationDate) {
            result = res;
        } else {

            errorMsg = "Coupon expired";
        }
    } else {
        errorMsg = "Coupon not found";
    }


    return { result, errorMsg }
}

const checkDiscountByProducts = (product: any, couponProducts: any) => {
    let res = '';
    res = couponProducts.filter((p: any) => p.catalogNumber == product.catalogNumber);
    return { res };
}

const checkDiscountByCategories = (product: any, couponCategories: any) => {
    let categoriesSequences: any = [];

    couponCategories.map((cat: any) => {
        let founded = true;
        product?.categories.map((p: any) =>{if(p?.id == cat?.id && founded){categoriesSequences.push(product); founded = false; return;}});
        product?.subCategories.map((p: any) =>{if(p?.id == cat?.id && founded){categoriesSequences.push(product); founded = false; return;}});
        product?.subSubCategories.map((p: any) =>{if(p?.id == cat?.id && founded){categoriesSequences.push(product); founded = false;return;}});
    })

    let uniques = [...new Set(categoriesSequences)]

    return {uniques};
}

const checkDiscountPricePerProduct = (cartProducts: any, coupon: Coupon) => {
    
    let productWithDiscount: any = cartProducts.map((prod: any) => {
        let { res } = checkDiscountByProducts(prod, coupon.appliedTo.products);
        let {uniques}: any = checkDiscountByCategories(prod, coupon?.appliedTo?.categories);
        if(res?.length > 0 && uniques?.length < 1) {
            console.log("res", res);
            console.log("uniques", uniques);
            return res[0]
        } else if(res?.length < 1 && uniques?.length > 0) {
            console.log("res", res);
            console.log("uniques", uniques);
            return uniques[0]
        } else {

            let concatRes: any = res.concat(uniques.filter((item: any) => !res.indexOf(item?.id) ));
            console.log("res", res);
            console.log("uniques", uniques);
            console.log("concatRes", concatRes[0]);
            return concatRes?.length > 0 ? concatRes[0] : null;
        }

        
    })
    
    console.log("productWithDiscount11111", productWithDiscount);
    console.log("productWithDiscount", productWithDiscount.filter((elm: any) => elm !== null));
    return {productWithDiscount: productWithDiscount.filter((elm: any) => elm !== null)};
}

const sumTotalDiscount = (products: any, coupon: any) => {
    let discountType = "";
    let totalDiscount = 0;
    let discountAm = 0;
    products.map((p: any) => {
        let { price, discountAmmount } = countProductDiscountPrice(p);

        if(coupon?.discountType) {
            console.log(price);
            totalDiscount += (price * (coupon.discountPercent / 100));
        } else {
            totalDiscount += coupon.discountAmount;
        }
    })
    return {totalDiscount: totalDiscount};
}
export { isCouponValid, checkDiscountPricePerProduct, sumTotalDiscount }