import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { push } from "connected-react-router";
import { NewPhrase } from "../../../utils/language";
import languagesStore from "../../../store/languages-store";


const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      [theme.device.mobile()]: {
        alignItems: "center",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        paddingBottom: 10,
        width: "70%",
        alignItems: "center",
        borderBottom: `1px solid ${theme.colors.blue}`,
      },
    },
    item: {
      userSelect: "none",
      color: theme.colors.darkBlue,
      fontSize: 20,
      fontWeight: 600,
      cursor: "pointer",
      lineHeight: 1.7,
      textDecoration: "none",
      "&:hover": {
        transition: "0.3s",
        fontWeight: "bold"
      },
      [theme.device.mobile()]: {
        fontWeight: 600,
        fontSize: 22,
      },
      [theme.device.mobile480()]: {
        fontSize: 18,
      },
    },
  }),
  { name: "info-links" }
);

export type LinkData = {
  name: NewPhrase;
  path: string;
};

type TProps = {
  list: LinkData[]
};

const InfoLinks = ({ list }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const onInfoLinkClick = (path: string) => {
    dispatch(push(path, true));
  };
  const items = list.map(({ name, path }, i) => {
    const label = activePhrases && activePhrases[name];

    return (
      <div key={i} className={(classes.item)} onClick={() => onInfoLinkClick(path)}>
        {label}
      </div>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>{items}</div>
    </div>
  );
};

export default InfoLinks;
