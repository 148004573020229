import React, { useMemo, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";

import SingleProductMenu from "../products-view/single-product/single-product-menu/single-product-menu";
import SingleProduct from "../products-view/single-product/single-product";
import CategoryPanel from "../category/category-panel";
import ItemsList from "../common/items-list";

import { useAppDispatch, useAppSelector } from "../../store/store";
import categoriesStore from "../../store/categories-store";
import { Product } from "../../services/products/types";
import productsStore from "../../store/products-store";
import { getPhrase } from "../../utils/language";
import usersStore from "../../store/users-store";
import { Category } from "../../services/categories/types";
import {
  checkImporterDeals,
  getCategoryParams,
  userLoggedIn,
  parseRichText,
} from "../../utils/helper";
import { config } from "../../config";
import { push } from "connected-react-router";
import { Favorites } from "../../services/users/types";
import ErrorPage from "./404-error-page";
import { addFavouriteProductToStorage } from "../../services/products/products-service";
import Button from "../common/button";
import authFormStore from "../../store/auth-form-store";
import languagesStore from "../../store/languages-store";
import Popup from "reactjs-popup";
import { ReactComponent as CloseIcon } from "../../static/icons/close.svg";
import PuffLoader from "react-spinners/ClipLoader";
import { useCallback } from "react";

const SingleProductPage = (): JSX.Element => {
  const activeProduct: Product | null = useAppSelector(
    productsStore.selectors.getActiveProductSelector()
  );

  const [open, setOpen] = useState<boolean>(false);
  const [featureImageUrl, setFeatureImageUrl] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const theme = useTheme();

  const params = useAppSelector(state => state.router.location.search);
  const activeCategory: number = getCategoryParams(params, "category");

  const { lang, user, categories, products, isLoading, loaded } = useAppSelector(state => ({
    categories: state.categories.categoriesData.data,
    lang: state.languages.language,
    user: state.user.data,
    products: state.products.productsData.data,
    isLoading: state.products.productsData.loading,
    loaded: state.products.productsData.loaded,
  }));

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const dispatch = useAppDispatch();

  const isImporterDeals = checkImporterDeals(
    useAppSelector(state => state.router.location.pathname)
  );

  const classes = useStyles();
  const isMobile = window.innerWidth < 600;
  const favoriteCatalogItems = user?.favoriteCatalogItems! || [];

  const findProductCategories = useCallback(
    (activeProduct: Product | null, activeCategoryId: number | null) => {
      let category: Category | undefined;
      let subCategory: Category | undefined;
      let subSubCategory: Category | undefined;

      if (activeCategoryId !== 0) {
        const subSubCategoryOfCurrentActiveCategory = activeProduct?.subSubCategories.find(
          subSubCategory => {
            const subCategory = categories.find(cat => cat?.id === subSubCategory?.parent);
            const category = categories.find(cat => cat?.id === subCategory?.parent?.id);

            return category?.id === activeCategoryId;
          }
        );

        const subCategoryOfCurrentActiveCategory = activeProduct?.subCategories.find(
          subCategory => subCategory?.parent === activeCategoryId
        );

        if (subSubCategoryOfCurrentActiveCategory) {
          subSubCategory = subSubCategoryOfCurrentActiveCategory;
          subCategory = categories.find(c => c?.id === subSubCategory?.parent);
          category = categories.find(c => c?.id === subCategory?.parent?.id);
        } else if (subCategoryOfCurrentActiveCategory) {
          subCategory = subCategoryOfCurrentActiveCategory;
          category = categories.find(c => c?.id === subCategory?.parent?.id);
        } else {
          category = categories.find(c => c?.id === activeCategoryId);
        }
      } else {
        if (activeProduct && activeProduct?.subSubCategories.length > 0) {
          subSubCategory = activeProduct.subSubCategories[0];
          subCategory = categories.find(c => c?.id === subSubCategory?.parent);
          category = categories.find(c => c?.id === subCategory?.parent?.id);
        } else if (activeProduct && activeProduct.subCategories.length > 0) {
          subCategory = activeProduct.subCategories[0];
          category = categories.find(c => c?.id === subCategory?.parent);
        } else {
          category = activeProduct?.categories[0];
        }
      }

      return { category, subCategory, subSubCategory };
    },
    [activeProduct, activeCategory]
  );
  
  const filterSimilarProducts = (shownProduct: Product, allProducts: Product[]) => {
    const shownProductCategories = [...shownProduct.categories, ...shownProduct.subCategories, ...shownProduct.subSubCategories]
    const shownProductCategoryIds = shownProductCategories.map(c => c.id)
    return allProducts.filter(p => {
      const isInCategory = p.categories.some(c => shownProductCategoryIds.includes(c.id))
      const isInSubCategory = p.subCategories.some(c => shownProductCategoryIds.includes(c.id))      
      const isInSubSubCategory = p.subSubCategories.some(c => shownProductCategoryIds.includes(c.id))      
      
      return isInCategory || isInSubCategory || isInSubSubCategory
    })
  }
  const similarProducts = useMemo(
    () => {
      if(activeProduct && products && products.length){
        return filterSimilarProducts(activeProduct, products).sort((a, _) => a.quantity > 0 ? -1 : 1)
      }
      return []
    }, 
    [activeProduct, products]
  ) 

  const { category, subCategory, subSubCategory } = findProductCategories(
    activeProduct,
    activeCategory
  );


  const openFeatureImage = (url: string) => {
    setFeatureImageUrl(url);
    setModalIsOpen(true);
  };

  const features = activeProduct?.features?.map((feature, i) => (
    <div
      key={feature?.id}
      className={classes.featuresItem}
      onClick={() => openFeatureImage(feature?.image?.url)}
    >
      <div className={classes.featureView}>
        <img className={classes.featureImg} src={feature?.image?.url!} alt={`${feature?.id} img`} />
      </div>
      <div className={classes.featureDescription}>{parseRichText(feature.description)}</div>
    </div>
  ));

  const {
    description,
    specifications,
    includedInTheKit,
    qna,
    reviews,
    relatedProducts,
  } = {
    description: activeProduct?.description!,
    specifications: activeProduct?.specifications!,
    includedInTheKit: activeProduct?.includedInTheKit!,
    qna: activeProduct?.qna!,
    reviews: activeProduct?.reviews!,
    relatedProducts: activeProduct?.relatedProducts!,
  };

  const userId = user?.id?.toString()!;
  const toggleFavorite = (p: Favorites) => {
    if (!userLoggedIn(user)) {
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      addFavouriteProductToStorage(p, products);

      if (isMobile) {
        dispatch(push(config.routes.myAccount, true));
      }
      return;
    }

    const updatedList = [...favoriteCatalogItems];

    // Remove product from the favourites list if it exists already
    if (!!updatedList.find(fP => fP.productId === p.productId)) {
      const index = updatedList.indexOf(p);
      updatedList.splice(index, 1);
      dispatch(
        usersStore.actions.toggleFavoriteCatalogItem({ userId, favoriteCatalogItems: updatedList })
      );
      return;
    }

    // Add the product to the favourites list
    updatedList.push(p);
    dispatch(
      usersStore.actions.toggleFavoriteCatalogItem({ userId, favoriteCatalogItems: updatedList })
    );
  };

  const onPathClick = (cat: Category, type: "cat" | "sub" | "sub_sub") => () => {
    switch (type) {
      case "cat":
        dispatch(push((isImporterDeals ? config.routes.importerDealsPage : config.routes.productsPage) + `/${cat?.id!}`, true));
        dispatch(categoriesStore.actions.setActiveCategory(cat?.id!));
        dispatch(categoriesStore.actions.setActiveSubCategory(null));
        dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
        return;

      case "sub":
        dispatch(
          push(
            (isImporterDeals ? config.routes.importerDealsPage : config.routes.productsPage) +
            `/${category?.id}?sub=${cat?.id}`,
            true
          )
        );
        dispatch(categoriesStore.actions.setActiveCategory(cat?.parent));
        dispatch(categoriesStore.actions.setActiveSubCategory(cat?.id));
        dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
        return;

      case "sub_sub":
        const mainCat = categories.find(
          c => c?.id === categories.find(c => c?.id === cat.parent?.id)?.parent?.id
        );

        dispatch(
          push(
            (isImporterDeals ? config.routes.importerDealsPage : config.routes.productsPage) +
            `/${category?.id}?sub_sub=${cat?.id}`,
            true
          )
        );
        dispatch(categoriesStore.actions.setActiveCategory(mainCat?.id!));
        dispatch(categoriesStore.actions.setActiveSubCategory(cat?.parent));
        dispatch(categoriesStore.actions.setActiveSubSubCategory(cat?.id!));
    }
  };

  const menuProperties =
    (specifications === null || specifications?.length <= 0) &&
    (description === null || description?.length <= 0) &&
    (includedInTheKit === null || includedInTheKit?.length <= 0) &&
    reviews?.length <= 0 &&
    qna?.length <= 0;

  const onReviewButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (!user) {
      dispatch(authFormStore.actions.toggleVisibility("authFormIsVisible", true));
      window.scrollTo(0, 0)
    } else {
      setOpen(current => !current);
    }
  };

  const closeModal = () => setModalIsOpen(false);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <CategoryPanel hideCarousel={isMobile} />
        <div className={classes.loaderWrapper}>
          <PuffLoader color={theme.colors.blue} size={50} />
        </div>
      </div>
    );
  }

  return loaded && !!activeProduct ? (
    <div className={classes.root}>
      <CategoryPanel hideCarousel={isMobile} />
      <div className={classes.path}>
        <div className={classes.pathItem}>
          <div className={classes.pathText} onClick={onPathClick(category!, "cat")}>
            {category?.name}
          </div>
          <div className={classes.arrow} />
        </div>
        {!!subCategory && (
          <div className={classes.pathItem}>
            <div className={classes.pathText} onClick={onPathClick(subCategory!, "sub")}>
              {subCategory?.name}
            </div>
            <div className={classes.arrow} />
          </div>
        )}
        {!!subSubCategory && (
          <div className={classes.pathItem}>
            <div className={classes.pathText} onClick={onPathClick(subSubCategory!, "sub_sub")}>
              {subSubCategory?.name}
            </div>
            <div className={classes.arrow} />
          </div>
        )}
        <div className={clsx( classes.pathText, classes.productName)}>{activeProduct?.name}</div>
      </div>

      <SingleProduct
        open={open}
        product={activeProduct!}
        isImporterDeals={isImporterDeals}
        toggleFavorite={toggleFavorite}
        setOpen={setOpen}
        onReviewButtonClick={onReviewButtonClick}
      />

      {features?.length > 0 && (
        <div className={classes.features}>
          <div className={classes.featuresTitle}>{activePhrases && activePhrases["features"]}</div>
          <div className={classes.featuresContainer}>
            <Popup
              open={modalIsOpen}
              closeOnDocumentClick
              onClose={closeModal}
              overlayStyle={{ backgroundColor: "rgba(0, 0, 0 , 0.8)" }}
              contentStyle={{ height: "100%", display: "flex", position: "unset" }}
            >
              <div className={classes.picturePopup} onClick={closeModal}>
                <img src={featureImageUrl} className={classes.modalImage} alt="main" />
              </div>
              <CloseIcon className={classes.closeButton} onClick={closeModal} />
            </Popup>
            {features}
          </div>
        </div>
      )}

      {!menuProperties && <SingleProductMenu product={activeProduct} />}

      {relatedProducts?.length > 0 && (
        <ItemsList
          isImporterDeals={isImporterDeals}
          title={activePhrases && activePhrases["related_products"]}
          products={relatedProducts?.map(rP => rP.product)}
          toggleFavorite={toggleFavorite}
          user={user!}
        />
      )}

      {similarProducts?.length > 0 && (
        <ItemsList
          isImporterDeals={isImporterDeals}
          title={activePhrases && activePhrases["similar_products"]}
          products={similarProducts}
          toggleFavorite={toggleFavorite}
          pageSize={4}
          user={user!}
        />
      )}
    </div>
  ) : (
    <ErrorPage />
  );
};

export default SingleProductPage;

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.device.mobile()]: {
        paddingBottom: 20,
      },
    },
    path: {
      direction: "rtl",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      boxSizing: "border-box",
      [theme.device.desktop()]: {
        marginRight: 25,
      },
      [theme.device.mobile()]: {
        padding: "10px 15px",
        flexWrap: "wrap",
      },
    },
    pathItem: {
      display: "flex",
      alignItems: "center",
    },
    loaderWrapper: {
      height: "50vh",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    pathText: {
      cursor: "pointer",
      color: theme.colors.blue,
      fontSize: 19,
      fontWeight: 600,
      whiteSpace: "nowrap",
      marginRight: 4,
      [theme.device.mobile()]: {
        fontSize: 13,
      },
    },
    productName: {
      cursor: "text",
    },
    arrow: {
      border: `solid #58C9E8`,
      borderWidth: "0 2px 2px 0",
      height: 10,
      width: 10,
      minWidth: 10,
      flex: "0 0 10px",
      transform: "rotate(135deg)",
      [theme.device.desktop()]: {
        margin: "0 10px",
      },
      [theme.device.mobile()]: {
        marginRight: 3,
        height: 8,
        width: 8,
        minWidth: 8,
        flex: "0 0 8px",
      },
    },
    features: {
      padding: "10px 35px",
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      [theme.device.mobile()]: {
        padding: "10px 15px",
      },
    },
    featuresTitle: {
      padding: "10px 0 20px 0",
      color: theme.colors.blue,
      fontWeight: 700,
      fontSize: 21,
      [theme.device.mobile()]: {
        fontSize: 35,
      },
    },
    featureTitle: {
      [theme.device.mobile()]: {
        fontSize: 25,
      },
    },
    featuresContainer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridColumnGap: 45,
      direction: "rtl",
      [theme.device.mobile()]: {
        gridColumnGap: 30,
        gridRowGap: 20,
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },
    featuresItem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    featureView: {
      width: "100%",
      height: 220,
      [theme.device.mobile()]: {
        height: 150,
      },
      cursor: "pointer",
    },
    featureImg: {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
    featureDescription: {
      width: "100%",
      textAlign: "center",
      wordBreak: "break-word",
      color: theme.colors.grayText,
      fontWeight: 600,
      fontSize: 15,
    },

    picturePopup: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    modalImage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },

    closeButton: {
      cursor: "pointer",
      position: "absolute",
      right: "2%",
      top: "2%",
      height: 25,
      width: 25,
      ...theme.utils.svgChangeColor(theme.colors.blue),
    },
    activeDesktop: {
      [theme.device.mobile()]: {
        display: "none",
      },
    },
    activeMobile: {
      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "single-product-page" }
);
