import React, { useState } from "react";
import Popup from "reactjs-popup";
import { makeStyles } from "@material-ui/styles";
import { useAppSelector } from "../../../store/store";
import { CategoryFreeTextPopup } from "../../category/category-free-text-popup";
import { getMostSpecificCategoryId, removeHTML } from "../../../utils/helper";
const useStyles = makeStyles(
  theme => ({
    root: {
      fontSize: 24,
      textAlign: "end",
      color: theme.colors.grayText,
      display: 'flex',
      alignItems: 'center',
      justifyContent: "end",
      gap: 4
    },
    readMore: {
      color: theme.colors.blue,
      cursor: "pointer",
      textDecoration: 'underline'
    },
  })
)
export function CategoryFreeText(){
  const classes = useStyles();
  const location = useAppSelector(state => state.router.location)
  const categories = useAppSelector(state => state.categories.categoriesData.data)
  const currentCategoryId = getMostSpecificCategoryId(location);
  const currentCategory = categories.find(c => c.id === Number(currentCategoryId))
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  
  if(!currentCategory || !currentCategory.freeText || currentCategory.freeText.length < 30) return null
  
  const freetextBeginning = "... "  + currentCategory.freeText.split(' ').map((word, i) => {
    const displayedWordsCnt = 2
    if(i + 1 <   displayedWordsCnt) return word + " "
  }).join(' ')

  return (
    <div className={classes.root}>
      <span onClick={() => setIsPopupOpen(true)} className={classes.readMore}>קרא עוד</span>
      {removeHTML(freetextBeginning)}
      <Popup open={isPopupOpen} overlayStyle={{ backdropFilter: "blur(5px)" }} onClose={() => setIsPopupOpen(false)} lockScroll={true}>
        <CategoryFreeTextPopup closePopup={() => setIsPopupOpen(false)} category={currentCategory}/>
      </Popup>
    </div>
  )
}