import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { getPhrase } from "../../utils/language";
import { useAppSelector, useAppDispatch } from "../../store/store";
import clsx from "clsx";
import slide from "../../static/images/middle_img.png";
import { push } from "connected-react-router";
import { config } from "../../config";
import languagesStore from "../../store/languages-store";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import axios from "axios";

export const Explore = () => {
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const { HomePageExploreBanner, HomePageExploreBannerMobile } = useAppSelector(
    state => state.siteConfig
  );
  const [isExist, setIsExist] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigateToSayHelloPage = () => {
    dispatch(push(config.routes.exploreGraco));
  };

  React.useEffect(() => {}, [isMobile, HomePageExploreBanner, HomePageExploreBannerMobile]);

  React.useEffect(() => {}, [window.innerWidth]);

  const setUrl = (url: string) => {
    let http = "http://";
    if (url.substring(0, 7) == "http://") {
      return url;
    }
    if (url.substring(0, 8) == "https://") {
      return url;
    }
    return http + url;
  };

  return (
    <>
      {window.innerWidth < 600 ? (
        <div
          className={classes.explore}
          onClick={() => {
            if (
              HomePageExploreBannerMobile?.caption !== "" &&
              HomePageExploreBannerMobile?.caption
            ) {
              window.open(setUrl(HomePageExploreBannerMobile?.caption), "_self");
            }
          }}
        >
          <img
            className={classes.img}
            src={HomePageExploreBannerMobile?.url}
            alt="explore background"
          />
        </div>
      ) : (
        <div
          className={clsx(
            HomePageExploreBanner?.caption !== "" && HomePageExploreBanner?.caption
              ? classes.exploreCursor
              : classes.explore
          )}
          onClick={() => {
            if (HomePageExploreBanner?.caption !== "" && HomePageExploreBanner?.caption) {
              window.open(setUrl(HomePageExploreBanner?.caption), "_self");
            }
          }}
        >
          <img className={classes.img} src={HomePageExploreBanner?.url} alt="explore background" />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  explore: {
    width: "95%",
    marginTop: "60px",
    minHeight: 600,
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    [theme.device.mobile()]: {
      marginTop: 0,
    },
  },
  exploreCursor: {
    width: "100%",
    marginTop: "60px",
    minHeight: 600,
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    cursor: "pointer",
    [theme.device.mobile()]: {
      marginTop: 0,
      minHeight: 300,
    },
  },
  exploreInfo: {
    boxSizing: "border-box",
    padding: "30px 20px 20px 20px",
    marginLeft: 60,
    width: 480,
    height: 340,
    // backgroundColor: theme.colors.blueTransparent,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    display: "flex",
    flexDirection: "column",
    gridRowGap: 15,
    [theme.device.mobile()]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  exploreInfoText: {
    boxSizing: "border-box",
    padding: "0 15px",
    textAlign: "center",
    width: "100%",
    fontWeight: 600,
    fontSize: 15,
    color: theme.colors.text,
  },
  exploreInfoLink: {
    marginTop: "auto",
    userSelect: "none",
    cursor: "pointer",
    textAlign: "start",
  },
  exploreInfoLinkMobileTitle: {
    [theme.device.mobile()]: {
      fontSize: "34px !important",
      textAlign: "center",
    },
  },
  bigTitle: {
    padding: 0,
    fontWeight: 700,
    fontSize: 40,
    color: theme.colors.text,
  },
  img: {
    zIndex: -1,
    position: "absolute",
    objectFit: 'cover',
    width: "100%",
    height: "100%",
  },
}));
