import React, { useState, useEffect } from "react";
import { makeStyles, CreateCSSProperties } from "@material-ui/styles";
import { Route, Switch, useLocation } from "react-router";
import clsx from "clsx";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ReactGA from "react-ga";

import TermsAndConditionsPage from "./components/pages/terms-and-conditions";
import PrivacyPolicyPage from "./components/pages/privacy-policy";
import AccessibilityPage from "./components/pages/accessibility";
import SingleProductPage from "./components/pages/single-product-page";
import CustomerServicePage from "./components/pages/customer-service";
import ApprovedSellersPage from "./components/pages/approved-sellers";
import ProviderAuth from "./components/authentication/providerAuth";
import PrivateRoute from "./components/authentication/PrivateRoute";
import ShoppingBagPage from "./components/pages/shopping-bag-page";
import ContactPage from "./components/pages/contact/contact-page";
import MyAccount from "./components/pages/my-account/my-account";
import ProductsPage from "./components/pages/products-page";
import ImporterDealsPage from ".//components/pages/importer-deals-page";
import CheckoutPage from "./components/pages/checkout-page";
import CatalogPage from "./components/pages/catalog-page";
import ErrorPage from "./components/pages/404-error-page";
import BuyPolicyPage from "./components/pages/buy-policy";
import AppBanner from "./components/common/app-banner";
import SortPopup from "./components/popup/sort-popup";
import AuthPage from "./components/pages/auth-page";
import HomePage from "./components/pages/home-page";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Popup from "./components/popup/popup";
import ExploreGraco from "./components/pages/explore-graco/explore_graco";

import FitGuidePage from "./components/fit-guide/fit-guide";
import VehicleNotFound from "./components/fit-guide/vehicle-not-found";

import { useAppDispatch, useAppSelector } from "./store/store";
import siteConfigStore from "./store/site-config-store";
import categoriesStore from "./store/categories-store";
import productsStore from "./store/products-store";
import ordersStore from "./store/orders-store";
import usersStore from "./store/users-store";
import { config } from "./config";
import CareersPage from "./components/pages/careers-page";
import PositionPage from "./components/pages/position-page";
import languagesStore from "./store/languages-store";
import { Product } from "./services/products/types";
import { CartItem } from "./services/users/types";
import { isMobile } from "react-device-detect";
import authFormStore from "./store/auth-form-store";
import { countProductDiscountPrice } from "./utils/helper";
import contentPagesStore from "./store/content-pages-store";
import couponsStore from "./store/coupons-store";
import ContentPage from "./components/pages/content-pages/content-page";
import exploreGracoStore from "./store/explore-graco-store";
import SeoSettingsStore from "./store/seo-settings-store";
import { useHistory } from "react-router";
import ThanksPage from "./components/pages/thanks-pages/thanks-page";
import thanksPagesStore from "./store/thanks-page-store";
import Under from "./static/images/under-construction.png";
import { WhatsappIcon } from "react-share";

type StyleProps = {
  bannerIsVisible: boolean;
  AppBannerHeight: number;
};

type ProductToAdd = {
  product: Product;
  count: number;
};

const App = (): JSX.Element => {
  // const [activeAuth, setActiveAuth] = useState<boolean>(false);
  const [bannerIsVisible, setBannerIsVisible] = useState<boolean>(true);

  const { user, activeSort, bottomBanner, url, seoSettings, socialMedia } = useAppSelector(
    state => ({
      bottomBanner: state.siteConfig.bottomBanner,
      activeSort: state.UIsorting.activeSort,
      url: state.router.location.pathname,
      user: state.user.data,
      seoSettings: state.seoSettings,
      socialMedia: state.siteConfig.socialMedia
    })
  );

  const dispatch = useAppDispatch();

  const [AppBannerHeight, setAppBannerHeight] = useState(0);
  const classes = useStyles({ bannerIsVisible, AppBannerHeight });
  const pathName = window.location.pathname;
  const search = window.location.search;
  const history = useHistory();

  useEffect(() => {
    let bannerHeight = window.innerWidth * 0.12;
    setAppBannerHeight(bannerHeight);
  });

  useEffect(() => {
    window.scroll({ top: 0, behavior: "auto" });
    dispatch(thanksPagesStore.actions.fetchThanksPages());
    dispatch(SeoSettingsStore.actions.fetchSeoConfig());
    dispatch(usersStore.actions.fetchUser());
    dispatch(languagesStore.actions.fetchPhrases());
    dispatch(siteConfigStore.actions.fetchSiteConfig())
    dispatch(categoriesStore.actions.fetchCategories())
    dispatch(productsStore.actions.fetchProducts())
    dispatch(contentPagesStore.actions.fetchContentPages());
    dispatch(couponsStore.actions.fetchCoupons());
    dispatch(exploreGracoStore.actions.fetchExploreGracoConfig());
  }, [dispatch]);

  let occurance = seoSettings.find(seo => seo.enteredURL === pathName);

  useEffect((): any => {
    if (occurance?.enteredURL === pathName) {
      return history.push(occurance.forwardToURL);
    }
  }, [pathName, seoSettings]);

  useEffect(() => {
    if (user?.id) {
      dispatch(ordersStore.actions.fetchOrders());
    }
  }, [user?.id]);

  useEffect(() => window.scroll({ top: 0, behavior: "auto" }), [url]);

  useEffect(() => {
    const productsInStorage = localStorage.getItem("cartProducts");
    const cartItems: CartItem[] = user?.cartItems!?.map(cI => cI);

    if (user?.id && productsInStorage) {
      const parsedProducts: ProductToAdd[] = JSON.parse(productsInStorage);

      const countProductPrice = (isAgent: boolean, p: Product, discount = 0) => {
        isAgent = false;
        let { price } = countProductDiscountPrice(p);
        if (isAgent) {
          return p?.salePrice - (p?.salePrice / 100) * discount;
        } else {
          return price;
        }
      };
      const addProductsFromLocalStToUpdatedCartItems = (products: ProductToAdd[]) => {
        let currentCartItems: CartItem[] = cartItems?.slice(0);

        products.map(obj => {
          const price = countProductPrice(false, obj.product, 0);
          const itemToAdd = {
            productId: obj.product?.id,
            productName: obj.product?.name,
            productCount: obj?.count,
            price,
            discount: obj.product?.discount,
            discountDate: obj.product?.discountDate,
            retailPrice: obj.product?.retailPrice,
            salePrice: obj.product?.salePrice,
          };
          const indexOfProduct = currentCartItems?.findIndex(
            p => p.productId === itemToAdd.productId
          );

          if (indexOfProduct !== -1) {
            currentCartItems = currentCartItems.map((item, i) => {
              if (i === indexOfProduct) {
                let { discountPercentages, discountAmmount, price } =
                  countProductDiscountPrice(item);
                if (item.productCount + itemToAdd.productCount <= obj.product.quantity) {
                  item = {
                    ...item,
                    productCount: item.productCount + itemToAdd.productCount,
                    price: price,
                    salePrice: item.salePrice,
                    retailPrice: item.retailPrice,
                    discountDate: item.discountDate,
                    discount: item.discount,
                  };
                }
              }

              return item;
            });
          } else {
            currentCartItems = [...currentCartItems, itemToAdd];
          }
        });

        return currentCartItems;
      };

      const updatedCartItems = addProductsFromLocalStToUpdatedCartItems(parsedProducts);

      dispatch(
        usersStore.actions.updateUserCart({
          userId: user.id,
          cartItems: updatedCartItems,
        })
      );

      localStorage.removeItem("cartProducts");
    }
  }, [user?.id]);

  const { authFormIsVisible } = useAppSelector(({ authForm: { authFormIsVisible } }) => ({
    authFormIsVisible,
  }));

  useEffect(() => {
    ReactGA.initialize("G-KBEJJQ8P3Q");
    ReactGA.pageview("/");
  });

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCUBPT07nOLFyhoSVLttO64PXiKYXiNZAY",
    authDomain: "joie-ae4ec.firebaseapp.com",
    projectId: "joie-ae4ec",
    storageBucket: "joie-ae4ec.appspot.com",
    messagingSenderId: "119889112226",
    appId: "1:119889112226:web:0bd42df4b84bee75913bfb",
    measurementId: "G-KBEJJQ8P3Q",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const TRACKING_ID = "G-TRCSSDFXL6"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  return (
    <>
    {/* <img src={Under} alt="under-construction" /> */}
      <Header />
      <div
        className={classes.wrapper}
        onClick={() => dispatch(authFormStore.actions.closeAllDropDowns())}
      >
        <div className={classes.content}>
          {!isMobile && <Popup active={authFormIsVisible} />}
          <Popup active={activeSort} className={classes.sortPopup}>
            <SortPopup active={activeSort} />
          </Popup>

          <Switch>
            <Route path={config.routes.redirect} component={ProviderAuth} />
            <Route exact path={config.routes.homePage} component={HomePage} />
            <Route path={config.routes.catalogPage} component={CatalogPage} />
            <Route path={config.routes.productsPage} component={ProductsPage} />
            <Route path={config.routes.fit} component={FitGuidePage} />
            <Route path={`${config.routes.product}/:id`} component={SingleProductPage} />
            <Route path={config.routes.vehicleNotFound} component={VehicleNotFound} />
            <Route path={config.routes.importerDealsPage} component={ImporterDealsPage} />
            <Route path={`${config.routes.product}/:id`} component={SingleProductPage} />
            <Route path={config.routes.thanksPage} component={ThanksPage} />

            <Route
              path={`${config.routes.importerDealsProduct}/:id`}
              component={SingleProductPage}
            />
            <Route path={config.routes.authPage} component={AuthPage} />
            <Route path={config.routes.contact} component={ContactPage} />
            <Route path={config.routes.termsAndConditions} component={TermsAndConditionsPage} />
            <Route path={config.routes.privacyPolicy} component={PrivacyPolicyPage} />
            <Route path={config.routes.accessibility} component={AccessibilityPage} />
            <Route path={config.routes.customerService} component={CustomerServicePage} />
            <Route path={config.routes.buyPolicy} exact component={BuyPolicyPage} />
            <Route path={config.routes.approvedSellers} component={ApprovedSellersPage} />
            <Route path={config.routes.exploreGraco} component={ExploreGraco} />
            <Route exact path={config.routes.careers} component={CareersPage} />
            <Route path={`${config.routes.careers}/:id`} component={PositionPage} />
            <Route path={config.routes.shoppingBagPage}>
              <ShoppingBagPage />
            </Route>
            <Route path={config.routes.checkoutPage}>
              <CheckoutPage />
            </Route>
            <PrivateRoute path={config.routes.myAccount}>
              <MyAccount />
            </PrivateRoute>
            <Route path={`${config.routes.contentPage}:name`} component={ContentPage} />
            <Route path="*" component={ErrorPage} />
          </Switch>

          <Footer className={clsx(bannerIsVisible && classes.footerMargin)} />

          {!!bottomBanner && (
            <AppBanner
              banner={bottomBanner}
              bannerIsVisible={bannerIsVisible}
              setBannerIsVisible={setBannerIsVisible}
            />
          )}
          <a href={socialMedia?.whatsapp ?? "https://wa.me/972559992583"}>
            <WhatsappIcon className={classes.whatsappIcon} />        
          </a>
          </div>
      </div>
    </>
  );
};

export default App;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      // paddingTop: 74,
      paddingTop: 160,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      // mediumMobile

      [theme.device.tablet()]: {
        paddingTop: 110,
      },
      // [theme.device.smallTablet()]: {
      //   paddingTop: 84,
      // },
      // [theme.device.mobile()]: {
      //   paddingTop: 84,
      // },
    },
    whatsappIcon: {
      position: "fixed",
      top: "78%",
      left: 5,
      borderRadius: 50,
      height: 50,
      width: 50,
      cursor: "pointer",
      zIndex: 9999,
    },
    content: {
      paddingBottom: 0,

      [theme.device.mobile()]: {
        paddingBottom: 0,
      },
    },
    footerMargin: ({ AppBannerHeight }: StyleProps): CreateCSSProperties => ({
      //marginBottom: AppBannerHeight,
      marginBottom: 0,
      [theme.device.mobile()]: {
        marginBottom: 0,
      },
    }),
    sortPopup: {
      alignItems: "flex-end",
      backdropFilter: "none",
      backgroundColor: theme.colors.shadow,

      [theme.device.desktop()]: {
        display: "none",
      },
    },
  }),
  { name: "app" }
);
