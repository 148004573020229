import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import displayCurrencySign from "../../../../utils/currencies-signs";
import { ReactComponent as DiscountIcon } from "../../../../static/icons/discount.svg";
import {
  countDiscount,
  countProductDiscountPrice,
  countProductPrice,
} from "../../../../utils/helper";
import { Product } from "../../../../services/products/types";
import { LoggedUser } from "../../../../services/users/types";
import { useAppSelector } from "../../../../store/store";
import languagesStore from "../../../../store/languages-store";

type TProps = {
  product: Product;
  user: LoggedUser;
  className?: {
    root?: string;
  };
};

const SingleProductPrice = ({ user, product, className }: TProps) => {
  const classes = useStyles();

  const lang = useAppSelector(({ languages }) => languages.language);
  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());

  const isAgent = false
  let discount = 0
  let safes = 0;
  let currPrice = product.retailPrice;
  let isRetail = true;


  if (isAgent) {
    if (discount) {
      let countedPrice = product?.salePrice * (1 - discount / 100);
      const { discountPercentage, discountAmount } = countDiscount(
        product?.salePrice,
        countedPrice
      );
      currPrice = countedPrice;
      discount = discountPercentage!;
      safes = discountAmount;
    } else {

      currPrice = product?.salePrice;
    }
  } else {
    let { discountPercentages, discountAmmount, price, retail } =
      countProductDiscountPrice(product);
    discount = discountPercentages;
    safes = discountAmmount;
    currPrice = price;
    isRetail = retail;
  }

  const withDiscount = (discount! > 0 && !isAgent) || (isAgent && discount! > 0);
  return (
    <div className={clsx(classes.root, className?.root)}>
      <div className={classes.priceInfo}>
        {withDiscount && (
          <>
            <div className={classes.discountPercentage}>
              <DiscountIcon className={classes.discountIcon} />
              <div
                className={clsx(
                  classes.discountText,
                  Number(discount?.toFixed()) < 10 && classes.discountTextOneDigit
                )}
              >
                -{discount?.toFixed()}%
              </div>
            </div>

            <div className={classes.priceInfoText}>
              <div className={classes.retailPrice}>
                {/* {displayCurrencySign(lang)} {product?.retailPrice?.toFixed(1)} */}
                {displayCurrencySign(lang)}{" "}
                {isAgent ? product?.salePrice.toFixed(2) : product?.retailPrice.toFixed(2)}
              </div>
              &nbsp;:
              {activePhrases && activePhrases["consumer_price"]}
            </div>

            <div className={classes.priceInfoText}>
              {displayCurrencySign(lang)} {safes?.toFixed(2)} :
              {/* {displayCurrencySign(lang)} {discountAmount?.toFixed(1)} : */}
              {activePhrases && activePhrases["savings"]}
            </div>
          </>
        )}
        <div className={classes.priceInfoText}>
          <div className={(classes.salePrice)}>
            {displayCurrencySign(lang)} {currPrice.toFixed(2)}
            {/* {isAgent
              ? countProductPrice(isAgent, product!, discount)?.toFixed(2)
              : price.toFixed(2)} */}
          </div>
          &nbsp;:
          {isAgent
            ? activePhrases && activePhrases["store_price"]
            : isRetail
              ? activePhrases && activePhrases["consumer_price"]
              : activePhrases && activePhrases["sale_price"]}
        </div>

        {!isAgent && product?.discountDate && withDiscount ?
          <div className={classes.discountDate}>
            {product?.discountDate?.split("-")?.reverse()?.join("-")!}{" "}
            {activePhrases && activePhrases["discount_date"]}
          </div>
          : null}
        {
          isAgent ?
            <div className={classes.discountDate}>
              {activePhrases && activePhrases["promotion_text"]}
            </div>
            : null
        }
      </div>
    </div>
  );
};

export default SingleProductPrice;

const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      boxSizing: "border-box",
      paddingLeft: 120,
      [theme.device.mobile()]: {
        padding: 0,
        marginBotton: 20
      },
    },
    priceInfo: {
      position: "relative",
      width: "100%",
      backgroundColor: theme.colors.whiteBackground,
      boxShadow: `2px 3px 3px ${theme.colors.shadow}`,
      borderRadius: 20,
      padding: "10px 15px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gridRowGap: 10,
      [theme.device.smallMobile()]: {
        gridRowGap: 5,
      },
    },
    priceInfoText: {
      display: "flex",
      color: theme.colors.black,
      fontSize: 27,
      [theme.device.mobile()]: {
        fontSize: 23,
      },
      [theme.device.smallMobile()]: {
        fontSize: 17,
      },
    },
    retailPrice: {
      textDecoration: "line-through",
    },
    salePrice: {
      fontWeight: 700,
      color: theme.colors.blue,
    },
    discountPercentage: {
      position: "absolute",
      left: 15,
      top: -7,
      [theme.device.mobile()]: {
        left: 7,
      },
    },
    discountIcon: {
      width: 100,
      height: 100,
      [theme.device.mobile()]: {
        height: 70,
        width: 70,
      },
    },
    discountText: {
      userSelect: "none",
      cursor: "default",
      top: 39,
      left: 39,
      position: "absolute",
      color: theme.colors.text,
      fontWeight: 700,
      fontSize: 20,
      transform: "rotate(45deg)",
      [theme.device.mobile()]: {
        top: 27,
        left: 27,
        fontSize: 15,
      },
    },
    discountTextOneDigit: {
      top: 38,
      left: 45,
      [theme.device.mobile()]: {
        top: 26,
        left: 30,
      },
    },
    discountDate: {
      fontSize: 17,
      fontWeight: 700,
    },
    missingPrice: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gridColumnGap: 15,
      padding: "30px 0",
    },
  }),
  { name: "single-product-price" }
);
