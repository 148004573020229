//@ts-nocheck
import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: "fixed",
      top: 0,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      zIndex: 6,
    },
    unfixed: {
      [theme.device.mediumMobile()]: {
        position: "initial",
      },
    },
    rootBlack: {
      position: "fixed",
      top: 0,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      zIndex: 6,
      // filter: "grayscale(100%)",
    },
    text: {
      fontSize: 18,
      color: theme.colors.text,

      [theme.device.mediumMobile()]: {
        textAlign: "center",
      },
    },
    logo: {
      userSelect: "none",
      alignSelf: "flex-start",
      marginLeft: 35,
      marginTop: 35,
      position: "absolute",
      cursor: "pointer",
      maxWidth: 200,
      zIndex: 2,
      [theme.device.mediumMobile()]: {
        marginLeft: 15,
        height: "36px",
        maxWidth: "100%",
      },
    },
    logoMobile: {
      userSelect: "none",
      alignSelf: "flex-start",
      position: "absolute",
      cursor: "pointer",
      marginTop: 10,
      marginLeft: 15,
      height: "36px",
      maxWidth: "100%",
      zIndex: 2,
      [theme.device.mediumMobile()]: {
        marginLeft: 15,
        height: "36px",
        maxWidth: "100%",
      },
      [theme.device.mobile480()]: {
        marginLeft: 0,
        paddingTop: "8px",
        marginTop: 0
      },
    },
    title: {
      zIndex: 1,
      position: "relative",
      width: "100%",
      backgroundColor: theme.colors.blue,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 7,
      paddingTop: 7,

      [theme.device.mediumMobile()]: {
        padding: "0",
        height: 60,
        justifyContent: "center",
      },
    },
    titleBlack: {
      zIndex: 1,
      position: "relative",
      width: "100%",
      backgroundColor: theme.colors.brown,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 7,
      paddingTop: 7,

      [theme.device.mediumMobile()]: {
        padding: "0",
        height: 60,
        justifyContent: "center",
      },
    },
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.device.mediumMobile()]: {
        paddingBottom: 0,
      },
    },
    navigation: {
      zIndex: 1,
      width: "100%",
      boxSizing: "border-box",
      padding: "0px 0px 0px 250px",
      backgroundColor: theme.colors.text,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.device.mediumMobile()]: {
        justifyContent: "flex-end",
        padding: "0",
      },

      [theme.device.mediumMobile()]: {
        justifyContent: "space-evenly",
        paddingLeft: 0,
        height: 50,
      },
    },
    navigationContainer: {
      display: "flex",
      justifyContent: "space-between",
      gridColumnGap: 70,

      [theme.device.mediumMobile()]: {
        gridColumnGap: 30,
      },
    },
    navigationItem: {
      cursor: "pointer",
      color: theme.colors.blue,
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      gridColumnGap: 5,
      [theme.device.smallTablet()]: {
        paddingLeft: 60,
        // marginLeft: "auto",
      },

      [theme.device.mediumMobile()]: {
        paddingLeft: 0,
        marginLeft: 0,
      },
    },
    navigationItemBlack: {
      cursor: "pointer",
      color: theme.colors.black,
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      gridColumnGap: 5,
    },
    menu: {
      width: "100%",
      boxSizing: "border-box",
      paddingRight: "20px",
      justifyContent: "flex-end",
      paddingTop: "8px",
      paddingBottom: "10px",
      backgroundColor: theme.colors.white,
      display: "flex",
      alignItems: "baseline",
      gridColumnGap: 5,
      [theme.device.mediumMobile()]: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    menuContainer: {
      boxSizing: "border-box",
      paddingRight: 250,
      width: "100%",
      display: "flex",
    },
    menuItem: {
      width: "7%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: 17,
      lineHeight: "90%",
      color: theme.colors.darkBlue,
      "& span": {
        textAlign: "center",
      },
      "&:hover": {
        backgorund: theme.colors.blue,
      },
    },
    menuItemSlide: {
      width: "100%",
      display: "flex",
      // flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      padding: "10px 0",
      color: theme.colors.white,
      borderBottom: "1px solid #fff",
      "&:first-of-type": {
        borderTop: "1px solid #fff",
      },
      "& span": {
        textAlign: "center",
      },
      "&:hover": {
        background: theme.colors.white,
        color: theme.colors.blue,
        cursor: "pointer",
      },
    },
    mobileMenuItemSlide: {
      // "& $menuItemSlide:first-of-type": {
      //   background: theme.colors.brown,
      // },
    },
    menuItemBlack: {
      display: "flex",
      width: "7%",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontSize: 17,
      lineHeight: "80%",
      color: "#707070",
      "&:first-of-type": {
        borderTop: "1px solid #fff",
      },
    },
    menuHamburgerItemBlack: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      fontSize: 11,
      color: "#707070",
      filter: "grayscale(100%)",
    },
    slideMenuContainer: {
      "& :first-child": {
        color: "red",
      },
    },
    menuIcon: {
      cursor: "pointer",
      // filter:
      //   "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(89%) contrast(96%)",
      width: 23,
      height: 30,
    },
    menuIconBlack: {
      cursor: "pointer",
      width: 23,
      height: 30,
    },
    menuHamburgerIcon: {
      cursor: "pointer",
      width: 30,
      height: 30,
    },

    icon: {
      cursor: "pointer",
      height: 30,
      width: 30,
      ...theme.utils.svgStroke(theme.colors.blue),
    },
    iconBrown: {
      cursor: "pointer",
      height: 25,
      width: 25,
      [theme.device.mobile()]: {
        width: 22,
        height: 22,
      },
    },
    navigationIcon: {
      width: 25,
      height: 25,
      ...theme.utils.svgStroke(theme.colors.blue),
      [theme.device.mobile()]: {
        width: 22,
        height: 22,
      },
    },
    placeIcon: {
      width: 22,
      height: 22,
      ...theme.utils.svgChangeColor(theme.colors.blue),
    },
    languageIcon: {
      width: 41,
      height: 34,
    },
    searchWindowVisible: {
      display: "flex",
    },
    cart: {
      userSelect: "none",
      cursor: "pointer",
      position: "relative",
      height: 30,
      // filter:
      //   "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(89%) contrast(96%)",
      // filter: "sepia(17%) saturate(900%) hue-rotate(30deg)",
      [theme.device.mediumMobile()]: {
        paddingRight: "0px",
      },
    },

    // iconWhite: {
    //   ...theme.utils.svgStroke(theme.colors.white),
    //   ...theme.utils.svgChangeColor(theme.colors.white),
    // },
    authWrapper: {
      position: "absolute",
      left: "45%",
      ["@media (min-width: 2000px)"]: {
        left: "46%",
      },
      ["@media (max-width: 1800px)"]: {
        left: "46%",
      },
      ["@media (max-width: 1700px)"]: {
        left: "45%",
      },
      [theme.device.smallDesktop()]: {
        left: "43%",
      },
      [theme.device.laptop()]: {
        left: "42%",
      },
      [theme.device.smallTablet()]: {
        display: "none",
      },
    },
    auth: {
      cursor: "pointer",
      userSelect: "none",
      alignSelf: "flex-end",
      color: theme.colors.blue,
      borderBottom: "2px solid transparent",
      transition: "border-bottom 0.2s",
      fontWeight: "normal",
      fontSize: 18,
      paddingBottom: 2,
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.blue}`,
      },
      [theme.device.mediumMobile()]: {
        display: "none",
      },
    },
    authBlack: {
      cursor: "pointer",
      userSelect: "none",
      alignSelf: "flex-end",
      color: theme.colors.black,
      borderBottom: "2px solid transparent",
      transition: "border-bottom 0.2s",
      fontWeight: "normal",
      fontSize: 18,
      paddingBottom: 2,
      "&:hover": {
        borderBottom: `2px solid ${theme.colors.gray}`,
      },
      [theme.device.mediumMobile()]: {
        display: "none",
      },
    },
    cartCount: {
      position: "absolute",
      top: "60%",
      right: 0,
      backgroundColor: theme.colors.blue,
      borderRadius: "100%",
      color: theme.colors.text,
      textAlign: "center",
      fontSize: 9,
      padding: "2px 4px",
    },
    mobileMenuContainer: {
      paddingTop: 20,
    },
    dropMenu: {
      display: "none",
      opacity: 0,
      transition: "0.3s",
      position: "absolute",
      top: 0,
      right: 0,
      width: 250,
      [theme.device.mediumMobile()]: {
        width: "77%",
        zIndex: "2",
        right: 0,
      },
      [theme.device.desktop()]: {
        display: "none",
      },
    },
    dropMenuActive: {
      display: "flex",
      flexDirection: "column",
      opacity: 1,
      top: "74px",
      zIndex: 9,
      backgroundColor: `${theme.colors.blue}`,
      borderLeft: "1px solid #fff",
      "& $menuItem": {
        color: "#FFF",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "18px",
        borderBottom: "1px solid #FFF",
      },
      "& $menuItemBlack": {
        color: "#FFF",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "18px",
        borderBottom: "1px solid #FFF",
        width: "auto",
        cursor: "pointer",
      },
      "& $auth": {
        color: "#FFF",
        justifyContent: "center",
        backgroundColor: theme.colors.blue,
        width: "100%",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid #FFF",
        [theme.device.mediumMobile()]: {
          display: "flex",
        },
      },
      [theme.device.mediumMobile()]: {
        right: "0",
        top: "102px",
      },
    },
    dropMenuBlackActive: {
      backgroundColor: `${theme.colors.brown}`,
    },
    authPopup: {
      position: "absolute",
      margin: "0 auto",
      top: "100%",
      visibility: "hidden",
      opacity: 0,
      transition: "0.3s",
      [theme.device.mediumMobile()]: {
        display: "flex",
        top: 0,
      },
    },
    active: {
      visibility: "visible",
      opacity: 1,
      transition: "0.3s",
    },
    activeDesktop: {
      [theme.device.mediumMobile()]: {
        display: "none",
      },
    },

    menuItemHamburger: {
      height: 37,
      width: "250px",
      display: "flex",
      justifyContent: "end",
      paddingTop: 5,
      paddingRight: 5,
      "& > svg": {
        ...theme.utils.svgStroke(theme.colors.brown),
      },
      [theme.device.mediumMobile()]: {
        width: "auto",
        position: "absolute",
        right: 0,
        paddingTop: 0,
      },
    },
    menuItemHamburgerActive: {
      height: 37,
      width: "250px",
      display: "flex",
      justifyContent: "end",
      paddingTop: 5,
      paddingRight: 5,
      background: theme.colors.blue,
      borderTop: "1px solid #fff",
      borderLeft: "1px solid #fff",
      "& > svg": {
        paddingBottom: "5px",
        filter:
          "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(589%) contrast(96%)",
      },
      [theme.device.mediumMobile()]: {
        position: "absolute",
        bottom: 9,
        height: 43,
        right: 0,
        width: "77%",
      },
    },
    menuItemHamburgerBlack: {
      height: 37,
      // background: theme.colors.brown,
      width: "250px",
      display: "flex",
      justifyContent: "end",
      paddingTop: 5,
      paddingRight: 5,
      [theme.device.mediumMobile()]: {
        width: "auto",
      },
    },

    menuItemHamburgerBlackActive: {
      height: 37,
      width: "250px",
      display: "flex",
      justifyContent: "end",
      paddingTop: 5,
      paddingRight: 5,
      background: theme.colors.brown,
      borderTop: "1px solid #fff",
      borderLeft: "1px solid #fff",
      "& > svg": {
        paddingBottom: "5px",
        filter:
          "invert(50%) sepia(20%) saturate(1321%) hue-rotate(173deg) brightness(589%) contrast(96%)",
      },
      [theme.device.mediumMobile()]: {
        position: "absolute",
        bottom: 9,
        height: 43,
        right: 0,
        width: "77%",
      },
    },
  }),
  { name: "header" }
);
