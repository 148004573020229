import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Route, Switch, useLocation } from "react-router";
import { push } from "connected-react-router";
import Cookies from "js-cookie";
import clsx from "clsx";

import FavoritesList from "../../my-account/favorites-list/favorites-list";
import MyAccountPanel from "../../my-account/my-account-panel";
import MyAccountOverview from "./overview";
import Button from "../../common/button";

import { useAppDispatch, useAppSelector } from "../../../store/store";
import useViewport from "../../../hooks/useViewport";
import MyAccountOrderHistory from "./order-history";
import { config } from "../../../config";
import { ActiveTab } from "./types";
import languagesStore from "../../../store/languages-store";

const MyAccount = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<ActiveTab>("myAccount");

  const activePhrases = useAppSelector(languagesStore.selectors.getActivePhrases());
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const viewport = useViewport();

  const classes = useStyles();

  const theme = useTheme();

  const goDown = (): string => {
    if (viewport?.width && viewport?.width >= 600) {
      return "further";
    }

    return "";
  };

  useEffect(() => {
    if (pathname === config.routes.favoritesList && activeTab !== "favoritesList")
      setActiveTab("favoritesList");
    if (pathname === config.routes.ordersHistory && activeTab !== "orderHistory")
      setActiveTab("orderHistory");
    if (pathname === config.routes.myAccount && activeTab !== "myAccount")
      setActiveTab("myAccount");
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      <MyAccountPanel />

      <div className={classes.content}>
        <div className={classes.tabMenu}>
          <ul>
            <li>
              <span
                className={clsx(activeTab === "myAccount" && "active")}
                onClick={() => {
                  dispatch(push(config.routes.myAccount, true));
                }}
              >
                {activePhrases && activePhrases["my_account"]}
              </span>
            </li>

            <li>
              <span
                className={clsx(activeTab === "orderHistory" && "active")}
                onClick={() => {
                  dispatch(push(config.routes.ordersHistory, true));
                }}
              >
                {activePhrases && activePhrases["order_history"]}
              </span>
            </li>

            <li>
              <span
                className={clsx(activeTab === "favoritesList" && "active")}
                onClick={() => {
                  dispatch(push(config.routes.favoritesList, true));
                }}
              >
                {activePhrases && activePhrases["favourite_list"]}
              </span>
            </li>

            <li className={goDown()}>
              <Button
                text={activePhrases && activePhrases["sign_out"]}
                width={160}
                height={50}
                borderRadius={30}
                outline={theme.colors.blue}
                textColor={theme.colors.blue}
                borderWidth={3}
                textSize={28}
                action={() => {
                  Cookies.remove("jwt");
                  document.location.href = config.routes.homePage;
                }}
              />
            </li>
          </ul>
        </div>

        <div className={classes.tabContent}>
          <Switch>
            <Route path={config.routes.ordersHistory} component={MyAccountOrderHistory} />
            <Route path={config.routes.favoritesList} component={FavoritesList} />
            <Route path={config.routes.myAccount} component={MyAccountOverview} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      alignItems: "center",
    },

    content: {
      display: "flex", 
      flexFlow: "column",

      width: "100%",
      margin: 20,

      [theme.device.desktop()]: {
        flexDirection: "row",
      },
      [theme.device.mobile()]: {
        margin: "20px 0",
        boxSizing: "border-box",
        padding: "0 15px",
      },
    },

    tabMenu: {
      fontSize: 30,
      width: "100%",
      minWidth: 320,
      maxWidth: 320,
      margin: "0 auto",
      textAlign: "center",
      color: theme.colors.blue,
      [theme.device.mobile()]: {
        minWidth: "auto",
        paddingBottom: 20,
        borderBottom: `2px solid ${theme.colors.blue}`,
      },

      "&>ul": {
        margin: 0,
        padding: 0,
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
        [theme.device.mobile()]: {
          alignItems: "center",
        },

        "&>li": {
          height: 35,
          marginBottom: 14,
          cursor: "pointer",
          alignSelf: "flex-end",

          "&:last-child": {
            margin: "30px 0px",
          },

          "&.further": {
            marginTop: 200,
          },

          "&>span": {
            "&.active": {
              fontWeight: "bold",
              borderBottom: `3px solid ${theme.colors.blue}`,
            },
            "&.signOut": {
              fontSize: 30,
              fontWeight: "bold",
              padding: "4px 18px",
              borderRadius: 30,
              border: `2px solid ${theme.colors.blue}`,
            },
          },
        },

        [theme.device.desktop()]: {
          borderRight: `1px solid ${theme.colors.grayBorder}`,
          paddingRight: 20,
        },
      },

      [theme.device.desktop()]: {
        width: "20%",
        paddingLeft: 10,
        textAlign: "start",
      },

      "@media (min-width: 1000px)": {
        paddingLeft: 50,
      },
    },

    tabContent: {
      width: "100%",

      [theme.device.desktop()]: {
        width: "80%",
        paddingRight: 10,
      },
      "@media (min-width: 1000px)": {
        paddingRight: 50,
      },
    },
  }),
  { name: "my-account-page" }
);
