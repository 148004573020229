import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { push } from "connected-react-router";
import React from "react";
import { config } from "../../config";
import { Category } from "../../services/categories/types";
import categoriesStore from "../../store/categories-store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getPhrase } from "../../utils/language";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
      padding: "10px 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      cursor: "pointer",
      userSelect: "none",
      color: theme.colors.text,
      fontSize: 23,
      whiteSpace: "nowrap",
    },
    underline: {
      textDecoration: "underline",
    },
    arrow: {
      zIndex: 4,
      position: "absolute",
      top: "100%",
      opacity: 0,
      visibility: "hidden",
      borderLeft: "30px solid transparent",
      borderRight: "30px solid transparent",
      borderBottom: `20px solid ${theme.colors.grayBorder}`,
      "&:before": {
        content: '""',
        position: "absolute",
        top: 0.9,
        left: -30,
        right: 0,
        bottom: 0,
        borderLeft: "30px solid transparent",
        borderRight: "30px solid transparent",
        borderBottom: `20px solid ${theme.colors.text}`,
      },
    },

    active: {
      opacity: 1,
      visibility: "visible",
    },
  }),
  { name: "category-item" }
);

type TProps = {
  currentCategory: number | null;
  setCurrentCategory: (id: number | null) => void;
  category: Category;
  isImporterDeals?: boolean;
};

const CategoryItem = ({ currentCategory, category, setCurrentCategory }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const onHoverStart = (): void => setCurrentCategory(category.id);

  const lang = useAppSelector(state => state.languages.language);
  const categories = useAppSelector(state => state.categories.categoriesData.data);

  const bobo = categories
    .filter(a => a.id === currentCategory)
    .some(a => (a.subcategories.length > 0 ? true : false));
  //console.log(bobo)

  const activeCategory = useAppSelector(state => state.categories.activeCategory);

  const onCategoryClick = () => {
    setCurrentCategory(null);

    dispatch(push(`${config.routes.productsPage}/${category.id}`, true));
    dispatch(categoriesStore.actions.setActiveCategory(category.id));
    dispatch(categoriesStore.actions.setActiveSubCategory(null));
    dispatch(categoriesStore.actions.setActiveSubSubCategory(null));
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={clsx(classes.title, activeCategory === category?.id && classes.underline)}
          onMouseEnter={onHoverStart}
          onClick={onCategoryClick}
        >
          {category.name}
        </div>
        <div
          className={clsx(
            bobo && classes.arrow,
            currentCategory === category?.id && classes.active
          )}
        />
      </div>
    </>
  );
};

export default CategoryItem;
