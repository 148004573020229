import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Category } from "../../services/categories/types";
import Close from "@mui/icons-material/Close";
import { parseRichText } from "../../utils/helper";
const useStyles = makeStyles(
  theme => ({
    root: {
      height: "max-content",
      width: "40vw",
      textAlign: "end",
      position: "relative",
      background: theme.colors.whiteBackground,
      borderRadius: "10px",
      color: theme.colors.black,
      [theme.device.mobile()]: {
        width: "95vw",
        height: "90vh",
        overflow: "hidden",
      }
    },
    header: {
      width: "100%",
      height: 200,
      display: "flex",
      borderRadius: "10px 10px 0 0",
      backgroundColor: theme.colors.blue,
    },
    img: {
      width: "50%",
      borderRadius: "10px 0 0 0",
      objectFit: "cover"
    },
    categoryName: {
      color: theme.colors.text,
      fontSize: 48,
      width: "45%",
      marginTop: 50,
      marginLeft: 10,
      [theme.device.mobile()]: {
        fontSize: 28 
      }
    },
    categoryDescription: {
      fontSize: 28,
      padding: 20,
      overflow: "scroll",
      height: "70vh"
    },
    closeBtn: {
      position: "absolute",
      top: 5,
      right: 5,
      color: theme.colors.text,
      cursor: "pointer"
    },
    closeBtnIcon: {
      borderRadius: 50,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    }
  })
)
type TProps = {
  closePopup: () => void
  category: Category
}
export function CategoryFreeTextPopup({ closePopup, category }: TProps){
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {category?.freeTextImg?.url ? <img src={category.freeTextImg.url} alt={category.name} className={classes.img} /> : <div className={classes.img} />}
        <h1 className={classes.categoryName}>{category.name}</h1> 
      </div>
      <div className={classes.categoryDescription}>{parseRichText(category.freeText ?? "")}</div> 
      <div className={classes.closeBtn} onClick={closePopup}>
        <Close fontSize="large" className={classes.closeBtnIcon}></Close>
      </div>
    </div>
  )
}